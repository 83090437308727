<template>
    <client-page>
        <shop-product-visual wide :image="`${path}/visual.webp`" :breadcrumbs="breadcrumbs" title="Wood in the bathroom" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm txt--dark">나무 가구는 욕실을 더 따뜻하고 아늑하게 느끼게 합니다. 욕실에서 나무에 대한 현대적인 아이디어를 여기에서 찾을 수 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <template v-for="(item, index) in navigation">
                                <v-col cols="12" :key="index">
                                    <router-link :to="`#${index + 1}`" class="txt txt--sm d-flex align-center">
                                        <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                        {{ item }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <dot-list>
                                <template v-for="item in overview">
                                    <li :key="item">
                                        {{ item }}
                                    </li>
                                </template>
                            </dot-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--first">
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8" class="text-center">
                        <h3 class="tit mb-12px mb-md-16px">나무는 욕실에 따뜻한 분위기를 조성합니다.</h3>
                        <div class="txt txt--sm txt--dark">
                            <p>목재는 다양한 장점을 제공하는 다재다능한 소재입니다. 지역산 목재를 선택하면 매우 지속 가능한 소재로, 거주 공간을 따뜻하고 매력적으로 느끼게 합니다. 목재는 내구성이 뛰어나고 견고한 소재로, 아름답게 오래갑니다.</p>
                            <p>나무는 욕실에서 수증기를 흡수한 다음 공기가 건조해지면 다시 방출하여 실내 기후에 긍정적인 영향을 미칠 수 있다는 점에 주목할 가치가 있습니다. 또한 플라스틱과 달리 진짜 나무는 항균 특성이 있습니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="right" id="1">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/legato-bathroom-furniture-kansas-oak-with-shower-and-toilet.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">욕실에 나무를 사용할 수 있나요?</div>
                        <div class="txt txt--sm txt--dark">
                            <p>위에서 언급한 장점 때문에 목재는 욕실에 인기 있는 선택입니다. 다양한 종류와 색상의 목재 중에서 선택할 수 있습니다. 참나무와 호두나무는 매우 인기가 있습니다. 목재처럼 보이는 MDF나 칩보드는 약간 저렴합니다.</p>
                            <p>욕실의 습도가 높으면 처리되지 않은 목재에 곰팡이가 생길 수 있습니다. 따라서 욕실용 목재는 처리하여 물이 침투하지 않도록 하는 것이 중요합니다. 목재에 물이 고이면 항상 즉시 닦아내야 합니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row>
                    <v-col cols="12" md="8">
                        <div class="txt txt--xs">고려해야 할 핵심 사항</div>
                        <h3 class="tit mb-12px mb-md-16px">습한 공간에 적합한 목재 선택</h3>
                        <p class="txt txt--sm txt--dark">욕실의 스타일을 보완하는 동시에 습기를 잘 견디는 목재 종류를 선택하세요. 오크는 내구성이 뛰어나고 습기에 대한 내성이 좋기 때문에 세면대와 샤워기에 특히 좋은 선택입니다. 낙엽송, 대나무, 아카시아도 욕실에 잘 어울립니다. 더글러스 전나무, 호두 또는 로빈과 같은 목재 종류는 나무 벽 패널에 잘 어울립니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="2">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/collaro-bathtub-white-toilet-white-and-washbasin-double-with-mirror-large.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">욕실에는 밝은 나무색을 사용하세요</div>
                        <div class="txt txt--sm txt--dark">
                            <p>솔리드 오크, 낙엽송, 너도밤나무, 소나무는 욕실에서 스타일리시하게 보이는 밝은 색상의 목재입니다. Villeroy & Boch에서 화이트 오크, 오크 베니어 또는 노르딕 오크 등의 욕실 가구를 찾을 수 있습니다. 밝은 색상의 목재는 욕실에 통풍이 잘되는 느낌을 줍니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="left" id="3">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">욕실에는 어두운 나무색을 사용하세요</div>
                        <div class="txt txt--sm txt--dark">
                            <p>어두운 목재 종류는 욕실에서 우아하거나 더 소박하게 보일 수 있습니다. 호두, 마호가니 또는 티크를 선택하여 방에 특별한 터치를 더하세요. 완벽한 조합: 어두운 목재와 스테인리스 스틸. Villeroy &amp; Boch는 Warm Walnut, Walnut Veneer 및 Arizona Oak를 포함하여 어두운 목재 색상의 광범위한 욕실 가구를 제공합니다.</p>
                        </div>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/subway30-bath-tub-in-rustic-bathroom.webp`" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap text-center">
                    <h3 class="tit mb-12px mb-md-16px">나무로 만든 현대적인 욕실 영감</h3>
                    <p class="txt txt--sm txt--dark">욕실에서 나무를 사용하는 방법은 많습니다. 예를 들어, 소박한 나무 세면대는 훌륭한 선택이 될 수 있습니다. 가구 외에도 나무 또는 나무 효과 타일을 바닥에 사용할 수 있습니다. 한편, 나무 벽 패널은 매우 눈길을 끄는 효과를 냅니다.</p>
                </div>
                <three-images :path="path" first="artis-washbasin-rust-on-beige-finion-vanity-unit.webp" second="subway-3.0-bathroom-furniture-kansas-oak.webp" third="subway30-bathroom-furniture-in-light-wooden-bathroom.webp" />
            </v-container>
        </page-section>

        <page-section class="page-section--grey" id="4">
            <v-container>
                <v-row justify="center" align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <v-img :src="`${path}/antao-freestanding-bathtub-shower-system-washbasin-in-white-lofty-design.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">2024년 욕실 트렌드</div>
                        <div class="txt txt--sm txt--dark">
                            <p>목재는 매력적인 컨트리 스타일 가구부터 호화로운 빈티지 룩까지 다양한 욕실 트렌드와 아름답게 조화를 이룹니다. 목재는 또한 스칸디나 재팬디와 같은 미니멀리스트 스타일과 완벽하게 어울립니다.</p>
                        </div>
                        <v-btn large color="#fff" elevation="2" class="rounded-xs mt-18px mt-md-24px" to="/ideas/bathroom/trends/2024">
                            지금 당장 2024년 욕실 트렌드를 확인해보세요!
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import SectionSlide from "@/components/client/dumb/section-slide.vue";
import DotList from "@/components/client/ideas/dot-list.vue";
import ThreeImages from "@/components/client/ideas/three-images.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        SectionSlide,
        DotList,
        ThreeImages,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 아이디어 및 트렌드",
                href: "/ideas/bathroom/trends",
            },
        ],
        navigation: [
            "욕실에 나무를 사용해야 할까요?",
            "꿈의 욕실을 위한 밝은 나무",
            "소박한 느낌을 위한 어두운 나무 색상",
            "2024년을 위한 더 많은 욕실 트렌드",
            //
        ],
        overview: [
            "욕실에는 나무 가구와 벽 패널이 인기 있는 선택입니다.",
            "나무 바닥이 있는 욕실은 아늑하고 소박한 느낌을 줍니다..",
            "욕실을 나무로 장식하는 아이디어는 다양합니다.",
            //
        ],
        items: [
            {
                title: "완벽한 욕조를 선택하세요",
                text: "독립형 욕조는 소박한 시골 스타일 욕실에 특히 좋은 선택입니다. 곡선형, 둥근 모양의 욕조는 현대적인 스타일과도 아름답게 조화를 이룹니다.",
                button: "빌레로이앤보흐 욕조",
                link: "/shop/products?category=bathtub",
                image: "collaro-bathroom-country-style.webp",
                cols: "6",
            },
            {
                title: "스타일리쉬한 샤워",
                text: "욕조뿐만 아니라 샤워도 넓은 시골 스타일 욕실에 더 큰 편의성을 더해줍니다. 검은색 수도꼭지와 레인 샤워는 특별한 터치를 더해줍니다.",
                button: "완벽한 수도꼭지를 찾아보세요",
                link: "/shop/products?category=Shower_fittings",
                image: "universal-showers-rain-shower-matt-black-in-white-shower.webp",
                cols: "6",
            },
        ],
        path: "/images/ideas/bathroom/trends/styles/wood",
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>