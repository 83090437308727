var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": `${_vm.path}/visual.webp`,
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Vintage bathroom"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("욕실을 빈티지하게 꾸미고 싶으신가요? 이 인테리어 트렌드의 주요 특징에 대해 자세히 알아보려면 계속 읽어보세요.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_vm._l(_vm.navigation, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('router-link', {
      staticClass: "txt txt--sm d-flex align-center",
      attrs: {
        "to": `#${index + 1}`
      }
    }, [_c('v-img', {
      staticClass: "mr-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-down.svg"
      }
    }), _vm._v(" " + _vm._s(item) + " ")], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('dot-list', [_vm._l(_vm.overview, function (item) {
    return [_c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실의 레트로한 미학")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("빈티지 스타일은 각기 다른 시대를 아우르며, 각 시대마다 고유한 특징과 매력이 있습니다. 욕실에 빈티지 스타일 룩을 계획하고 있다면, 첫 번째 단계는 시대를 정하는 것입니다: 빅토리아, 1950년대, 1970년대, 바로크 - 어떤 시대가 될까요?")])])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "1"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/finion-bathtub-in-classic-bathroom-with-white-and-blue-walls.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("빈티지 스타일의 특징과 특징")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("어떤 시대를 선택하든 빈티지 욕실의 각 구성 요소에 대해 일관된 스타일을 목표로 하는 것이 중요합니다. 이렇게 하면 전체적으로 조화로운 효과가 생겨 사용자를 선택한 시대로 즉시 되돌릴 수 있습니다. 빈티지 욕실에서는 독립형 욕조가 인기 있는 선택이며, 특히 기발한 발과 눈길을 끄는 오버플로와 같은 복잡한 디테일이 있는 욕조가 그렇습니다. 욕조는 방의 중심이며 다른 모든 가구와 위생 설비는 욕조 주변에 배치됩니다.")])])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "left"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("빈티지 스타일 색상")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("빈티지 욕실에 가장 인기 있는 색상은 흰색, 구리, 금, 황동과 같은 금속성 색조, 검정과 짙은 녹색입니다. 파스텔 색상은 1950년대 스타일 욕실에 좋은 선택입니다.")]), _c('p', [_vm._v("대조 역시 중요합니다. 정교한 돋을새김 무늬가 있는 흰색 욕실 세라믹은 금색 수도꼭지와 어두운 타일과 결합하면 특히 우아해 보일 것입니다.")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/collaro-washbasin-white-on-furniture-green-with-mirror-large.webp`
    }
  })], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "2"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/hommage-bathtub-black-white-and-freestanding.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("빈티지 스타일 디자인")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("빈티지 또는 레트로 욕실의 또 다른 특징은 나무 선반이나 장식적인 디테일이 있는 캐비닛과 같은 화려한 디자인의 대형 가구입니다. 현대적인 터치도 빈티지 스타일 욕실에 잘 어울립니다. 트렌디한 단색 색상 구성표와 빈티지 가구를 결합하여 이러한 효과를 만들어 보세요. 물론 빈티지 스타일 욕실에는 최첨단 기술이 허용됩니다!")]), _c('p', [_vm._v("세면대 아래의 세면대는 빈티지 욕실의 고전입니다. 여기에는 기발하고 정교한 디자인의 여지가 많습니다. Hommage 컬렉션의 복잡한 디테일과 강렬한 대비는 특히 눈길을 끄는 효과를 만들어냅니다")]), _c('v-btn', {
    staticClass: "rounded-xs mt-18px mt-md-24px",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/b/hommage"
    }
  }, [_vm._v(" Hommage 컬렉션 보기 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey",
    attrs: {
      "id": "3"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("빈티지한 장식으로 욕실을 스타일리시하게 연출해보세요")]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("장식은 세련된 빈티지 욕실을 마무리하는 데 중요한 역할을 합니다. 골드 수도꼭지는 우아하고 호화로운 효과를 만들어내며, 계단식 릴리프 기능이 있는 Hommage 컬렉션의 어두운 세면대 중 하나와 아름답게 어울립니다. 화려한 프레임에 큰 그림을 넣으면 마지막 화려함이 더해집니다. 복고풍 촛대를 사용하여 특이한 조명 효과를 만들 수도 있습니다.")])]), _c('three-images', {
    attrs: {
      "path": _vm.path,
      "first": "hommage-washbasin-with-two-side-cabinets-with-glass-doors-and-bathtube-white.webp",
      "second": "mettlach-bathtub-tap-gold-with-handshower.webp",
      "third": "hommage-bathtub-black-white-and-freestanding-with-tap-silver.webp"
    }
  })], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "product-section-title"
  }, [_c('span', {
    staticClass: "tit font-weight-regular font-tertiary white"
  }, [_vm._v("욕실에 대한 기타 유용한 팁")])])])], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('v-row', {
    staticClass: "row--xxl",
    attrs: {
      "justify": "center"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": item.cols
      }
    }, [_c('v-card', {
      staticClass: "mx-auto",
      attrs: {
        "max-width": "776",
        "color": "transparent",
        "tile": "",
        "flat": "",
        "href": item.link
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": `${_vm.path}/${item.image}`
      }
    }), _c('div', {
      staticClass: "px-12px px-md-16px"
    }, [_c('div', {
      staticClass: "tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), item.link ? [_c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v(" " + _vm._s(item.button) + " ")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)] : _vm._e()], 2)], 1)], 1)];
  })], 2)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }