<template>
    <client-page>
        <shop-product-visual wide :image="`${path}/visual.webp`" :breadcrumbs="breadcrumbs" title="Oriental bathroom" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm txt--dark">욕실을 동양풍으로 꾸미고 싶으신가요? 이 룩에 필수적인 요소를 보여드리겠습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <template v-for="(item, index) in navigation">
                                <v-col cols="12" :key="index">
                                    <router-link :to="`#${index + 1}`" class="txt txt--sm d-flex align-center">
                                        <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                        {{ item }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <dot-list>
                                <template v-for="item in overview">
                                    <li :key="item">
                                        {{ item }}
                                    </li>
                                </template>
                            </dot-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="right" id="1">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/loop-and-friends-withe-washbasin-mediterranean-style-pink-wall.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <p class="txt txt--xs mb-12px mb-md-16px">완벽한 동양식 욕실을 디자인하세요</p>
                        <div class="tit mb-12px mb-md-16px">동양식 욕실 디자인: 색상과 패턴의 조화</div>
                        <div class="txt txt--sm txt--dark">
                            <p>욕실에 동양적 분위기를 연출하려면 실용적이고 고품질의 가구와 기분 좋은 향, 따뜻한 색상, 장난기 있는 디테일과 같은 고급스럽고 관능적인 터치를 결합하세요. 선택할 수 있는 디자인 아이디어가 많이 있습니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="left" id="2">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">동양식 욕실을 위한 벽 디자인 팁:</div>
                        <p class="txt txt--sm txt--dark">동양식 타일과 같은 특징을 사용하여 욕실에 악센트를 더하세요. 복잡한 모자이크와 따뜻한 색상의 정교하게 장식된 장식용 타일은 완벽한 선택입니다. 이러한 타일은 특징적인 벽으로 가장 잘 작동하지만 그렇지 않으면 패턴이 너무 강렬해 보일 수 있습니다. 세면대와 거울 뒤의 타일은 특히 아름다운 모습을 연출합니다.</p>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/oberon-20-bathtub-with-liberty-tap.webp`" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="3">
            <v-container>
                <h3 class="tit mb-12px mb-md-16px text-center">평온함과 관능미 - 동양적 악센트</h3>
                <v-row justify="center" align="center" class="row--lg">
                    <v-col order-md="2" cols="12" md="auto">
                        <v-sheet max-width="700" color="transparent" class="position-relative">
                            <swiper class="swiper" v-bind="{ options }">
                                <template v-for="(item, index) in items">
                                    <swiper-slide :key="index">
                                        <img :src="`${path}/${item}`" alt="" class="d-block" />
                                    </swiper-slide>
                                </template>
                            </swiper>
                            <div class="swiper-pagination swiper-pagination--center" />
                        </v-sheet>
                    </v-col>
                    <v-col order-md="1" cols="auto">
                        <v-btn min-width="64" min-height="64" icon outlined class="swiper-button--prev">
                            <v-img max-width="24" src="/images/icon/icon-swiper-left.svg" />
                        </v-btn>
                    </v-col>
                    <v-col order="3" cols="auto">
                        <v-btn min-width="64" min-height="64" icon outlined class="swiper-button--next">
                            <v-img max-width="24" src="/images/icon/icon-swiper-right.svg" />
                        </v-btn>
                    </v-col>
                </v-row>
                <div class="mt-40px mt-md-60px mb-12px mb-md-16px txt txt--sm txt--dark text-center font-weight-medium">동양적인 분위기를 연출하는 욕실 장식</div>
                <v-sheet color="transparent" max-width="570" class="mx-auto">
                    <p class="txt txt--sm text-center">특욕실 장식에 동양적인 느낌을 더할 수도 있습니다. 아늑한 분위기를 위해 베이지색이나 진한 빨간색 욕실 직물을 선택하세요. 마라케시 랜턴의 따뜻한 빛은 편안한 목욕을 위한 장면을 연출합니다. 촛불과 동양 조각상 또는 그림도 욕실에 잘 어울립니다.</p>
                </v-sheet>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <h3 class="tit font-tertiary mb-40px mb-md-56px">욕실을 위한 동양적 디자인 아이디어</h3>
                <v-row class="row--lg">
                    <template v-for="(item, index) in ideas">
                        <v-col cols="6" lg="4" :key="index">
                            <v-card tile flat :to="item.link" max-width="400" class="mx-auto">
                                <v-img :src="`${path}/${item.image}`" class="mb-12px mb-md-16px" />
                                <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm grey--text text--darken-3">
                                    {{ item.text }}
                                </p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">자세히 알아보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey" id="4">
            <v-container>
                <v-row justify="center" align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <v-img :src="`${path}/antao-bath-tub-in-stylish-bathromm.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">욕실에 가장 어울리는 디자인 스타일을 찾아보세요</div>
                        <div class="txt txt--sm txt--dark">
                            <p>동양적 룩은 모든 사람에게 적합한 스타일은 아닙니다. 하지만 선택할 수 있는 더 많은 트렌드 인테리어 디자인 아이디어가 있습니다. 지금 바로 당신에게 완벽한 2024년 욕실 트렌드를 찾아보세요. 몇 가지 간단한 변화만 있으면 이러한 트렌드를 적용하고 욕실에 아름다운 룩을 연출할 수 있습니다.</p>
                        </div>
                        <v-btn large color="#fff" elevation="2" class="rounded-xs mt-18px mt-md-24px" to="/ideas/bathroom/trends/2024">
                            2024년 욕실 트렌드
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import SectionSlide from "@/components/client/dumb/section-slide.vue";
import DotList from "@/components/client/ideas/dot-list.vue";
import ThreeImages from "@/components/client/ideas/three-images.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        SectionSlide,
        DotList,
        ThreeImages,
    },
    data: () => ({
        options: {
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        },
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 아이디어 및 트렌드",
                href: "/ideas/bathroom/trends",
            },
        ],
        navigation: [
            "동양식 욕실의 주요 특징은 무엇입니까?",
            "동양적 벽 디자인을 위한 우리의 팁",
            "동양식 욕실을 장식하는 방법",
            "더 많은 욕실 트렌드 2024",
            //
        ],
        overview: [
            "따뜻한 색상, 부드러운 조명, 기분 좋은 향기는 동양식 욕실의 주요 특징입니다.",
            "모자이크 타일로 눈길을 끄는 악센트 벽을 디자인해보세요.",
            "금은 수도꼭지와 기타 악센트에 완벽합니다.",
            //
        ],
        items: [
            "loop-friends-washbasin-grey-in-marrakech-style+bathroom.webp",
            "moretosee-mirror-in-hawaiian-style-bathroom.webp",
            "loop-friends-washbasin-white-in-peach-coloured-bathroom.webp",
            "subway30-washbasin-more-to-lite-mirror-blue-hotel-bathroom.webp",
            //
        ],
        ideas: [
            {
                image: "antao-vanity-unit-honey-oak.webp",
                title: "동양식 욕실을 위한 가구",
                text: "욕조에 동양적인 새로운 분위기를 부여하려면 심플한 욕실 가구에 정교한 액세서리와 직물을 결합해보세요.",
                link: "/shop/products?category=Bathroom_furniture_and_mirrors",
            },
            {
                image: "loop-and-friends-bathtub-deep-blue-marrakesh-oriental-style.webp",
                title: "완벽한 욕조를 선택하세요",
                text: "크고 독립형 욕조는 동양식 욕실의 중심이 될 것입니다. 모로코 양탄자를 앞에 놓으면 정말 눈에 띄게 됩니다.",
                link: "/shop/products?category=bathtub",
            },
            {
                image: "hommage-washbasin-tap-gold-on-washbasin-white.webp",
                title: "색상 악센트를 위한 골드 탭",
                text: "새로운 수도꼭지는 욕실에 동양적인 매력을 더해줍니다. 흥미진진하고 따뜻한 색상의 악센트를 위해 금색 수도꼭지를 선택하세요.",
                link: "/shop/products?category=Bathroom_fittings",
            },
        ],
        path: "/images/ideas/bathroom/trends/styles/oriental",
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.swiper-pagination--center {
    left: 50%;
    bottom: -12px;
    transform: translate(-50%, 100%);
    justify-content: center;
    :deep(.swiper-pagination-bullet) {
        border: none !important;
        background-color: #e2e8f0 !important;
        &-active {
            background-color: #007aff !important;
        }
    }
}
</style>