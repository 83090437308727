<template>
    <client-page>
        <shop-product-visual wide :image="`${path}/visual.webp`" :breadcrumbs="breadcrumbs" title="Boho bathroom" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm txt--dark">빌레로이앤보흐와 함께 완벽한 보헤미안 스타일 욕실을 디자인하는 방법을 알아보세요.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <template v-for="(item, index) in navigation">
                                <v-col cols="12" :key="index">
                                    <router-link :to="`#${index + 1}`" class="txt txt--sm d-flex align-center">
                                        <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                        {{ item }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <dot-list>
                                <template v-for="item in overview">
                                    <li :key="item">
                                        {{ item }}
                                    </li>
                                </template>
                            </dot-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="1">
            <v-container class="text-center">
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <h3 class="tit mb-12px mb-md-16px">보헤미안 스타일의 전형적인 특징</h3>
                        <p class="txt txt--sm txt--dark">보헤미안 스타일은 창의성, 기발함, 개성을 중시하지만, 특정 아이템과 색상은 여전히 ​​신중한 계획이 필요합니다. 어느 정도 조화를 이루면 욕실에 가볍고 통풍이 잘되는 분위기가 조성됩니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <v-row v-for="(item, index) in checkList" :key="index" class="row--xs">
                            <v-col cols="auto">
                                <v-icon small>mdi-check</v-icon>
                            </v-col>
                            <v-col>
                                <p class="txt txt--sm txt--dark">
                                    {{ item }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/loop-friends-washbasin-black-in-balinese-style-bathroom.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">보호 소재</div>
                        <div class="txt txt--sm txt--dark">
                            <p>보헤미안 스타일은 보헤미안 문화에서 유래되었으며 히피 미학의 요소를 반영합니다. 따라서 자연과의 연결은 또 다른 결정적 특징입니다. 나무, 라탄, 리넨과 같은 천연 소재는 이 스타일에 완벽합니다. 원단도 중요한 역할을 합니다. 부드럽고 가볍고 통풍이 잘 되어야 합니다. 프린지와 레이스는 훌륭한 추가 요소입니다. 한편, 고리버들 바구니는 보헤미안 욕실에 마무리 터치를 더할 것입니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="left">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">보헤미안 스타일에는 어떤 색상이 가장 잘 어울리나요?</div>
                        <div class="txt txt--sm txt--dark">
                            <p>기본 보헤미안 스타일 색상은 흙빛입니다: 갈색, 베이지, 모래, 카키, 올리브. 아늑한 느낌을 주는 따뜻한 색조를 선택하세요. 청록색, 밝은 녹색, 노란색, 보라색 또는 따뜻한 주황색과 같은 밝고 명랑한 색조로 생동감 있는 터치를 더하세요. 보헤미안 스타일에서는 단일 기본 색상과 하나의 악센트 색상에 고집하는 대신 욕실에 다양한 색상 악센트를 자유롭게 사용할 수 있습니다.</p>
                        </div>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/loop-and-friends-washbasin-in-white-bathroom-santorinian-style.webp`" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="2">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/subway-30-bathroom-with-boho-accessories.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">보헤미안 스타일 장식</div>
                        <div class="txt txt--sm txt--dark">
                            <p>보헤미안 욕실 장식은 호화롭고 다채로울 수 있습니다. 예를 들어, 팜파스 그라스로 채워진 베이지색 꽃병을 몇 장의 사진과 휴일 기념품 옆에 두는 것입니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="left" id="3">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">작은 욕실을 위한 보헤미안 스타일</div>
                        <div class="txt txt--sm txt--dark">
                            <p>작은 욕실조차도 아늑한 보헤미안 스타일로 빠르게 변신할 수 있습니다. 좁은 공간에서 인상적인 효과를 위해 직물과 작은 액세서리를 사용하여 환영하는 보헤미안 분위기를 연출하세요. 공간에 너무 많은 것을 집어넣으려고 하지 마세요. 방이 더 작아 보일 것입니다. 액세서리를 신중하게 선택하고 개성을 더하세요.</p>
                        </div>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/architectura-bathtub-with-hand-shower-and-toilet.webp`" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="4">
            <v-container>
                <div class="product-section-title">
                    <span class="tit font-weight-regular font-tertiary white">보헤미안 욕실을 위한 Villeroy &amp; Boch 솔루션</span>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="10">
                        <v-row justify="center" class="row--xxl">
                            <template v-for="(item, index) in items">
                                <v-col :cols="item.cols" :key="index">
                                    <v-card max-width="776" color="transparent" tile flat :href="item.link" class="mx-auto">
                                        <v-img :src="`${path}/${item.image}`" class="mb-12px mb-md-16px" />
                                        <div class="px-12px px-md-16px">
                                            <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                                {{ item.title }}
                                            </div>
                                            <p class="txt txt--sm grey--text text--darken-3">
                                                {{ item.text }}
                                            </p>
                                            <template v-if="item.link">
                                                <div class="mt-12px mt-md-16px d-flex align-center">
                                                    <span class="txt txt--xs txt--dark font-weight-medium">
                                                        {{ item.button }}
                                                    </span>
                                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                                </div>
                                            </template>
                                        </div>
                                    </v-card>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="4" class="page-section--grey">
            <v-container>
                <v-row justify="center" align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <v-img :src="`${path}/antao-bath-tub-in-stylish-bathromm.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">2024년 주택 욕실 트렌드를 알아보세요</div>
                        <p class="txt txt--sm txt--dark">보헤미안 룩 욕실 외에도 탐험할 수 있는 인테리어 디자인 트렌드가 많이 있습니다. 호화롭고 낭만적인 컨트리 스타일부터 도시적인 산업적 세련미와 미니멀리스트 재팬디 룩까지: 2024년 욕실 트렌드를 확인하고 자신에게 맞는 스타일을 찾으세요.</p>
                        <v-btn large color="#fff" elevation="2" class="rounded-xs mt-18px mt-md-24px" to="/ideas/bathroom/trends/2024">
                            더 많은 욕실 트렌드를 살펴보세요
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import SectionSlide from "@/components/client/dumb/section-slide.vue";
import DotList from "@/components/client/ideas/dot-list.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        SectionSlide,
        DotList,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 아이디어 및 트렌드",
                href: "/ideas/bathroom/trends",
            },
        ],
        navigation: [
            "보헤미안 스타일의 전형적인 특징",
            "보헤미안 스타일로 장식하세요",
            "작은 욕실의 보헤미안 스타일",
            "적합한 욕실 가구를 찾아보세요",
            //
        ],
        overview: [
            "창의적인 자유, 개성적인 스타일, 독특한 느낌에 중점을 둡니다.",
            "자연은 소재, 질감, 색상에 그대로 표현됩니다.",
            "흙빛깔의 기본 색조에 밝은 색상이 어우러졌습니다.",
            "아늑한 욕실 직물과 부드러운 러그가 아늑한 분위기를 조성합니다.",
            "미학과 기능성이 결합된 가구.",
            //
        ],
        checkList: [
            "보헤미안 스타일은 재기발랄한 디테일, 독특한 패턴, 호화로운 액세서리를 특징으로 합니다.",
            "자연과의 연결을 표현하기 위해 천연소재를 활용하세요.",
            "아늑한 직물과 따뜻한 조명을 추가하여 집안에 완벽한 보헤미안 룩을 연출해보세요.",
            //
        ],
        items: [
            {
                title: "베이지색 욕실용 직물을 확인해 보세요",
                text: "수건과 욕실 매트와 같은 아늑한 욕실 직물은 보헤미안 욕실에 완벽합니다. 포근한 목욕 가운과 욕실 러그도 보헤미안 휴양지에 잘 어울립니다.",
                // button: "빌레로이앤보흐 욕실용 직물",
                // link: "/shop/products?category=bathtub",
                image: "bath-textiles-in-bathroom-with-white-bath-tub.webp",
                cols: "6",
            },
            {
                title: "나무로 만든 세면대",
                text: "나무로 된 세면대 유닛은 실용적인 수납 공간을 더해주고 그 자체로 매력적인 특징입니다. 벽에 설치하면 아늑한 러그를 놓을 공간이 생기고 작은 욕실에 이상적입니다.",
                button: "세면기 찾기",
                link: "/shop/products?category=bathroom_sink",
                image: "antao-minimalist-console-bathroom.jpg.webp",
                cols: "6",
            },
        ],
        path: "/images/ideas/bathroom/trends/styles/boho",
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>