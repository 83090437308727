var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": `${_vm.path}/visual.webp`,
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Minimalism in the bathroom"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("미니멀리스트 욕실은 평화와 질서의 느낌을 조성하여 곧바로 긴장을 풀고 휴식을 취하고 싶은 마음을 불러일으킵니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_vm._l(_vm.navigation, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('router-link', {
      staticClass: "txt txt--sm d-flex align-center",
      attrs: {
        "to": `#${index + 1}`
      }
    }, [_c('v-img', {
      staticClass: "mr-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-down.svg"
      }
    }), _vm._v(" " + _vm._s(item) + " ")], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('dot-list', [_vm._l(_vm.overview, function (item) {
    return [_c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey",
    attrs: {
      "id": "1"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/antao-bathroom-wc-furniture-washbasin-white-and+oak.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("미니멀리스트 디자인 - 편안한 느낌을 위한 완벽한 구성")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v(" 미니멀리스트 디자인 트렌드는 모든 것을 필수 요소로 줄입니다. 미니멀리스트 욕실에는 불필요한 것은 없습니다. "), _c('br'), _vm._v(" 미니멀리즘은 작은 욕실에 좋은 선택으로, 공간을 더 넓어 보이게 하고 고요한 분위기를 조성합니다. ")])])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "2"
    }
  }, [_c('v-container', {
    staticClass: "text-center"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("고려할 사항")]), _c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("미니멀리스트 욕실 디자인 – 팁과 아이디어")]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("욕실에 신선한 미니멀리스트 룩을 주고 싶다면, 우리는 당신을 위한 많은 팁과 조언을 가지고 있습니다. 가이드를 따라 욕실을 순식간에 미니멀리스트적으로 탈바꿈하세요.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('section-slide', {
    attrs: {
      "items": _vm.tipSlide,
      "path": _vm.path
    }
  })], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey",
    attrs: {
      "id": "3"
    }
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('h3', {
    staticClass: "tit mb-12px mb-md-16px text-center"
  }, [_vm._v("미니멀리스트 욕실의 장점은 무엇인가요?")])]), _vm._l(_vm.checkList, function (item, index) {
    return _c('v-row', {
      key: index,
      staticClass: "row--xs"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-check")])], 1), _c('v-col', [_c('p', {
      staticClass: "txt txt--sm txt--dark"
    }, [_vm._v(" " + _vm._s(item) + " ")])])], 1);
  })], 2)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "4"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "product-section-title"
  }, [_c('span', {
    staticClass: "tit font-weight-regular font-tertiary white"
  }, [_vm._v("미니멀리스트 욕실을 위한 Villeroy & Boch 솔루션")])])])], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('v-row', {
    staticClass: "row--xxl",
    attrs: {
      "justify": "center"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": item.cols
      }
    }, [_c('v-card', {
      staticClass: "mx-auto",
      attrs: {
        "max-width": "776",
        "color": "transparent",
        "tile": "",
        "flat": "",
        "href": item.link
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": `${_vm.path}/${item.image}`
      }
    }), _c('div', {
      staticClass: "px-12px px-md-16px"
    }, [_c('div', {
      staticClass: "tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), item.link ? [_c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v(" " + _vm._s(item.button) + " ")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)] : _vm._e()], 2)], 1)], 1)];
  })], 2)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }