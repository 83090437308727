<template>
    <client-page>
        <shop-product-visual wide :image="`${path}/visual.webp`" :breadcrumbs="breadcrumbs" title="Vintage bathroom" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm txt--dark">욕실을 빈티지하게 꾸미고 싶으신가요? 이 인테리어 트렌드의 주요 특징에 대해 자세히 알아보려면 계속 읽어보세요.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <template v-for="(item, index) in navigation">
                                <v-col cols="12" :key="index">
                                    <router-link :to="`#${index + 1}`" class="txt txt--sm d-flex align-center">
                                        <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                        {{ item }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <dot-list>
                                <template v-for="item in overview">
                                    <li :key="item">
                                        {{ item }}
                                    </li>
                                </template>
                            </dot-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--first">
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8" class="text-center">
                        <h3 class="tit mb-12px mb-md-16px">욕실의 레트로한 미학</h3>
                        <div class="txt txt--sm txt--dark">
                            <p>빈티지 스타일은 각기 다른 시대를 아우르며, 각 시대마다 고유한 특징과 매력이 있습니다. 욕실에 빈티지 스타일 룩을 계획하고 있다면, 첫 번째 단계는 시대를 정하는 것입니다: 빅토리아, 1950년대, 1970년대, 바로크 - 어떤 시대가 될까요?</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="1">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/finion-bathtub-in-classic-bathroom-with-white-and-blue-walls.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">빈티지 스타일의 특징과 특징</div>
                        <div class="txt txt--sm txt--dark">
                            <p>어떤 시대를 선택하든 빈티지 욕실의 각 구성 요소에 대해 일관된 스타일을 목표로 하는 것이 중요합니다. 이렇게 하면 전체적으로 조화로운 효과가 생겨 사용자를 선택한 시대로 즉시 되돌릴 수 있습니다. 빈티지 욕실에서는 독립형 욕조가 인기 있는 선택이며, 특히 기발한 발과 눈길을 끄는 오버플로와 같은 복잡한 디테일이 있는 욕조가 그렇습니다. 욕조는 방의 중심이며 다른 모든 가구와 위생 설비는 욕조 주변에 배치됩니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="left">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">빈티지 스타일 색상</div>
                        <div class="txt txt--sm txt--dark">
                            <p>빈티지 욕실에 가장 인기 있는 색상은 흰색, 구리, 금, 황동과 같은 금속성 색조, 검정과 짙은 녹색입니다. 파스텔 색상은 1950년대 스타일 욕실에 좋은 선택입니다.</p>
                            <p>대조 역시 중요합니다. 정교한 돋을새김 무늬가 있는 흰색 욕실 세라믹은 금색 수도꼭지와 어두운 타일과 결합하면 특히 우아해 보일 것입니다.</p>
                        </div>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/collaro-washbasin-white-on-furniture-green-with-mirror-large.webp`" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="2">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/hommage-bathtub-black-white-and-freestanding.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">빈티지 스타일 디자인</div>
                        <div class="txt txt--sm txt--dark">
                            <p>빈티지 또는 레트로 욕실의 또 다른 특징은 나무 선반이나 장식적인 디테일이 있는 캐비닛과 같은 화려한 디자인의 대형 가구입니다. 현대적인 터치도 빈티지 스타일 욕실에 잘 어울립니다. 트렌디한 단색 색상 구성표와 빈티지 가구를 결합하여 이러한 효과를 만들어 보세요. 물론 빈티지 스타일 욕실에는 최첨단 기술이 허용됩니다!</p>
                            <p>세면대 아래의 세면대는 빈티지 욕실의 고전입니다. 여기에는 기발하고 정교한 디자인의 여지가 많습니다. Hommage 컬렉션의 복잡한 디테일과 강렬한 대비는 특히 눈길을 끄는 효과를 만들어냅니다</p>
                            <v-btn large color="#fff" elevation="2" class="rounded-xs mt-18px mt-md-24px" to="/b/hommage">
                                Hommage 컬렉션 보기
                                <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey" id="3">
            <v-container>
                <div class="tit-wrap text-center">
                    <h3 class="tit mb-12px mb-md-16px">빈티지한 장식으로 욕실을 스타일리시하게 연출해보세요</h3>
                    <p class="txt txt--sm txt--dark">장식은 세련된 빈티지 욕실을 마무리하는 데 중요한 역할을 합니다. 골드 수도꼭지는 우아하고 호화로운 효과를 만들어내며, 계단식 릴리프 기능이 있는 Hommage 컬렉션의 어두운 세면대 중 하나와 아름답게 어울립니다. 화려한 프레임에 큰 그림을 넣으면 마지막 화려함이 더해집니다. 복고풍 촛대를 사용하여 특이한 조명 효과를 만들 수도 있습니다.</p>
                </div>
                <three-images :path="path" first="hommage-washbasin-with-two-side-cabinets-with-glass-doors-and-bathtube-white.webp" second="mettlach-bathtub-tap-gold-with-handshower.webp" third="hommage-bathtub-black-white-and-freestanding-with-tap-silver.webp" />
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="product-section-title">
                    <span class="tit font-weight-regular font-tertiary white">욕실에 대한 기타 유용한 팁</span>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="10">
                        <v-row justify="center" class="row--xxl">
                            <template v-for="(item, index) in items">
                                <v-col :cols="item.cols" :key="index">
                                    <v-card max-width="776" color="transparent" tile flat :href="item.link" class="mx-auto">
                                        <v-img :src="`${path}/${item.image}`" class="mb-12px mb-md-16px" />
                                        <div class="px-12px px-md-16px">
                                            <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                                {{ item.title }}
                                            </div>
                                            <p class="txt txt--sm grey--text text--darken-3">
                                                {{ item.text }}
                                            </p>
                                            <template v-if="item.link">
                                                <div class="mt-12px mt-md-16px d-flex align-center">
                                                    <span class="txt txt--xs txt--dark font-weight-medium">
                                                        {{ item.button }}
                                                    </span>
                                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                                </div>
                                            </template>
                                        </div>
                                    </v-card>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import SectionSlide from "@/components/client/dumb/section-slide.vue";
import DotList from "@/components/client/ideas/dot-list.vue";
import ThreeImages from "@/components/client/ideas/three-images.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        SectionSlide,
        DotList,
        ThreeImages,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 아이디어 및 트렌드",
                href: "/ideas/bathroom/trends",
            },
        ],
        navigation: [
            "빈티지 스타일의 주요 특징은 무엇인가?",
            "빈티지 스타일에는 어떤 색상이 가장 잘 어울리나요?",
            "욕실에 빈티지한 분위기를 연출하는 방법",
            //
        ],
        overview: [
            "빈티지 룩은 다양한 시대와 스타일을 포괄합니다.",
            "모던하고 단색의 느낌이 빈티지 스타일과 완벽하게 조화를 이룹니다.",
            "호화로운 가구는 빈티지 스타일의 전형이다.",
            //
        ],
        items: [
            {
                title: "완벽한 빈티지 거울을 찾아보세요",
                text: "물론 빈티지 욕실에는 거울이 필수적입니다. 모양은 시대에 따라 다르지만 거울은 항상 매우 커야 합니다.",
                button: "빌레로이앤보흐 욕실거울",
                link: "/shop/products?category=Bathroom_mirrors",
                image: "collaro-washbasins-white-with-mirror-large.webp",
                cols: "6",
            },
            {
                title: "2024년 욕실 트렌드를 알아보세요",
                text: "매력적이고 지속 가능한 빈티지 스타일 외에도 2024년에는 다양한 욕실 디자인 트렌드가 있습니다. 취향과 욕실 디자인에 가장 잘 맞는 스타일과 색상 구성표를 선택하세요.",
                button: "2024년 욕실 트렌드",
                link: "/ideas/bathroom/trends/2024",
                image: "antao-bath-tub-in-stylish-bathromm.webp",
                cols: "6",
            },
        ],
        path: "/images/ideas/bathroom/trends/styles/vintage",
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>