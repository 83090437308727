import { render, staticRenderFns } from "./wood.vue?vue&type=template&id=28ceccd4&scoped=true"
import script from "./wood.vue?vue&type=script&lang=js"
export * from "./wood.vue?vue&type=script&lang=js"
import style0 from "./wood.vue?vue&type=style&index=0&id=28ceccd4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ceccd4",
  null
  
)

export default component.exports