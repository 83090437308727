import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import axios from "@/plugins/axios";
// import CookieStorage from "@/plugins/cookie-storage";

import Main from "@/pages/client/Main.vue";
import { BoardRoutes } from "./board.router";
import { BOARD_CATEGORIES__JFL_GUIDES, FORM_CATEGORIES__JFL_CARES, USER_TYPES } from "@/assets/variables";

Vue.use(VueRouter);

// const cookieStorage = new CookieStorage();

/**
 * @type {RouteConfig[]}
 */
const routes = [
    ////////////////////////////////////////////////////////////////////////////
    // 공통 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        path: "/common/kcp/cert/request",
        component: () => import("../pages/common/kcp/KCPCertRequest.vue"),
        props: true,
    },
    {
        path: "/common/kcp/cert/response",
        component: () => import("../pages/common/kcp/KCPCertResponse.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/",
        component: Main,
    },
    {
        // Terms 페이지
        path: "/terms/:code",
        component: () => import(/* webpackChunkName: "terms" */ "../pages/client/terms/TermsView.vue"),
        props: true,
    },
    {
        // VR Showroom
        path: "/VR-showroom",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/sub/VRShowroomPage.vue"),
        props: true,
    },
    {
        // 3D Interir
        path: "/3d-interir",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/sub/3DInterirPage.vue"),
        props: true,
    },
    {
        // 문의 및 제휴
        path: "/form/inquiry-partner",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/InquiryPartnerPage.vue"),
        props: true,
    },
    {
        // 쇼핑몰 메인 & 상품 리스트
        path: "/shop",
        redirect: { path: "/shop/products" },
        props: true,
    },
    {
        // 쇼핑몰 메인 & 상품 리스트
        path: "/shop/products",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/products/ProductListPage.vue"),
        props: true,
    },
    {
        // 상품 뷰
        path: "/shop/products/:_product",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/products/ProductViewPage.vue"),
        props: true,
    },
    {
        // 욕실 수전 개요
        path: "/shop/bathroom-fittings/overview",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/products/OverviewPage.vue"),
        props: true,
    },
    {
        // New Hit
        path: "/shop/new-hit",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/products/ProductNewHit.vue"),
        props: true,
    },
    // Room Ideas & Planners
    {
        // 욕실 개요
        path: "/ideas/bathroom/overview",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/OverviewPage.vue"),
        props: true,
    },
    {
        // 욕실 개요 - 욕실 계획하기
        path: "/ideas/bathroom/overview/planning",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/OverviewPage/planning.vue"),
        props: true,
    },
    {
        // 욕실 개요 - 모든 요구에 맞는 공간 솔루션
        path: "/ideas/bathroom/overview/solution",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/OverviewPage/solution.vue"),
        props: true,
    },
    {
        // 욕실 개요 - 욕실의 작은 수리
        path: "/ideas/bathroom/overview/repairs",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/OverviewPage/repairs.vue"),
        props: true,
    },
    {
        // 욕실 개요 - 욕실 소재
        path: "/ideas/bathroom/overview/material",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/OverviewPage/material.vue"),
        props: true,
    },
    {
        // 욕실 개요 - 욕실 기능
        path: "/ideas/bathroom/overview/features",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/OverviewPage/features.vue"),
        props: true,
    },
    {
        // 욕실 개요 - 욕실을 직접 설치하세요
        path: "/ideas/bathroom/overview/installing",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/OverviewPage/installing.vue"),
        props: true,
    },
    {
        // 욕실 개요 - 깨끗한 욕실
        path: "/ideas/bathroom/overview/cleaning",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/OverviewPage/cleaning.vue"),
        props: true,
    },
    {
        // 욕실 개요 - 욕실의 색상
        path: "/ideas/bathroom/overview/colours",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/OverviewPage/colours.vue"),
        props: true,
    },
    {
        // 욕실 개요 - 수납공간을 만들어보세요
        path: "/ideas/bathroom/overview/storage",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/OverviewPage/storage.vue"),
        props: true,
    },
    {
        // 욕실 개요 - 욕실 조명을 적절하게 켜세요
        path: "/ideas/bathroom/overview/lighting",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/OverviewPage/lighting.vue"),
        props: true,
    },
    {
        // 욕실 플래너
        path: "/ideas/bathroom/planner",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/PlannerPage.vue"),
        props: true,
    },
    {
        // 욕실 플래너 - 욕실의 슬로프
        path: "/ideas/bathroom/planner/slope",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/PlannerPage/slope.vue"),
        props: true,
    },
    {
        // 욕실 플래너 - 웰니스 욕실에 대한 추가 정보
        path: "/ideas/bathroom/planner/wellness",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/PlannerPage/wellness.vue"),
        props: true,
    },
    {
        // 욕실 아이디어 및 트렌드
        path: "/ideas/bathroom/trends",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/TrendsPage.vue"),
        props: true,
    },
    {
        // 욕실 아이디어 및 트렌드 - 2024년 욕실 트렌드
        path: "/ideas/bathroom/trends/2024",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/TrendsPage/trends-2024.vue"),
        props: true,
    },
    {
        // 욕실 아이디어 및 트렌드 - 스칸디
        path: "/ideas/bathroom/trends/styles/scandi",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/TrendsPage/styles/scandi.vue"),
        props: true,
    },
    {
        // 욕실 아이디어 및 트렌드 - 산업적
        path: "/ideas/bathroom/trends/styles/industrial",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/TrendsPage/styles/industrial.vue"),
        props: true,
    },
    {
        // 욕실 아이디어 및 트렌드 - 지중해
        path: "/ideas/bathroom/trends/styles/mediterranean",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/TrendsPage/styles/mediterranean.vue"),
        props: true,
    },
    {
        // 욕실 아이디어 및 트렌드 - 재팬디
        path: "/ideas/bathroom/trends/styles/japandi",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/TrendsPage/styles/japandi.vue"),
        props: true,
    },
    {
        // 욕실 아이디어 및 트렌드 - 미니멀리스트
        path: "/ideas/bathroom/trends/styles/minimalist",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/TrendsPage/styles/minimalist.vue"),
        props: true,
    },
    {
        // 욕실 아이디어 및 트렌드 - 보헤미안
        path: "/ideas/bathroom/trends/styles/boho",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/TrendsPage/styles/boho.vue"),
        props: true,
    },
    {
        // 욕실 아이디어 및 트렌드 - 휘게
        path: "/ideas/bathroom/trends/styles/hygge",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/TrendsPage/styles/hygge.vue"),
        props: true,
    },
    {
        // 욕실 아이디어 및 트렌드 - 별장
        path: "/ideas/bathroom/trends/styles/country",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/TrendsPage/styles/country.vue"),
        props: true,
    },
    {
        // 욕실 아이디어 및 트렌드 - 우디
        path: "/ideas/bathroom/trends/styles/wood",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/TrendsPage/styles/wood.vue"),
        props: true,
    },
    {
        // 욕실 아이디어 및 트렌드 - 빈티지
        path: "/ideas/bathroom/trends/styles/vintage",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/TrendsPage/styles/vintage.vue"),
        props: true,
    },
    {
        // 욕실 아이디어 및 트렌드 - oriental
        path: "/ideas/bathroom/trends/styles/oriental",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/TrendsPage/styles/oriental.vue"),
        props: true,
    },
    {
        // 욕실 아이디어 및 트렌드 - 럭셔리
        path: "/ideas/bathroom/trends/styles/luxurious",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/TrendsPage/styles/luxurious.vue"),
        props: true,
    },
    {
        // 욕실 바꾸기
        path: "/ideas/bathroom/renovation",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/bathroom/RenovationPage.vue"),
        props: true,
    },
    {
        // 주방에 대한 영감
        path: "/ideas/kitchen/overview",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/kitchen/OverviewPage.vue"),
        props: true,
    },
    {
        // 주방 유형
        path: "/ideas/kitchen/overview/types",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/kitchen/OverviewPage/KitchensTypesPage.vue"),
        props: true,
    },
    {
        // 주방 관리 팁
        path: "/ideas/kitchen/cleaning",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/kitchen/CleaningPage.vue"),
        props: true,
    },
    {
        // 주방 소재
        path: "/ideas/kitchen/material",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/kitchen/MaterialPage.vue"),
        props: true,
    },
    {
        // 빌레로이 세라믹의 장점
        path: "/ideas/kitchen/material/advantages",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/kitchen/MaterialPage/AdvantagesPage.vue"),
        props: true,
    },
    {
        // 세라믹플러스
        path: "/ideas/kitchen/material/ceramicplus",
        component: () => import(/* webpackChunkName: "ideas" */ "../pages/client/ideas/kitchen/MaterialPage/CeramicPlusPage.vue"),
        props: true,
    },
    // Sales
    {
        path: "/shop/sales",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/sales/SalesListPage.vue"),
        props: true,
    },
    // Company
    {
        // 성과
        path: "/company/about/figures",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/company/about/FiguresPage.vue"),
        props: true,
    },
    {
        // 비전과 미션
        path: "/company/about/vision",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/company/about/VisionPage.vue"),
        props: true,
    },
    // 품질과 혁신
    {
        // 창의적 제품
        path: "/company/innovation/products",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/company/innovation/InnovativeProductsPage.vue"),
        props: true,
    },
    {
        // 수상경력
        path: "/company/innovation/awards",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/company/innovation/AwardsPage.vue"),
        props: true,
    },
    {
        // Digital Services
        path: "/company/innovation/digital",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/company/innovation/DigitalServicesPage.vue"),
        props: true,
    },
    // 역사와 전통
    {
        // 역사
        path: "/company/history/history",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/company/history/HistoryPage.vue"),
        props: true,
    },
    {
        // 전통
        path: "/company/history/tradition",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/company/history/TraditionPage.vue"),
        props: true,
    },
    // 관련 브랜드
    {
        path: "/company/brands",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/company/BrandsPage.vue"),
        props: true,
    },
    // 사회적 책임
    {
        path: "/company/responsibility",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/company/ResponsibilityPage.vue"),
        props: true,
    },
    // Collections
    {
        path: "/b/:code1",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/collection/CollectionPage.vue"),
        props: true,
    },
    {
        path: "/b/:code1/:code2",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/collection/CollectionPage.vue"),
        props: true,
    },
    {
        path: "/b/:code1/:code2/:code3",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/collection/CollectionPage.vue"),
        props: true,
    },
    {
        path: "/b/:code1/:code2/:code3/:code4",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/collection/CollectionPage.vue"),
        props: true,
    },
    {
        // Collections - Antao
        path: "/collections/antao",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/collections/AntaoPage.vue"),
        props: true,
    },
    {
        // Collections - ViClean
        path: "/collections/viclean",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/collections/ViCleanPage.vue"),
        props: true,
    },
    {
        // Collections - Loop & Friends
        path: "/collections/loop-friends",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/collections/LoopFriendsPage.vue"),
        props: true,
    },
    {
        // Collections - Collaro
        path: "/collections/collaro",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/collections/CollaroPage.vue"),
        props: true,
    },
    {
        // Collections - Hommage
        path: "/collections/hommage",
        component: () => import(/* webpackChunkName: "company" */ "../pages/client/collections/HommagePage.vue"),
        props: true,
    },

    {
        // 기획전 목록
        path: "/shop/exhibitions",
        component: () => import(/* webpackChunkName: "shop-exhibitions" */ "../pages/client/shop/exhibitions/ExhibitionList.vue"),
        props: true,
    },
    {
        // 기획전 상세
        path: "/shop/exhibitions/:_exhibition",
        component: () => import(/* webpackChunkName: "shop-exhibitions" */ "../pages/client/shop/exhibitions/ExhibitionView.vue"),
        props: true,
    },
    {
        // 브랜드 목록
        path: "/shop/brands",
        component: () => import(/* webpackChunkName: "shop-brands" */ "../pages/client/shop/brands/BrandListPage.vue"),
        props: true,
    },
    {
        // 브랜드 상세
        path: "/shop/brands/:code",
        component: () => import(/* webpackChunkName: "shop-brands" */ "../pages/client/shop/brands/BrandViewPage.vue"),
        props: true,
    },
    {
        // 온라인 쇼룸 목록
        path: "/shop/showrooms",
        component: () => import(/* webpackChunkName: "shop-showrooms" */ "../pages/client/shop/showrooms/ShowroomListPage.vue"),
        props: true,
    },
    {
        // 온라인 쇼룸 목록
        path: "/shop/showrooms/:code",
        component: () => import(/* webpackChunkName: "shop-showrooms" */ "../pages/client/shop/showrooms/ShowroomListPage.vue"),
        props: true,
    },
    {
        // 주문
        path: "/shop/order",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/order.vue"),
        props: true,
    },
    {
        // 주문완료
        path: "/shop/result",
        component: () => import(/* webpackChunkName: "shop" */ "../pages/client/shop/result.vue"),
        props: true,
    },
    {
        // 로그인
        path: "/login",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/main.vue"),
        props: true,
    },
    {
        // 비밀번호 찾기
        path: "/login/find-password",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-password.vue"),
        props: true,
    },
    {
        // 아이디 찾기
        path: "/login/find-id",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-id.vue"),
        props: true,
    },
    {
        // SNS 로그인 검증
        path: "/login/sns-validate",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/sns-validate.vue"),
        props: true,
    },
    {
        // 회원가입
        path: "/join",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/join.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 대시보드
        path: "/mypage",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageDashboardPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 대시보드
        path: "/mypage/dashboard",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageDashboardPage.vue"),
        children: [...BoardRoutes],
        props: true,
    },
    {
        // 준회원페이지 - 장바구니
        path: "/mypage/cart",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageCartPage.vue"),
        props: () => ({ type: "cart" }),
    },
    {
        // 준회원페이지 - 장바구니
        path: "/mypage/cart/regular",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageCartPage.vue"),
        props: () => ({ type: "regular" }),
    },
    {
        // 준회원페이지 - 주문내역
        path: "/mypage/orders",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageOrdersPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 개인결제
        path: "/mypage/target-payments",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageTargetPayments.vue"),
        children: [...BoardRoutes],
        props: true,
    },
    {
        // 준회원페이지 - 영수증
        path: "/mypage/receipt",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageReceiptPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 쿠폰
        path: "/mypage/coupons",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageCouponsPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 쿠폰
        path: "/mypage/coupons/:tab",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageCouponsPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 찜한상품
        path: "/mypage/likes",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageLikesPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 적립금 내역
        path: "/mypage/points",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypagePointsPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 회원 정보 수정
        path: "/mypage/myinfo",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageMyinfoPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 배송지 정보
        path: "/mypage/destinations",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageDestinationsPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 환불계좌 관리
        path: "/mypage/refund-account",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageRefundAccountPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 회원 탈퇴
        path: "/mypage/leave",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 나의 리뷰
        path: "/mypage/reviews",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageReviewList.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 나의 상품 Q&A
        path: "/mypage/inquiries",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageInquireList.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 1:1 문의 내역
        path: "/mypage/questions",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageQuestionsPage.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 기타 문의 내역
        path: "/mypage/forms",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageFormList.vue"),
        props: true,
    },
    {
        // 준회원페이지 - 대량구매요청
        path: "/mypage/bulk-purchase",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MypageBulkPurchasePage.vue"),
        props: true,
    },
    {
        // 고객센터 - 대량구매요청
        path: "/mypage/bulk-purchase/inquire",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/mypage/BulkPurchaseForm.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 대시보드
        path: "/promoter",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterDashboardPage.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 대시보드
        path: "/promoter/dashboard",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterDashboardPage.vue"),
        children: [...BoardRoutes],
        props: true,
    },
    {
        // 정회원페이지 - 보너스 내역
        path: "/promoter/bonuses",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterBonusesPage.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 보너스 내역
        path: "/promoter/bonuses__:tab",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterBonusesPage.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 보너스 출금
        path: "/promoter/bonuses-withdraw",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterBonusesWithdraw.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 추천회원 구매확정 내역
        path: "/promoter/promotee-purchases",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterPromoteePurchases.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 나의 구매확정 내역
        path: "/promoter/promoter-purchases",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterPromoterPurchases.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 나의 구매확정 내역
        path: "/promoter/scores",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterScoresPage.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 나의 구매확정 내역
        path: "/promoter/bank",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterBankPage.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 나의 추천회원
        path: "/promoter/promotees",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterPromoteesPage.vue"),
        props: true,
    },
    {
        // 정회원페이지 - 1:1 문의 내역
        path: "/promoter/questions",
        component: () => import(/* webpackChunkName: "promoter" */ "../pages/client/promoter/PromoterQuestionsPage.vue"),
        props: true,
    },
    {
        // 오픈이벤트
        path: "/open-event",
        component: () => import(/* webpackChunkName: "about" */ "../pages/client/sub/event/OpenEventPage.vue"),
        props: true,
    },
    {
        // 회사소개
        path: "/about",
        component: () => import(/* webpackChunkName: "about" */ "../pages/client/sub/about/AboutPage.vue"),
        children: [...BoardRoutes],
        props: true,
    },
    {
        // 개인정보처리방침
        path: "/privacy-policy",
        component: () => import(/* webpackChunkName: "about" */ "../pages/client/sub/about/PrivacyPolicyPage.vue"),
        props: true,
    },
    {
        // 이용약관
        path: "/terms",
        component: () => import(/* webpackChunkName: "about" */ "../pages/client/sub/about/TermsPage.vue"),
        props: true,
    },
    {
        // 이메일무단수집거부
        path: "/email-policy",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/email-policy.vue"),
        props: true,
    },
    {
        // 고객센터 - 공지사항
        path: "/center/notice",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterNoticePage.vue"),
        props: true,
    },
    {
        // 고객센터 - 공지사항
        path: "/center/notice/:_notification",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterNoticePage.vue"),
        props: true,
    },
    {
        // 고객센터 - 자주묻는 질문
        path: "/center/faq",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterFaqPage.vue"),
        props: true,
    },
    {
        // 고객센터 - 문의하기
        path: "/center/inquiry",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterInquiryPage.vue"),
        props: true,
    },
    {
        // 고객센터 - 문의하기
        path: "/center/inquiry/:_form",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterInquiryPage.vue"),
        props: true,
    },
    {
        // 고객센터 - 개인회원서비스
        path: "/center/individual-service",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterIndividualServicePage.vue"),
        props: true,
    },
    {
        // 고객센터 - 개인회원 서비스 문의
        path: "/center/individual-service/inquire",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserServiceForm.vue"),
        props: { category: USER_TYPES.PERSON.value },
    },
    {
        // 고객센터 - 기업회원 서비스
        path: "/center/corporate-service",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterCorporateServicePage.vue"),
        props: true,
    },
    {
        // 고객센터 - 기업회원 서비스 문의
        path: "/center/corporate-service/inquire",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserServiceForm.vue"),
        props: { category: USER_TYPES.COMPANY.value },
    },
    {
        // 고객센터 - 첫 구매 가이드 문의
        path: "/center/first-buying-guide/inquire",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserGuideForm.vue"),
        props: { category: BOARD_CATEGORIES__JFL_GUIDES.FIRST_TIMERS.value },
    },
    {
        // 고객센터 - 첫 구매 가이드
        path: "/center/first-buying-guide",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterFirstBuyingGuidePage.vue"),
        children: [...BoardRoutes],
        props: true,
    },
    {
        // 고객센터 - 필독 가이드 문의
        path: "/center/must-read-guide/inquire",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserGuideForm.vue"),
        props: { category: BOARD_CATEGORIES__JFL_GUIDES.MUST_NOTICES.value },
    },
    {
        // 고객센터 - 필독 가이드
        path: "/center/must-read-guide",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterMustReadGuidePage.vue"),
        children: [...BoardRoutes],
        props: true,
    },
    {
        // 고객센터 - 설치 서비스 문의
        path: "/center/installation-service/inquire",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserGuideForm.vue"),
        props: { category: BOARD_CATEGORIES__JFL_GUIDES.INSTALLATION.value },
    },
    {
        // 고객센터 - 설치 서비스
        path: "/center/installation-service",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterInstallationServicePage.vue"),
        children: [...BoardRoutes],
        props: true,
    },
    {
        // 고객센터 - 출장 컨설팅
        path: "/center/business-trip-consulting",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterBusinessTripConsultingPage.vue"),
        props: true,
    },
    {
        // 고객센터 - 출장 컨설팅 문의
        path: "/center/business-trip-consulting/inquire",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserConsultingForm.vue"),
        props: { category: FORM_CATEGORIES__JFL_CARES.BUSINESS_TRIPS.value },
    },
    {
        // 고객센터 - 온라인 컨설팅
        path: "/center/online-consulting",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterOnlineConsultingPage.vue"),
        props: true,
    },
    {
        // 고객센터 - 온라인 컨설팅 문의
        path: "/center/online-consulting/inquire",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserConsultingForm.vue"),
        props: { category: FORM_CATEGORIES__JFL_CARES.ONLINE_CONSULT.value },
    },
    {
        // 고객센터 - 딜리버리 설치 서비스
        path: "/center/delivery-installation-service",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterDeliveryInstallationServicePage.vue"),
        props: true,
    },
    {
        // 고객센터 - 딜리버리 설치 서비스 문의
        path: "/center/delivery-installation-service/inquire",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserConsultingForm.vue"),
        props: { category: FORM_CATEGORIES__JFL_CARES.DELIVERY_QUERY.value },
    },
    {
        // 고객센터 - 전담 수리 서비스
        path: "/center/dedicated-repair-service",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterDedicatedRepairServicePage.vue"),
        props: true,
    },
    {
        // 고객센터 - 전담 수리 서비스 문의
        path: "/center/dedicated-repair-service/inquire",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserConsultingForm.vue"),
        props: { category: FORM_CATEGORIES__JFL_CARES.AFTER_SERVICES.value },
    },
    {
        // 고객센터 - 안심 상담
        path: "/center/consultation",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterConsultationPage.vue"),
        props: true,
    },
    {
        // 고객센터 - 안심 상담 문의
        path: "/center/consultation/inquire",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/UserConsultingForm.vue"),
        props: { category: FORM_CATEGORIES__JFL_CARES.PRIVATES_QUERY.value },
    },
    {
        // 고객센터 - 대량구매요청
        path: "/center/bulk-purchase",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/CenterBulkPurchasePage.vue"),
        props: true,
    },
    {
        // 고객센터 - 대량구매요청
        path: "/center/bulk-purchase/inquire",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/BulkPurchaseForm.vue"),
        props: true,
    },
    {
        // 커뮤니티
        path: "/community",
        redirect: { path: "/community/news" },
        props: true,
    },
    {
        // 컨시어지 서비스
        path: "/concierge-service",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/concierge/ConciergeServicePage.vue"),
        props: true,
    },
    {
        // 커뮤니티 - 공지사항
        path: "/community/notices",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/community/CommunityNoticePage.vue"),
        props: true,
    },
    {
        // 커뮤니티 - 공지사항
        path: "/center/notices/:_notification",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/community/CommunityNoticePage.vue"),
        props: true,
    },
    {
        // 커뮤니티 - 게시판
        path: "/community/:category",
        component: () => import(/* webpackChunkName: "center" */ "../pages/client/community/CommunityBoardPage.vue"),
        children: [...BoardRoutes],
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 플러그인 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        path: "/plugins/nice/pay/result",
        component: () => import("../pages/plugins/nice/NICEPayResult.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ////////////////////////////////////////////////////////////////////////////
    {
        // 관리자 메인
        path: "/console",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Main.vue"),
        props: true,
    },
    {
        // 관리자 - 대시보드
        path: "/console/dashboard",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/Dashboard.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardList.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/board-comments",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardCommentList.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 게시판관리
        path: "/console/boards/:_board",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/board/BoardView.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 회원 - 회원직급
        path: "/console/user-grades",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-grades/UserGradeList.vue"),
        props: true,
    },
    {
        // 관리자 - 회원 - 회원등급 - 목록
        path: "/console/user-level",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-levels/UserLevelList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 - 회원등급 - 상세
        path: "/console/user-level/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-levels/UserLevelList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 - 탈퇴회원
        path: "/console/withdrawn-users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/withdrawn-users/WithdrawnUserList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 - 회원목록
        path: "/console/users",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/users/UserList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 - 회원상세
        path: "/console/users/:_user",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/users/UserView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 회원 - 발급쿠폰
        path: "/console/user-coupons",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-coupons/UserCouponList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사용자 정보
        path: "/console/inquiries",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/inquiries/InquiryList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사이트 - 기본정보
        path: "/console/site/setting",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/site/SiteSettingPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사이트 - 이용약관
        path: "/console/site/terms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/site/SiteTermList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 상품전시
        path: "/console/main/link",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainLinkSetting.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 쇼핑몰 - 대량등록
        path: "/console/shop/bulk-upload",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/bulk-upload/ShopBulkUpload2.vue"),
        props: true,
        scope: ["console"],
    },
    // {
    //     // 관리자 - 쇼핑몰 - 대량등록
    //     path: "/console/shop/bulk-upload",
    //     component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/bulk-upload/ShopBulkUpload.vue"),
    //     props: true,
    //     scope: ["console"],
    // },
    {
        // 관리자 - 쇼핑몰 - 배송정보
        path: "/console/shop/shippings",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/shippings/ShopShippingList.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 쇼핑몰 - 상품 - 목록
        path: "/console/shop/products",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/products/ProductList.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 쇼핑몰 - 상품 - 신규
        path: "/console/shop/products/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/products/ProductView.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 쇼핑몰 - 상품 - 상세
        path: "/console/shop/products/:_product",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/products/ProductView.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 쇼핑몰 - 카테고리
        path: "/console/shop/categories",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/categories/CategoryList.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 쇼핑몰 - 필터링
        path: "/console/shop/filtering",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/filtering/FilteringList.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 쇼핑몰 - 브랜드
        path: "/console/shop/brands",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/brands/BrandList.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 쇼핑몰 - 개인결제
        path: "/console/shop/target-payments",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/target-payments/TargetPaymentList.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 쇼핑몰 - 쇼룸
        path: "/console/shop/showrooms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/showrooms/ShowroomListPage.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 쇼핑몰 - 쇼룸
        path: "/console/shop/showrooms/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/showrooms/ShowroomListPage.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 쇼핑몰 - 기획전
        path: "/console/shop/exhibitions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/exhibitions/ShopExhibitionList.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 쇼핑몰 - 상품전시
        path: "/console/shop/display",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainDisplayPage.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 쇼핑몰 - 상품전시
        path: "/console/shop/display/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainDisplayPage.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 쇼핑몰 - 상품문의
        path: "/console/shop/inquires",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/inquires/InquireList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 쇼핑몰 - 구매후기
        path: "/console/shop/reviews",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/reviews/ReviewList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 주문 - 통계
        path: "/console/statistics",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/statistics/StatisticsView.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 주문 - 주문 관리
        path: "/console/shop/purchases/all",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/PurchaseList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 주문 - 미결제 관리
        path: "/console/shop/purchases/nonpayment",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/NonpaymentList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 주문 - 발주/발송 관리
        path: "/console/shop/purchases/payment",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/PaymentList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 주문 - 배송 관리
        path: "/console/shop/purchases/delivery",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/DeliveryList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 주문 - 취소 관리
        path: "/console/shop/purchases/cancel",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/CancelList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 주문 - 반품 관리
        path: "/console/shop/purchases/exchange",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/ExchangeList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 주문 - 교환 관리
        path: "/console/shop/purchases/return",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/ReturnList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 주문 - 구매확정 관리
        path: "/console/shop/purchases/complete",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/purchases/CompleteList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },

    {
        // 관리자 - 문의 - 제휴문의
        path: "/console/center/inquiry-partners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/forms/InquiryPartnerList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 문의 - 컨시어지
        path: "/console/center/concierge-forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/forms/ConciergeFormList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 문의 - 대량구매요청
        path: "/console/center/bulk-purchase-forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/forms/BulkPurchaseList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 문의 - 고객센터 개인 & 기업회원 서비스
        path: "/console/center/user-service-forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/forms/UserServiceList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 문의 - 고객센터 JFL 가이드
        path: "/console/center/user-guide-forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/forms/UserGuideList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 문의 - 고객센터 JFL 케어
        path: "/console/center/user-consulting-forms",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/forms/UserConsultingList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },
    {
        // 관리자 - 문의 - 고객센터 문의하기 / 마이페이지 1:1문의
        path: "/console/center/questions",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/questions/QuestionList.vue"),
        props: true,
        scope: ["console", "admin02", "admin03"],
    },

    {
        // 관리자 - 메인페이지 - 배너 관리
        path: "/console/main/banner/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainBannerPage.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 메인페이지 - 상품전시 관리
        path: "/console/main/display/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainDisplayPage.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 메인페이지 - 리뷰전시 관리
        path: "/console/main/review/:code",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/main/MainDisplayReview.vue"),
        props: true,
        scope: ["console", "admin02"],
    },

    {
        // 관리자 - 정산관리
        path: "/console/user-settlements",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-settlements/UserSettlementList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 정산상세
        path: "/console/user-settlements/:_settlement",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-settlements/UserSettlementView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 적립금 관리
        path: "/console/user-points",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-points/UserPointList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 직급포인트 관리
        path: "/console/user-scores",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-scores/UserScoreList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 보너스 관리
        path: "/console/user-bonuses",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/user-bonuses/UserBonusList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 코드관리
        path: "/console/shop/codes",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/codes/CodeList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 쇼핑몰 - 쿠폰정책
        path: "/console/shop/coupons",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/coupons/ShopCouponList.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 쇼핑몰 - 적립정책
        path: "/console/shop/points",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/shop/points/ShopPointList.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 공지사항
        path: "/console/center/notifications",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/notifications/NotificationList.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - FAQ
        path: "/console/center/faqs",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/center/faqs/FaqList.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 배너관리 - 목록
        path: "/console/banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banners/BannerList.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 배너관리 - 생성
        path: "/console/banners/create",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banners/BannerView.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 배너관리 - 상세
        path: "/console/banners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/banners/BannerView.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 띠배너관리
        path: "/console/band-banners",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/band-banners/BandBannerListPage.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
    {
        // 관리자 - 팝업관리
        path: "/console/popups",
        component: () => import(/* webpackChunkName: "console-app" */ "../pages/console/popups/PopupList.vue"),
        props: true,
        scope: ["console", "admin02"],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,

    scrollBehavior: (to, from, savedPosition) => {
        return new Promise((resolve) =>
            setTimeout(() => {
                if (to.hash) resolve({ selector: to.hash, behavior: "smooth" });

                if (savedPosition) {
                    resolve({ ...savedPosition, behavior: "smooth" });
                }

                if (to.path.includes("/shop/showrooms")) resolve();

                if (to?.path == from?.path) resolve();

                resolve({ x: 0, y: 0, behavior: "smooth" });
            }, 500)
        );
    },
});

router.beforeEach((to, from, next) => {
    try {
        const accessToken = sessionStorage.getItem("accessToken");
        axios.defaults.headers.common["Authorization"] = !!accessToken ? `Bearer ${accessToken}` : "";

        const payload = accessToken ? JSON.parse(atob(accessToken.split(".")[1])) || {} : {};
        const route = routes.find((route) => [to?.path, to?.matched?.[0]?.path].includes(route.path));
        if (route?.scope) {
            if (!accessToken) throw new Error("로그인 후 이용 가능합니다", { cause: "/login" });

            const { scope = [] } = payload;
            if (!scope.some((scope) => route.scope.includes(scope))) throw new Error("접근 권한이 없습니다");

            next();
        } else if (route?.userTypes) {
            if (!accessToken) throw new Error("로그인 후 이용 가능합니다", { cause: "/login" });

            const { userType } = payload;
            if (!route.userTypes.includes(userType)) throw new Error("접근 권한이 없습니다");

            next();
        } else next();
    } catch (error) {
        alert(error.message);

        let path;
        if (error.cause) {
            path = ["/mypage", "/promoter", "/console"].find((path) => to.path.includes(path)) || "/";
            if (path == "/") path = error.cause;
        } else if (from.path != "/") path = routes.find((route) => route.path == from.path)?.path;
        else {
            path = ["/mypage", "/promoter", "/console"].find((path) => to.path.includes(path)) || "/";
        }

        next({ path });
    }
});
export default router;
