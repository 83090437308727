var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": `${_vm.path}/visual.webp`,
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Wood in the bathroom"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("나무 가구는 욕실을 더 따뜻하고 아늑하게 느끼게 합니다. 욕실에서 나무에 대한 현대적인 아이디어를 여기에서 찾을 수 있습니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_vm._l(_vm.navigation, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('router-link', {
      staticClass: "txt txt--sm d-flex align-center",
      attrs: {
        "to": `#${index + 1}`
      }
    }, [_c('v-img', {
      staticClass: "mr-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-down.svg"
      }
    }), _vm._v(" " + _vm._s(item) + " ")], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('dot-list', [_vm._l(_vm.overview, function (item) {
    return [_c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("나무는 욕실에 따뜻한 분위기를 조성합니다.")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("목재는 다양한 장점을 제공하는 다재다능한 소재입니다. 지역산 목재를 선택하면 매우 지속 가능한 소재로, 거주 공간을 따뜻하고 매력적으로 느끼게 합니다. 목재는 내구성이 뛰어나고 견고한 소재로, 아름답게 오래갑니다.")]), _c('p', [_vm._v("나무는 욕실에서 수증기를 흡수한 다음 공기가 건조해지면 다시 방출하여 실내 기후에 긍정적인 영향을 미칠 수 있다는 점에 주목할 가치가 있습니다. 또한 플라스틱과 달리 진짜 나무는 항균 특성이 있습니다.")])])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "right",
      "id": "1"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/legato-bathroom-furniture-kansas-oak-with-shower-and-toilet.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실에 나무를 사용할 수 있나요?")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("위에서 언급한 장점 때문에 목재는 욕실에 인기 있는 선택입니다. 다양한 종류와 색상의 목재 중에서 선택할 수 있습니다. 참나무와 호두나무는 매우 인기가 있습니다. 목재처럼 보이는 MDF나 칩보드는 약간 저렴합니다.")]), _c('p', [_vm._v("욕실의 습도가 높으면 처리되지 않은 목재에 곰팡이가 생길 수 있습니다. 따라서 욕실용 목재는 처리하여 물이 침투하지 않도록 하는 것이 중요합니다. 목재에 물이 고이면 항상 즉시 닦아내야 합니다.")])])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("고려해야 할 핵심 사항")]), _c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("습한 공간에 적합한 목재 선택")]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("욕실의 스타일을 보완하는 동시에 습기를 잘 견디는 목재 종류를 선택하세요. 오크는 내구성이 뛰어나고 습기에 대한 내성이 좋기 때문에 세면대와 샤워기에 특히 좋은 선택입니다. 낙엽송, 대나무, 아카시아도 욕실에 잘 어울립니다. 더글러스 전나무, 호두 또는 로빈과 같은 목재 종류는 나무 벽 패널에 잘 어울립니다.")])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "2"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/collaro-bathtub-white-toilet-white-and-washbasin-double-with-mirror-large.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실에는 밝은 나무색을 사용하세요")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("솔리드 오크, 낙엽송, 너도밤나무, 소나무는 욕실에서 스타일리시하게 보이는 밝은 색상의 목재입니다. Villeroy & Boch에서 화이트 오크, 오크 베니어 또는 노르딕 오크 등의 욕실 가구를 찾을 수 있습니다. 밝은 색상의 목재는 욕실에 통풍이 잘되는 느낌을 줍니다.")])])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "left",
      "id": "3"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실에는 어두운 나무색을 사용하세요")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("어두운 목재 종류는 욕실에서 우아하거나 더 소박하게 보일 수 있습니다. 호두, 마호가니 또는 티크를 선택하여 방에 특별한 터치를 더하세요. 완벽한 조합: 어두운 목재와 스테인리스 스틸. Villeroy & Boch는 Warm Walnut, Walnut Veneer 및 Arizona Oak를 포함하여 어두운 목재 색상의 광범위한 욕실 가구를 제공합니다.")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/subway30-bath-tub-in-rustic-bathroom.webp`
    }
  })], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("나무로 만든 현대적인 욕실 영감")]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("욕실에서 나무를 사용하는 방법은 많습니다. 예를 들어, 소박한 나무 세면대는 훌륭한 선택이 될 수 있습니다. 가구 외에도 나무 또는 나무 효과 타일을 바닥에 사용할 수 있습니다. 한편, 나무 벽 패널은 매우 눈길을 끄는 효과를 냅니다.")])]), _c('three-images', {
    attrs: {
      "path": _vm.path,
      "first": "artis-washbasin-rust-on-beige-finion-vanity-unit.webp",
      "second": "subway-3.0-bathroom-furniture-kansas-oak.webp",
      "third": "subway30-bathroom-furniture-in-light-wooden-bathroom.webp"
    }
  })], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey",
    attrs: {
      "id": "4"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/antao-freestanding-bathtub-shower-system-washbasin-in-white-lofty-design.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("2024년 욕실 트렌드")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("목재는 매력적인 컨트리 스타일 가구부터 호화로운 빈티지 룩까지 다양한 욕실 트렌드와 아름답게 조화를 이룹니다. 목재는 또한 스칸디나 재팬디와 같은 미니멀리스트 스타일과 완벽하게 어울립니다.")])]), _c('v-btn', {
    staticClass: "rounded-xs mt-18px mt-md-24px",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/ideas/bathroom/trends/2024"
    }
  }, [_vm._v(" 지금 당장 2024년 욕실 트렌드를 확인해보세요! "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }