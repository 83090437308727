var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": `${_vm.path}/visual.webp`,
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Mediterranean bathroom"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("지중해 스타일의 욕실로 집에서 느긋한 섬의 분위기를 즐기세요. 여기에서 최고의 팁을 모두 찾아보세요.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_vm._l(_vm.navigation, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('router-link', {
      staticClass: "txt txt--sm d-flex align-center",
      attrs: {
        "to": `#${index + 1}`
      }
    }, [_c('v-img', {
      staticClass: "mr-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-down.svg"
      }
    }), _vm._v(" " + _vm._s(item) + " ")], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('dot-list', [_vm._l(_vm.overview, function (item) {
    return [_c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1)], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("지중해풍 욕실 - 남쪽의 터치")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("\"지중해\"라는 단어만 들어도 이탈리아와 스페인, 여름, 해변과 바다의 이미지가 떠오릅니다. 지중해 스타일의 디자인은 욕실에 느긋하지만 우아한 휴일 분위기를 가져다 줄 것입니다.")])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "right",
      "id": "1"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/loop-friends-washbasin-white-in-peach-coloured-bathroom.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("지중해 스타일의 특징은 무엇인가요?")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("\"지중해\"라는 단어는 라틴어로 \"지구의 중심\"을 뜻하며 스페인, 이탈리아, 그리스 건축을 말합니다. 자연과의 연결은 지중해 욕실 디자인의 또 다른 중요한 측면입니다. 스페인 섬이나 그리스의 욕실에서 찾을 수 있는 것과 유사한 자연스러운 색상과 소재를 선택하세요.")]), _c('p', [_vm._v("곡선 모양은 지중해 욕실에 지속적인 우아함을 줍니다. 더 큰 방에서는 기둥이 가구와 수도꼭지에 대한 단순하고 우아한 모습과 함께 효과를 보완할 수 있습니다.")])])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "left",
      "id": "2"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("천연소재와 질감")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("소박한 나무, 테라코타 타일, 천연석, 라탄, 세라믹과 같은 천연 소재는 지중해 스타일에 완벽한 선택입니다. 지중해 스타일로 메인 욕실이나 손님용 화장실을 디자인하든 천연 소재를 기본으로 선택하고 질감을 사용하여 시각적 매력을 더하세요.")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/loop-and-friends-washbasin-in-white-bathroom-santorinian-style.webp`
    }
  })], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "right",
      "id": "3"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/subway-30-white-toilet-attic-bathroom-with-wood.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실을 위한 지중해 색상")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("욕실에 어떤 지중해 스타일을 선택하느냐에 따라 색상 구성이 달라집니다. 예를 들어, 밝은 흰색과 하늘색은 그리스의 전형인 반면, 스페인 욕실은 종종 모래와 베이지색 음영으로 장식됩니다. 일반적으로 테라코타, 모래 또는 베이지와 같은 흙빛 색조가 기본 색상으로 잘 어울립니다. 그런 다음 밝은 빨강, 파랑, 녹색 또는 보라색을 추가할 수 있습니다.")])])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "4"
    }
  }, [_c('v-container', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("지중해 분위기의 장식")]), _c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("지중해 웰빙 오아시스를 위한 디자인 아이디어")]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("지중해 욕실을 위한 디자인 아이디어는 부족하지 않습니다. \"Loop & Friends\" 컬렉션은 욕실을 웰빙의 성지로 바꾸고 싶을 때 좋은 선택입니다.")]), _c('v-btn', {
    staticClass: "rounded-xs mt-18px mt-md-24px",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/b/loop-friends/"
    }
  }, [_vm._v(" Loop & Friends를 만나보세요 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('three-images', {
    attrs: {
      "path": _vm.path,
      "first": "loop-and-friends-withe-washbasin-mediterranean-style-pink-wall.webp",
      "second": "loop-friends-washbasin-black-in-balinese-style-bathroom.webp",
      "third": "loop-friends-washbasin-white-in-modern-light-bathroom.webp"
    }
  })], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "right"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/more-to-see-mirror-in-marrakech-style+bathroom2.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("통풍이 잘되는 커튼과 가벼운 직물")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("지중해 욕실은 자연스럽고 통풍이 잘되는 느낌이 특징입니다. 실용적인 면 외에도 밝은 색상의 부드러운 타월과 밝은 색상의 욕실 러그는 방에 편안한 분위기를 조성합니다. 창문에 밝은 색상의 가벼운 커튼을 설치하면 충분한 빛이 들어오지만 엿보는 눈으로부터 보호해줍니다.")])])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "left"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("우아한 꽃병: 시대를 초월한 아름다움")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("식물은 지중해 욕실에서 중요한 특징입니다. 지중해 지역의 식물을 선택하세요: 라벤더, 로즈마리, 감귤류도 방에 기분 좋은 향을 가져다 줄 것입니다.")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/lave-vases-blue-on-shelf-with-porcelain-pumpkin.webp`
    }
  })], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "5"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/antao-bath-tub-in-stylish-bathromm.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실을 위한 2024년 트렌드 더 보기")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("지중해 스타일은 현대식 욕실에 쉽게 구현할 수 있습니다. 그럼에도 불구하고, 현재 유행하는 다른 욕실 스타일이 많이 있습니다. 더 많은 트렌드를 알아보고 욕실에 완벽한 스타일을 찾으세요!")]), _c('v-btn', {
    staticClass: "rounded-xs mt-18px mt-md-24px",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/ideas/bathroom/trends/2024"
    }
  }, [_vm._v(" 2024년 욕실 트렌드 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }