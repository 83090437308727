var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": `${_vm.path}/visual.webp`,
      "breadcrumbs": _vm.breadcrumbs,
      "title": "A luxury bathroom for your home"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("욕실을 변형하여 우아하고 고급스러운 휴식처로 만들어 절대적인 편안함을 보장하세요. 이러한 모습을 구현할 수 있도록 고품질 욕실 용품과 절묘한 소재를 결합한 다양한 디자인 아이디어를 모았습니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_vm._l(_vm.navigation, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('router-link', {
      staticClass: "txt txt--sm d-flex align-center",
      attrs: {
        "to": `#${index + 1}`
      }
    }, [_c('v-img', {
      staticClass: "mr-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-down.svg"
      }
    }), _vm._v(" " + _vm._s(item) + " ")], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('dot-list', [_vm._l(_vm.overview, function (item) {
    return [_c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1)], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "product-section-title"
  }, [_c('span', {
    staticClass: "tit font-weight-regular font-tertiary white"
  }, [_vm._v("호화롭고 세련된 욕실 - 욕실 계획을 위한 팁")])])])], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm txt--dark text-center"
  }, [_vm._v("Villeroy & Boch의 세련된 디자인에서 영감을 받아 매우 실용적인 고급스러운 욕실을 만들어 보세요. 그들은 아늑하고 편안한 분위기를 조성하는 동시에 공간을 최대한 활용하는 방법을 알려드릴 것입니다. 독립형 욕조, 우아한 거울 캐비닛, 맞춤형 조명 컨셉과 같은 혁신적인 제품은 욕실 디자인을 한 단계 업그레이드해 줄 것입니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "py-0",
    attrs: {
      "bg": "right"
    }
  }, [_c('v-row', {
    staticClass: "row--lg my-0",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "lg": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/antao-bath-tub-in-bathroom-with-large-window.jpg.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("미니멀하고 도시적인 디자인과 매끈한 라인")]), _c('p', {
    staticClass: "txt txt--sm mb-18px mb-md-24px"
  }, [_vm._v(" Less is more: 고품질 인테리어 디자인은 깔끔한 라인과 유선형 모양으로 미니멀리스트적인 룩에 초점을 맞춥니다. 이러한 룩은 현대적이고 고급스러운 욕실에 이상적이며, 집에 구조화된 우아함과 시대를 초월한 아름다움을 가져다 줄 것입니다. 이는 질서와 공간의 느낌을 만들어내며, 현대적인 인테리어 디자인 스타일에 완벽합니다. 예술적이고 기능적인 요소, 숨겨진 수납 공간, 눈에 띄지 않는 움푹 들어간 선반을 선택하세요. 이러한 터치는 차분하고 고급스러운 분위기의 현대적이고 우아한 욕실을 만들어냅니다. ")])])], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("럭셔리 욕실: 재료 선택")]), _c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("목재, 대리석 등 고품질 소재")]), _c('p', {
    staticClass: "txt txt--sm txt--dark text-center"
  }, [_vm._v("나무, 화강암, 대리석과 같은 신중하게 선택된 천연 소재는 욕실에 편안한 효과를 줍니다. 미적 매력, 내구성, 관리 용이성으로 완벽한 선택입니다.")])])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey",
    attrs: {
      "id": "3"
    }
  }, [_c('v-container', [_c('h3', {
    staticClass: "tit mb-12px mb-md-16px text-center"
  }, [_vm._v("어두운 색조와 악센트를 사용한 우아한 색상 구성")]), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "order-md": "2",
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "position-relative",
    attrs: {
      "max-width": "700",
      "color": "transparent"
    }
  }, [_c('swiper', _vm._b({
    staticClass: "swiper"
  }, 'swiper', {
    options: _vm.options
  }, false), [_vm._l(_vm.items, function (item, index) {
    return [_c('swiper-slide', {
      key: index
    }, [_c('img', {
      staticClass: "d-block",
      attrs: {
        "src": `${_vm.path}/${item}`,
        "alt": ""
      }
    })])];
  })], 2), _c('div', {
    staticClass: "swiper-pagination swiper-pagination--center"
  })], 1)], 1), _c('v-col', {
    attrs: {
      "order-md": "1",
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button--prev",
    attrs: {
      "min-width": "64",
      "min-height": "64",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-left.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "order": "3",
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button--next",
    attrs: {
      "min-width": "64",
      "min-height": "64",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-right.svg"
    }
  })], 1)], 1)], 1), _c('v-sheet', {
    staticClass: "mt-40px mt-md-60px mx-auto",
    attrs: {
      "color": "transparent",
      "max-width": "570"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm text-center"
  }, [_vm._v("진한 검정, 네이비 블루 또는 포레스트 그린과 같은 어두운 색조는 깊이와 세련미를 전달하는 반면, 신중하게 선택된 색상, 소재 및 욕실 수도꼭지는 전반적인 조화를 더합니다. 고급스러운 욕실 가구와 아름답게 조화를 이루고 개인적인 취향을 반영하는 우아한 욕실 장식으로 분위기를 마무리하세요. 이렇게 하면 현대적인 우아함과 나만의 개성이 융합된 고급스러운 욕실이 만들어집니다.")])])], 1)], 1), _c('page-section', {
    attrs: {
      "id": "2"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/antao-bathroom-black-toilet-washbasin-furniture.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실에서 최고의 편의성을 위한 스마트 기술")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v(" Villeroy & Boch의 스마트 기술로 완전히 새로운 차원의 럭셔리와 편안함을 경험하세요. 조명이 있는 LED 조명 욕조와 욕실 가구는 실용적인 이점을 제공하는 동시에 매우 세련된 추가 기능이 될 것입니다. 통합 조명과 터치스크린 기능이 있는 스마트 거울은 세련미와 실용성을 결합합니다. ViClean 샤워 변기 시리즈는 자동 세척 및 사용자 정의 설정으로 향상된 편안함을 제공합니다. "), _c('br'), _vm._v(" 빌레로이앤보흐 욕실의 모든 디테일은 현대 기술, 고급스러운 디자인, 지속 가능한 기능성이 완벽하게 결합되어 일상 생활을 더욱 풍요롭게 만들어줍니다. ")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('h3', {
    staticClass: "tit font-tertiary mb-40px mb-md-56px"
  }, [_vm._v("욕실을 위한 동양적 디자인 아이디어")]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.ideas, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "lg": "4"
      }
    }, [_c('v-card', {
      staticClass: "mx-auto",
      attrs: {
        "tile": "",
        "flat": "",
        "to": item.link,
        "max-width": "400"
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": `${_vm.path}/${item.image}`
      }
    }), _c('div', {
      staticClass: "tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), item.link ? _c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v("자세히 알아보기")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1) : _vm._e()], 1)], 1)];
  })], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }