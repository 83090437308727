<template>
    <client-page>
        <shop-product-visual wide :image="`${path}/visual.webp`" :breadcrumbs="breadcrumbs" title="A luxury bathroom for your home" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm txt--dark">욕실을 변형하여 우아하고 고급스러운 휴식처로 만들어 절대적인 편안함을 보장하세요. 이러한 모습을 구현할 수 있도록 고품질 욕실 용품과 절묘한 소재를 결합한 다양한 디자인 아이디어를 모았습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <template v-for="(item, index) in navigation">
                                <v-col cols="12" :key="index">
                                    <router-link :to="`#${index + 1}`" class="txt txt--sm d-flex align-center">
                                        <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                        {{ item }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <dot-list>
                                <template v-for="item in overview">
                                    <li :key="item">
                                        {{ item }}
                                    </li>
                                </template>
                            </dot-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="product-section-title">
                    <span class="tit font-weight-regular font-tertiary white">호화롭고 세련된 욕실 - 욕실 계획을 위한 팁</span>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm txt--dark text-center">Villeroy &amp; Boch의 세련된 디자인에서 영감을 받아 매우 실용적인 고급스러운 욕실을 만들어 보세요. 그들은 아늑하고 편안한 분위기를 조성하는 동시에 공간을 최대한 활용하는 방법을 알려드릴 것입니다. 독립형 욕조, 우아한 거울 캐비닛, 맞춤형 조명 컨셉과 같은 혁신적인 제품은 욕실 디자인을 한 단계 업그레이드해 줄 것입니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="right" class="py-0">
            <v-row align="center" class="row--lg my-0">
                <v-col lg="7" class="py-0">
                    <v-img :src="`${path}/antao-bath-tub-in-bathroom-with-large-window.jpg.webp`"></v-img>
                </v-col>
                <v-col lg="3">
                    <div class="tit mb-12px mb-md-16px">미니멀하고 도시적인 디자인과 매끈한 라인</div>
                    <p class="txt txt--sm mb-18px mb-md-24px">
                        Less is more: 고품질 인테리어 디자인은 깔끔한 라인과 유선형 모양으로 미니멀리스트적인 룩에 초점을 맞춥니다. 이러한 룩은 현대적이고 고급스러운 욕실에 이상적이며, 집에 구조화된 우아함과 시대를 초월한 아름다움을 가져다 줄 것입니다. 이는 질서와 공간의 느낌을 만들어내며, 현대적인 인테리어 디자인 스타일에 완벽합니다. 예술적이고 기능적인 요소, 숨겨진 수납 공간, 눈에 띄지 않는 움푹 들어간 선반을 선택하세요. 이러한 터치는 차분하고 고급스러운 분위기의 현대적이고 우아한 욕실을
                        만들어냅니다.
                    </p>
                </v-col>
            </v-row>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <div class="text-center">
                            <div class="txt txt--xs">럭셔리 욕실: 재료 선택</div>
                            <h3 class="tit mb-12px mb-md-16px">목재, 대리석 등 고품질 소재</h3>
                            <p class="txt txt--sm txt--dark text-center">나무, 화강암, 대리석과 같은 신중하게 선택된 천연 소재는 욕실에 편안한 효과를 줍니다. 미적 매력, 내구성, 관리 용이성으로 완벽한 선택입니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="3" class="page-section--grey">
            <v-container>
                <h3 class="tit mb-12px mb-md-16px text-center">어두운 색조와 악센트를 사용한 우아한 색상 구성</h3>
                <v-row justify="center" align="center" class="row--lg">
                    <v-col order-md="2" cols="12" md="auto">
                        <v-sheet max-width="700" color="transparent" class="position-relative">
                            <swiper class="swiper" v-bind="{ options }">
                                <template v-for="(item, index) in items">
                                    <swiper-slide :key="index">
                                        <img :src="`${path}/${item}`" alt="" class="d-block" />
                                    </swiper-slide>
                                </template>
                            </swiper>
                            <div class="swiper-pagination swiper-pagination--center" />
                        </v-sheet>
                    </v-col>
                    <v-col order-md="1" cols="auto">
                        <v-btn min-width="64" min-height="64" icon outlined class="swiper-button--prev">
                            <v-img max-width="24" src="/images/icon/icon-swiper-left.svg" />
                        </v-btn>
                    </v-col>
                    <v-col order="3" cols="auto">
                        <v-btn min-width="64" min-height="64" icon outlined class="swiper-button--next">
                            <v-img max-width="24" src="/images/icon/icon-swiper-right.svg" />
                        </v-btn>
                    </v-col>
                </v-row>
                <v-sheet color="transparent" max-width="570" class="mt-40px mt-md-60px mx-auto">
                    <p class="txt txt--sm text-center">진한 검정, 네이비 블루 또는 포레스트 그린과 같은 어두운 색조는 깊이와 세련미를 전달하는 반면, 신중하게 선택된 색상, 소재 및 욕실 수도꼭지는 전반적인 조화를 더합니다. 고급스러운 욕실 가구와 아름답게 조화를 이루고 개인적인 취향을 반영하는 우아한 욕실 장식으로 분위기를 마무리하세요. 이렇게 하면 현대적인 우아함과 나만의 개성이 융합된 고급스러운 욕실이 만들어집니다.</p>
                </v-sheet>
            </v-container>
        </page-section>

        <page-section id="2">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/antao-bathroom-black-toilet-washbasin-furniture.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">욕실에서 최고의 편의성을 위한 스마트 기술</div>
                        <div class="txt txt--sm txt--dark">
                            Villeroy & Boch의 스마트 기술로 완전히 새로운 차원의 럭셔리와 편안함을 경험하세요. 조명이 있는 LED 조명 욕조와 욕실 가구는 실용적인 이점을 제공하는 동시에 매우 세련된 추가 기능이 될 것입니다. 통합 조명과 터치스크린 기능이 있는 스마트 거울은 세련미와 실용성을 결합합니다. ViClean 샤워 변기 시리즈는 자동 세척 및 사용자 정의 설정으로 향상된 편안함을 제공합니다. <br />
                            빌레로이앤보흐 욕실의 모든 디테일은 현대 기술, 고급스러운 디자인, 지속 가능한 기능성이 완벽하게 결합되어 일상 생활을 더욱 풍요롭게 만들어줍니다.
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <h3 class="tit font-tertiary mb-40px mb-md-56px">욕실을 위한 동양적 디자인 아이디어</h3>
                <v-row class="row--lg">
                    <template v-for="(item, index) in ideas">
                        <v-col cols="6" lg="4" :key="index">
                            <v-card tile flat :to="item.link" max-width="400" class="mx-auto">
                                <v-img :src="`${path}/${item.image}`" class="mb-12px mb-md-16px" />
                                <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm grey--text text--darken-3">
                                    {{ item.text }}
                                </p>
                                <div v-if="item.link" class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">자세히 알아보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import SectionSlide from "@/components/client/dumb/section-slide.vue";
import DotList from "@/components/client/ideas/dot-list.vue";
import ThreeImages from "@/components/client/ideas/three-images.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        SectionSlide,
        DotList,
        ThreeImages,
    },
    data: () => ({
        options: {
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            loop: true,
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        },
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 아이디어 및 트렌드",
                href: "/ideas/bathroom/trends",
            },
        ],
        navigation: [
            "고급스러운 욕실을 위한 이상적인 스타일",
            "욕실에 이 색상 트렌드를 활용하세요",
            "2024년의 다른 욕실 트렌드를 확인하세요",
            //
        ],
        overview: [
            "대리석과 목재와 같은 세련된 소재를 결합하여 고급스러운 욕실에 독특하고 우아한 모습을 연출하세요.",
            "기능성과 고급스러운 디자인이 결합된 최첨단 욕실 설비를 선택하세요.",
            "호화로운 욕실용품으로 개성 있는 스타일을 표현해 보세요.",
            //
        ],
        items: [
            "collaro-grey-bathroom-toilet-washbasin-furniture.webp",
            "artis-washbasin-green-in-green-bathroom.webp",
            "squaro-bathtub-with-black-artis-basin.webp",
            //
        ],
        ideas: [
            {
                image: "infinity-showers-shower-system-matt-black-with-squaro-infinity-shower-tray.webp",
                title: "럭셔리한 샤워 경험",
                text: "활력을 되찾는 샤워로 하루를 시작하세요. Villeroy & Boch의 바닥 레벨 워크인 샤워는 욕실에 완벽하게 어울리며 공간감과 편안함을 높여줍니다.",
            },
            {
                image: "theano-curved-edition-bathtub-white-in-wellness-bathroom.webp",
                title: "욕실 트렌드 2024",
                text: "2024년 최신 욕실 트렌드를 최신 상태로 유지하세요. 기술적 하이라이트 외에도 스칸디나비아 스타일 욕실부터 콘크리트 룩의 욕실까지 영감을 주는 디자인 트렌드도 찾아볼 수 있습니다.",
                link: "/ideas/bathroom/trends/2024",
            },
            {
                image: "antao-bath-tub-white-in-bathroom-with-wall-of-glass.webp",
                title: "고급 욕조로 웰빙을 즐기세요",
                text: "빌레로이 앤 보흐의 럭셔리 독립형 욕조는 조각품 같은 특징이며 휴식을 취하기에 완벽한 장소입니다. 웰빙의 세계로 들어가 일상의 걱정을 뒤로 하세요.",
                link: "/shop/products?category=bathtub",
            },
        ],
        path: "/images/ideas/bathroom/trends/styles/luxurious",
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.swiper-pagination--center {
    left: 50%;
    bottom: -12px;
    transform: translate(-50%, 100%);
    justify-content: center;
    :deep(.swiper-pagination-bullet) {
        border: none !important;
        background-color: #e2e8f0 !important;
        &-active {
            background-color: #007aff !important;
        }
    }
}
</style>