<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/visual.jpg" :breadcrumbs="breadcrumbs" title="Bathroom Overview" />
        <page-section class="pt-40px pt-md-80px">
            <v-container class="mt-50px mt-md-100px">
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <h3 class="tit mb-12px mb-md-16px">욕실의 모든 것</h3>
                        <p class="txt txt--sm">오래된 욕실을 새롭게 개조하거나 새로운 욕실을 설치하고 싶지만 아이디어가 떠오르지 않으신가요? 그렇다면 지금이 바로 시작하기 좋은 때입니다. 욕실을 현대적이고 편안하게 만드는 방법과 필요한 요소를 알려드립니다. Villeroy &amp; Boch의 디자인, 혁신, 최신 트렌드, 그리고 실용적인 팁에서 영감을 얻어보세요. 당신의 욕실을 웰빙을 위한 진정한 오아시스로 변신시켜보세요!</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 py-40px py-md-80px my-20px my-md-40px">
            <v-container>
                <v-row>
                    <v-col cols="12" md="auto">
                        <router-link to="#1" class="txt txt--sm d-flex align-center">
                            모든 욕실 주제
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <router-link to="#2" class="txt txt--sm d-flex align-center">
                            신상품 및 인기 컬렉션
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="auto">
                        <router-link to="#3" class="txt txt--sm d-flex align-center">
                            욕실 제품 살펴보기
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="1">
            <v-container>
                <div class="tit-wrap tit-wrap--lg">
                    <h2 class="tit text-center">욕실에 관한 흥미로운 주제를 찾아보세요</h2>
                </div>
                <v-row class="row--lg">
                    <template v-for="item in subjects">
                        <v-col :key="item" cols="6" md="3">
                            <v-card tile flat :to="item.link">
                                <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm grey--text text--darken-3">
                                    {{ item.text }}
                                </p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">자세히 알아보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--lg">
                    <h2 class="tit">당신을 위해 선정된 주요 주제들</h2>
                </div>
                <v-row class="row--lg">
                    <template v-for="item in themes">
                        <v-col cols="12" lg="6" :key="item">
                            <v-card tile flat :to="item.link">
                                <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                <div class="tit tit--sm grey--text text--darken-3 mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm grey--text text--darken-3">
                                    {{ item.text }}
                                </p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">자세히 알아보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>

        <overview-revolution id="2" />

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--lg text-center">
                    <h2 class="tit">가장 인기 있는 욕실 컬렉션</h2>
                </div>
                <v-row class="row--lg">
                    <template v-for="item in collections">
                        <v-col cols="12" lg="4" :key="item">
                            <v-card tile flat :to="item.link" max-width="400" class="mx-auto">
                                <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm grey--text text--darken-3">
                                    {{ item.text }}
                                </p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">자세히 알아보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>

        <overview-categories id="3" />

        <overview-popular-slides />

        <page-section>
            <v-container>
                <div class="product-section-title">
                    <span class="tit font-weight-regular font-tertiary white">유용한 도구 및 서비스</span>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--xxl">
                    <v-col cols="12" md="7">
                        <v-img src="/images/ideas/bathroom/service-1.webp" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="txt txt--xs mb-20px grey--text font-weight-light">전문적인 조언</div>
                        <div class="tit mb-12px mb-md-16px">욕실 플래너 살펴보기</div>
                        <p class="txt txt--sm mb-18px mb-md-24px">욕실을 계획할 때는 실용적인 온라인 욕실 플래너를 활용해보세요. 초기 디자인 아이디어를 정리한 후, 욕실 플래너를 통해 아이디어를 3D 형식으로 시각화하고 구체화할 수 있습니다.</p>
                        <v-btn large color="#fff" elevation="2" class="rounded-xs" href="/ideas/bathroom/planner">
                            욕실 플래너 보러 가기
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <overview-exploration />
    </client-page>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import OverviewRevolution from "@/components/client/ideas/bathroom/overview/overview-revolution.vue";
import OverviewCategories from "@/components/client/ideas/bathroom/overview/overview-categories.vue";
import OverviewPopularSlides from "@/components/client/ideas/bathroom/overview/overview-popular-slides.vue";
import OverviewExploration from "@/components/client/ideas/bathroom/overview/overview-exploration.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        ClientPage,
        PageSection,
        ShopProductVisual,
        OverviewRevolution,
        OverviewCategories,
        OverviewPopularSlides,
        OverviewExploration,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 개요",
                href: "/ideas/bathroom/overview",
            },
        ],
        subjects: [
            {
                title: "욕실 계획하기",
                text: "완벽한 욕실 계획을 위한 스마트한 팁과 서비스.",
                image: "/images/ideas/bathroom/subject-1.webp",
                link: "/ideas/bathroom/overview/planning",
            },
            {
                title: "모든 요구에 맞는 공간 솔루션",
                text: "소형, 대형, 각진형, 무장애형 - 모든 유형의 욕실을 위한 솔루션입니다.",
                image: "/images/ideas/bathroom/subject-2.webp",
                link: "/ideas/bathroom/overview/solution",
            },
            {
                title: "욕실 아이디어 및 트렌드",
                text: "욕실을 위한 최신 트렌드, 스타일, 영감을 주는 아이디어.",
                image: "/images/ideas/bathroom/subject-3.webp",
                link: "/ideas/bathroom/trends",
            },
            {
                title: "욕실의 작은 수리",
                text: "신속한 수리 및 유지관리를 위한 실용적인 팁.",
                image: "/images/ideas/bathroom/subject-4.webp",
                link: "/ideas/bathroom/overview/repairs",
            },
            {
                title: "욕실 소재",
                text: "고급스러움을 느껴보세요: 우아한 소재와 마감.",
                image: "/images/ideas/bathroom/subject-5.webp",
                link: "/ideas/bathroom/overview/material",
            },
            {
                title: "욕실을 개조하다",
                text: "성공적인 욕실 리모델링을 위해 알아야 할 모든 것.",
                image: "/images/ideas/bathroom/subject-6.webp",
                link: "/ideas/bathroom/renovation",
            },
            {
                title: "욕실 기능",
                text: "기능성 욕실 - 꿈의 욕실을 위한 혁신적인 기능.",
                image: "/images/ideas/bathroom/subject-7.webp",
                link: "/ideas/bathroom/overview/features",
            },
            {
                title: "욕실을 직접 설치하세요",
                text: "독립형 욕실 가구에 대한 창의적인 아이디어.",
                image: "/images/ideas/bathroom/subject-8.webp",
                link: "/ideas/bathroom/overview/installing",
            },
            {
                title: "깨끗한 욕실",
                text: "반짝반짝 깨끗한 욕실을 위한 효율적인 방법.",
                image: "/images/ideas/bathroom/subject-9.webp",
                link: "/ideas/bathroom/overview/cleaning",
            },
        ],
        themes: [
            {
                title: "욕실의 색상",
                text: "약간의 색상 이론, 영감을 주는 아이디어, Gesa Hansen의 디자이너 팁이 욕실에 딱 맞는 색상을 찾는 데 도움이 될 것입니다.",
                image: "/images/ideas/bathroom/theme-1.webp",
                link: "/ideas/bathroom/overview/colours",
            },
            {
                title: "수납공간을 만들어보세요",
                text: "깔끔한 욕실에 대한 영감 - 영리한 수납 솔루션으로 공간을 최적으로 활용해보세요.",
                image: "/images/ideas/bathroom/theme-2.webp",
                link: "/ideas/bathroom/overview/storage",
            },
            {
                title: "욕실 조명을 적절하게 켜세요",
                text: "세련된 욕실 조명은 일상적인 관리 루틴에 편안한 분위기를 조성합니다.",
                image: "/images/ideas/bathroom/theme-3.webp",
                link: "/ideas/bathroom/overview/lighting",
            },
            {
                title: "욕실 디자인을 위한 팁",
                text: "몇가지 팁 만으로 욕실을 세련된 웰빙 오아시스로 바꿀 수 있습니다.",
                image: "/images/ideas/bathroom/theme-4.webp",
                link: "/ideas/bathroom/overview/installing",
            },
        ],
        collections: [
            {
                title: "Subway 3.0",
                text: "Subway 3.0은 트렌드를 뛰어넘는 시대를 초월한 아름다운 디자인을 제공합니다!",
                image: "/images/ideas/bathroom/collection-1.webp",
                link: "/b/subway-30",
            },
            {
                title: "Avento",
                text: "현대적이고 가벼운 디자인 - 개인의 라이프스타일에 딱 맞는 욕실",
                image: "/images/ideas/bathroom/collection-2.webp",
                link: "/b/avento",
            },
            {
                title: "Collaro",
                text: "캐릭터와 스타일의 만남! 스타일리쉬하고 섬세하면서도 집처럼 편안한 독특한 디자인 엣지.",
                image: "/images/ideas/bathroom/collection-3.webp",
                link: "/b/collaro/",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>