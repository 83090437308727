var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": "/images/ideas/bathroom/colours/visual.webp",
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Bathroom trend: colours"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('p', [_vm._v("욕실에서 상쾌한 파란색으로 하루를 시작해 보세요. 아니면 조용한 녹색 숲 속에서 저녁을 마무리해 보세요. 적절한 컬러 컨셉으로 욕실을 오감으로 느끼고 즐길 수 있습니다.")]), _c('br'), _c('p', [_vm._v("디자이너 Gesa Hansen의 약간의 색상 이론, 영감 및 팁을 통해 올바른 색상과 재료를 선택하는 것은 매우 쉽습니다.")]), _c('br'), _c('p', [_vm._v("시도해 보세요!")])])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("계획은 영감에서 시작됩니다")]), _c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실을 위한 색상")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("색상을 사용하여 욕실을 더욱 편안하고 개성있게 만드세요. 색상 사용 방법에 대한 아이디어, 제안, 팁을 제공하는 색상의 세계로 여러분을 안내합니다. 발견해보세요: 섬세한 로제, 강렬한 레드, 따뜻한 옐로우, 내추럴 그린, 차분한 블루, 흙빛 그레이지.")])])], 1)], 1)], 1), _c('page-section', [_c('trends-slide')], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg text-center"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("ARTIS - 가장 아름다운 색상")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" Artis의 조리대 세면대는 다양한 색상과 다양한 모양으로 인상적입니다. "), _c('br'), _vm._v(" 10가지 색상과 4가지 모양 중에서 모델을 선택하세요 . 세면대의 섬세한 디자인으로 더욱 아름답게 강조되는 욕실에 강렬하거나 은은한 액센트를 추가하세요. ")])])])], 1), _c('artis-slide'), _c('div', {
    staticClass: "tit-wrap"
  }), _c('p', {
    staticClass: "txt txt--sm text-center"
  }, [_vm._v("Artis의 다양한 색상으로 개별적인 욕실 디자인이 가능합니다.")])], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "right"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/colours/gesa-hansen-sitting-on-pink-chair.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('p', [_vm._v(" \"색상은 분위기, 크기, "), _c('br'), _vm._v(" 방의 정의 등 거의 모든 것을 "), _c('br'), _vm._v(" 바꿀 수 있습니다.\" "), _c('br')]), _c('p', [_vm._v("디자이너 - 게사 한센")])])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("색 구성표")]), _c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("색상을 결합하는 네 가지 쉬운 방법")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("하나, 둘, 세 가지 색상을 조합하더라도 조화로운 선택이 중요합니다. 네 가지 색상 구성표를 사용하면 항상 조화로운 효과를 얻을 수 있습니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('section-slide', {
    attrs: {
      "items": _vm.items,
      "path": "/images/ideas/bathroom/colours"
    }
  })], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mt-md-90px"
  }, [_vm._v("컬러 컨셉 다운로드")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-card', {
    staticClass: "pa-24px pa-md-32px",
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "txt txt--xs line-height-2"
  }, [_vm._v("2024년")]), _c('h3', {
    staticClass: "tit font-tertiary mb-12px mb-md-16px"
  }, [_vm._v("아티스 컬러 컨셉")]), _c('p', {
    staticClass: "txt txt--xs txt--dark"
  }, [_vm._v("색상환과 색상 컨셉을 다운로드하여 인쇄하거나, 무드 보드를 만들거나, 약간의 영감을 얻으세요.")]), _c('div', {
    staticClass: "txt txt--xs txt--dark mt-12px mt-md-16px"
  }, [_vm._v("PDF 18MB")]), _c('v-btn', {
    staticClass: "pa-0 mt-20px rounded-0 v-size--xx-small",
    attrs: {
      "text": "",
      "href": "/res/download/Colour_concept_booklet_2023_EN.pdf",
      "download": ""
    }
  }, [_c('span', {
    staticClass: "txt txt--xs txt--dark font-weight-medium"
  }, [_vm._v("다운로드 받기")]), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "src": "/images/icon/icon-download.svg"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "txt txt--xs mb-12px mb-md-16px"
  }, [_vm._v("디자인 전문가가 전하는 10가지 팁")]), _c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("Gesa Hansen이 추천하는 훌륭한 컬러 컨셉")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v(" 색상을 올바르게 사용하면 놀라운 효과를 얻을 수 있습니다. "), _c('br'), _vm._v(" 색상은 욕실을 더 크고, 친근하고, 편안하게 보이게 합니다. "), _c('br'), _vm._v(" 아니면 그 반대의 원인이 됩니다. ")]), _c('expansion-panels', {
    attrs: {
      "data": _vm.recommends
    }
  })], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "order-md": "2"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ideas/bathroom/colours/gesa-hansen-stands-in-front-of-a-pink-washbasin.webp"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "order-md": "1"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm"
  }, [_c('p', [_vm._v(" \"색상은 분위기, 크기, "), _c('br'), _vm._v(" 방의 정의 등 거의 모든 것을 "), _c('br'), _vm._v(" 바꿀 수 있습니다.\" "), _c('br')]), _c('p', [_vm._v("디자이너 - 게사 한센")])])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("우리의 컬렉션")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("팁 3에서 Gesa Hansen은 욕실 색상을 세면대부터 시작할 것을 권장합니다. Artis 및 Finion 컬렉션은 다양한 색상을 제공합니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.collections, function (item) {
    return [_c('v-col', {
      key: item.title,
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "elevation": "4"
      }
    }, [_c('v-img', {
      attrs: {
        "src": `/images/ideas/bathroom/colours/${item.image}`
      }
    }), _c('div', {
      staticClass: "pa-24px pa-md-32px"
    }, [_c('div', {
      staticClass: "tit tit--sm mb-12px mb-md-16px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm"
    }, [_vm._v(" " + _vm._s(item.text) + " ")])])], 1)], 1)];
  })], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }