<template>
    <client-page>
        <shop-product-visual wide :image="`${path}/visual.webp`" :breadcrumbs="breadcrumbs" title="Japandi bathroom" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">욕실을 트렌디한 Japandi 스타일로 꾸미고 싶으신가요? 저희가 방법을 보여드리겠습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <template v-for="(item, index) in navigation">
                                <v-col cols="12" :key="index">
                                    <router-link :to="`#${index + 1}`" class="txt txt--sm d-flex align-center">
                                        <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                        {{ item }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <dot-list>
                                <template v-for="item in overview">
                                    <li :key="item">
                                        {{ item }}
                                    </li>
                                </template>
                            </dot-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="1">
            <v-container>
                <v-row justify="center" align="center" class="row--lg text-center">
                    <v-col cols="12" md="8">
                        <h3 class="tit mb-12px mb-md-16px">미니멀리스트 재팬디 스타일의 정수</h3>
                        <p class="txt txt--sm txt--dark">"새로운 Japandi 스타일은 일본과 스칸디나비아 인테리어 디자인의 영향을 결합했습니다. 이름은 "Japan"과 "Scandi"의 융합입니다. 두 스타일이 처음에는 반대되는 것처럼 보일 수 있지만 자세히 살펴보면 많은 공통적인 특징이 드러납니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center" align="center" class="row--lg">
                    <v-col cols="12" md="8">
                        <v-row v-for="(item, index) in checkList" :key="index" class="row--xs">
                            <v-col cols="auto">
                                <v-icon small>mdi-check</v-icon>
                            </v-col>
                            <v-col>
                                <p class="txt txt--sm txt--dark">
                                    {{ item }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container class="text-center">
                <div class="txt txt--xs">조화로운 분위기를 위해</div>
                <h3 class="tit mb-12px mb-md-16px">재팬디룩: 천연소재</h3>
                <p class="txt txt--sm txt--dark">Japandi 인테리어 디자인 스타일은 나무와 같은 천연 소재에 초점을 맞춥니다. 천연석, 리넨, 코튼도 인기 있는 선택입니다.</p>
            </v-container>
        </page-section>

        <page-section bg="right" id="2">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/loop-friends-bathtub-black-in-japanese-style-bathroom.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">Japandi 욕실 장식 선택</div>
                        <div class="txt txt--sm txt--dark">
                            <p>Japandi 스타일 욕실을 디자인하려면 몇 가지 선택된 핵심 품목으로 시작하세요. 크고 우아한 꽃병은 말린 꽃과 함께 진정한 스타일의 특징이 될 것입니다. 진짜 식물은 자연광이 드는 더 큰 욕실을 위한 또 다른 옵션이며 Japandi 욕실에 신선한 느낌을 더할 것입니다. 벽에 있는 예술 작품은 방을 과부하시키지 않으면서도 의미를 전달할 것입니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="left" id="2">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">정통 재팬디 스타일의 욕실 가구를 선택하세요</div>
                        <div class="txt txt--sm txt--dark">
                            <p>Japandi 스타일은 욕실 가구를 허용하지만, 품목을 선택할 때 몇 가지 기준을 고려하는 것이 중요합니다. 모든 욕실 가구는 선형 디자인과 단순한 색상을 가져야 합니다. 실용적인 기능도 중요합니다. Japandi 욕실의 세면대는 항상 기능적이고 스타일리시해야 합니다.</p>
                        </div>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/loop-friends-washbasin-white-with-legato-furniture-and-bonsai.webp`" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="3">
            <v-container class="text-center">
                <h3 class="tit mb-12px mb-md-16px">재팬디 욕실: 편안한 휴식과 이완</h3>
                <p class="txt txt--sm txt--dark">Japandi 인테리어 디자인의 모든 측면은 차분하고 평화로운 분위기를 조성하기 위해 완벽하게 조정되었습니다. 이는 욕실 세라믹에도 적용됩니다.</p>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="10">
                        <v-row justify="center" class="row--xxl">
                            <template v-for="(item, index) in items">
                                <v-col :cols="item.cols" :key="index">
                                    <v-card max-width="776" color="transparent" tile flat :href="item.link" class="mx-auto">
                                        <v-img :src="`${path}/${item.image}`" class="mb-12px mb-md-16px" />
                                        <div class="px-12px px-md-16px">
                                            <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                                {{ item.title }}
                                            </div>
                                            <p class="txt txt--sm grey--text text--darken-3">
                                                {{ item.text }}
                                            </p>
                                            <template v-if="item.link">
                                                <div class="mt-12px mt-md-16px d-flex align-center">
                                                    <span class="txt txt--xs txt--dark font-weight-medium">
                                                        {{ item.button }}
                                                    </span>
                                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                                </div>
                                            </template>
                                        </div>
                                    </v-card>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="4" class="page-section--grey">
            <v-container>
                <v-row justify="center" align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">2024년 주택 욕실 트렌드를 알아보세요</div>
                        <p class="txt txt--sm txt--dark">다양한 스타일의 영향이 결합되어 미니멀리스트 재팬디 스타일 욕실이 매우 특별해졌습니다. 하지만 이 스타일이 모든 욕실에 어울리는 것은 아닙니다. 미니멀리스트적이지 않은 것을 찾고 있다면 욕실 트렌드를 살펴보세요. 산업 스타일에서 스칸디나비아 스타일, 컨트리 스타일까지 선택할 수 있는 룩이 많이 있습니다.</p>
                        <v-btn large color="#fff" elevation="2" class="rounded-xs mt-18px mt-md-24px" to="/ideas/bathroom/trends/2024">
                            2024년을 위한 더 많은 욕실 트렌드
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="5">
                        <v-img :src="`${path}/antao-bath-tub-in-stylish-bathromm.webp`" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import SectionSlide from "@/components/client/dumb/section-slide.vue";
import DotList from "@/components/client/ideas/dot-list.vue";
import ThreeImages from "@/components/client/ideas/three-images.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        SectionSlide,
        DotList,
        ThreeImages,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 아이디어 및 트렌드",
                href: "/ideas/bathroom/trends",
            },
        ],
        navigation: [
            "재팬디 스타일의 특징",
            "재팬디 욕실 장식",
            "Japandi 스타일 욕실에 완벽한 제품",
            "2024년 욕실 트렌드",
            //
        ],
        overview: [
            "재팬디는 일본과 스칸디나비아 스타일을 결합한 스타일입니다.",
            "미니멀리즘과 여유로운 휘게 스타일이 만났습니다.",
            "재팬디 욕실의 주요 특징은 천연 소재와 심플한 색상입니다.",
            "직선은 가구와 도자기에 필수적입니다.",
            //
        ],
        checkList: [
            '일본식 욕실은 단순함과 기능성이 특징입니다. 이는 또한 스칸디나비아 스타일의 특징이기도 합니다. "적을수록 더 좋다"는 것이 지침 원칙입니다.',
            "선형적이고 미니멀한 가구는 스칸디나비아 스타일과 일본식 욕실의 특징입니다.",
            "두 스타일 간의 대조를 결합하여 눈에 띄는 효과를 낼 수도 있습니다. 예를 들어, 스칸디나비아의 아늑함과 일본의 미니멀리즘이 함께하는 hygge 트렌드.",
            //
        ],
        items: [
            {
                title: "완벽한 욕조를 선택하세요",
                text: "직선이 있는 독립형 욕조는 Japandi 욕실에 이상적인 선택입니다. 단순한 모양을 찾으세요. Japandi 스타일에서는 장난기 있는 디테일은 피하는 것이 가장 좋습니다.",
                button: "빌레로이앤보흐 욕조",
                link: "/shop/products?category=bathtub",
                image: "antao-bath-tub-beige-in-modern-bathroom.webp",
                cols: "6",
            },
            {
                title: "재팬디 스타일 욕실을 위한 모던한 샤워기",
                text: "Japandi 욕실에는 샤워도 있습니다. 간단한 수도꼭지와 부속품이 있는 맞춤형 워크인 샤워 공간은 완벽한 선택입니다.",
                // button: "빌레로이앤보흐 샤워기",
                // link: "/",
                image: "memento-2.0-bath-with-shower-japandi.webp",
                cols: "6",
            },
        ],
        path: "/images/ideas/bathroom/trends/styles/japandi",
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>