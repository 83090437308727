var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": `${_vm.path}/visual.webp`,
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Oriental bathroom"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("욕실을 동양풍으로 꾸미고 싶으신가요? 이 룩에 필수적인 요소를 보여드리겠습니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_vm._l(_vm.navigation, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('router-link', {
      staticClass: "txt txt--sm d-flex align-center",
      attrs: {
        "to": `#${index + 1}`
      }
    }, [_c('v-img', {
      staticClass: "mr-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-down.svg"
      }
    }), _vm._v(" " + _vm._s(item) + " ")], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('dot-list', [_vm._l(_vm.overview, function (item) {
    return [_c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "right",
      "id": "1"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/loop-and-friends-withe-washbasin-mediterranean-style-pink-wall.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('p', {
    staticClass: "txt txt--xs mb-12px mb-md-16px"
  }, [_vm._v("완벽한 동양식 욕실을 디자인하세요")]), _c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("동양식 욕실 디자인: 색상과 패턴의 조화")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("욕실에 동양적 분위기를 연출하려면 실용적이고 고품질의 가구와 기분 좋은 향, 따뜻한 색상, 장난기 있는 디테일과 같은 고급스럽고 관능적인 터치를 결합하세요. 선택할 수 있는 디자인 아이디어가 많이 있습니다.")])])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "left",
      "id": "2"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("동양식 욕실을 위한 벽 디자인 팁:")]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("동양식 타일과 같은 특징을 사용하여 욕실에 악센트를 더하세요. 복잡한 모자이크와 따뜻한 색상의 정교하게 장식된 장식용 타일은 완벽한 선택입니다. 이러한 타일은 특징적인 벽으로 가장 잘 작동하지만 그렇지 않으면 패턴이 너무 강렬해 보일 수 있습니다. 세면대와 거울 뒤의 타일은 특히 아름다운 모습을 연출합니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/oberon-20-bathtub-with-liberty-tap.webp`
    }
  })], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "3"
    }
  }, [_c('v-container', [_c('h3', {
    staticClass: "tit mb-12px mb-md-16px text-center"
  }, [_vm._v("평온함과 관능미 - 동양적 악센트")]), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "order-md": "2",
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "position-relative",
    attrs: {
      "max-width": "700",
      "color": "transparent"
    }
  }, [_c('swiper', _vm._b({
    staticClass: "swiper"
  }, 'swiper', {
    options: _vm.options
  }, false), [_vm._l(_vm.items, function (item, index) {
    return [_c('swiper-slide', {
      key: index
    }, [_c('img', {
      staticClass: "d-block",
      attrs: {
        "src": `${_vm.path}/${item}`,
        "alt": ""
      }
    })])];
  })], 2), _c('div', {
    staticClass: "swiper-pagination swiper-pagination--center"
  })], 1)], 1), _c('v-col', {
    attrs: {
      "order-md": "1",
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button--prev",
    attrs: {
      "min-width": "64",
      "min-height": "64",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-left.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "order": "3",
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "swiper-button--next",
    attrs: {
      "min-width": "64",
      "min-height": "64",
      "icon": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-swiper-right.svg"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "mt-40px mt-md-60px mb-12px mb-md-16px txt txt--sm txt--dark text-center font-weight-medium"
  }, [_vm._v("동양적인 분위기를 연출하는 욕실 장식")]), _c('v-sheet', {
    staticClass: "mx-auto",
    attrs: {
      "color": "transparent",
      "max-width": "570"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm text-center"
  }, [_vm._v("특욕실 장식에 동양적인 느낌을 더할 수도 있습니다. 아늑한 분위기를 위해 베이지색이나 진한 빨간색 욕실 직물을 선택하세요. 마라케시 랜턴의 따뜻한 빛은 편안한 목욕을 위한 장면을 연출합니다. 촛불과 동양 조각상 또는 그림도 욕실에 잘 어울립니다.")])])], 1)], 1), _c('page-section', [_c('v-container', [_c('h3', {
    staticClass: "tit font-tertiary mb-40px mb-md-56px"
  }, [_vm._v("욕실을 위한 동양적 디자인 아이디어")]), _c('v-row', {
    staticClass: "row--lg"
  }, [_vm._l(_vm.ideas, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "6",
        "lg": "4"
      }
    }, [_c('v-card', {
      staticClass: "mx-auto",
      attrs: {
        "tile": "",
        "flat": "",
        "to": item.link,
        "max-width": "400"
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": `${_vm.path}/${item.image}`
      }
    }), _c('div', {
      staticClass: "tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), _c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v("자세히 알아보기")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)], 1)], 1)];
  })], 2)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey",
    attrs: {
      "id": "4"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/antao-bath-tub-in-stylish-bathromm.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실에 가장 어울리는 디자인 스타일을 찾아보세요")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("동양적 룩은 모든 사람에게 적합한 스타일은 아닙니다. 하지만 선택할 수 있는 더 많은 트렌드 인테리어 디자인 아이디어가 있습니다. 지금 바로 당신에게 완벽한 2024년 욕실 트렌드를 찾아보세요. 몇 가지 간단한 변화만 있으면 이러한 트렌드를 적용하고 욕실에 아름다운 룩을 연출할 수 있습니다.")])]), _c('v-btn', {
    staticClass: "rounded-xs mt-18px mt-md-24px",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/ideas/bathroom/trends/2024"
    }
  }, [_vm._v(" 2024년 욕실 트렌드 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }