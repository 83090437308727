<template>
    <client-page>
        <shop-product-visual wide :image="`${path}/visual.webp`" :breadcrumbs="breadcrumbs" title="Cosy bathrooms" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm txt--dark">아늑한 웰빙 욕실은 트렌드에 딱 맞습니다. 욕실을 환영하는 휴식처로 바꾸고 싶다면, 몇 가지 팁을 알려드리겠습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <template v-for="(item, index) in navigation">
                                <v-col cols="12" :key="index">
                                    <router-link :to="`#${index + 1}`" class="txt txt--sm d-flex align-center">
                                        <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                        {{ item }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <dot-list>
                                <template v-for="item in overview">
                                    <li :key="item">
                                        {{ item }}
                                    </li>
                                </template>
                            </dot-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="1">
            <v-container class="text-center">
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <h3 class="tit mb-12px mb-md-16px">아늑한 욕실 디자인 – 팁과 아이디어</h3>
                        <p class="txt txt--sm txt--dark">욕실을 아늑하게 만드는 방법은 다양합니다. 핵심 원칙을 설명하겠습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <v-row v-for="(item, index) in checkList" :key="index" class="row--xs">
                            <v-col cols="auto">
                                <v-icon small>mdi-check</v-icon>
                            </v-col>
                            <v-col>
                                <p class="txt txt--sm txt--dark">
                                    {{ item }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <section-slide :items="tipSlide" :path="path" />
            </v-container>
        </page-section>

        <page-section bg="right">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/artis-washbasin-white-with-concrete-walls.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">작은 욕실을 아늑하게 만드는 방법</div>
                        <div class="txt txt--sm txt--dark">
                            <p>작은 욕실은 쉽게 아늑한 휴식처로 바꿀 수 있습니다. 큰 타일은 작고 아늑한 욕실을 약간 더 크게 보이게 합니다. 또한 조인트가 적기 때문에 더 편안한 효과를 냅니다. 일관된 색상 구성표와 많은 천연 소재는 더 아늑한 느낌을 주는 데 이상적입니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="left" id="2">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">과제: 넓은 욕실을 아늑하게 느끼게 하기</div>
                        <div class="txt txt--sm txt--dark">
                            <p>넓은 욕실에서 아늑한 분위기를 조성하는 것은 어려울 수 있습니다. 한 가지 해결책은 룸 디바이더에 대해 이미 설명한 것처럼 서로 다른 구역을 영리하게 분리하는 것입니다.</p>
                            <p>각 구역에 다른 색상을 사용하여 공간을 시각적으로 구분합니다. 간접 조명 배열도 잘 작동합니다. 이는 개별적으로 제어하여 욕실에서 사용하는 부분만 밝힐 수 있습니다.</p>
                        </div>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/finion-bathtub-white-freestanding-with-washbasins-white-and-mirrors-big-round.webp`" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="right" id="3">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/antao-bathroom-wc-furniture-washbasin-white-and+oak.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">따뜻한 조명으로 편안한 분위기를 연출하세요</div>
                        <div class="txt txt--sm txt--dark">
                            <p>욕실의 적절한 조명은 완벽한 기분 좋은 분위기를 조성하는 데 필수적입니다. 물론 일광이 가장 좋은 선택이지만 모든 욕실에 창문이 있는 것은 아닙니다. 여기서 따뜻한 조명이 등장합니다. 간접 조명원은 분위기 있는 악센트를 더합니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="4">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">스칸디나비아 욕실 - 북쪽의 아늑한 스타일</div>
                        <div class="txt txt--sm txt--dark">
                            <p>우리는 모두 "휘게"라는 단어에 익숙하지만, 욕실에도 아늑한 스칸디나비아 룩이 있다는 걸 알고 계셨나요? 이 인테리어 디자인 스타일을 자세히 알아보세요!</p>
                        </div>
                        <v-btn large color="#fff" elevation="2" class="rounded-xs mt-18px mt-md-24px" to="/ideas/bathroom/trends/styles/scandi">
                            지금 스칸디나비아 욕실 스타일을 만나보세요
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/antao-bath-tub-in-bathroom-with-large-window.jpg.webp`" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import SectionSlide from "@/components/client/dumb/section-slide.vue";
import DotList from "@/components/client/ideas/dot-list.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        SectionSlide,
        DotList,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 아이디어 및 트렌드",
                href: "/ideas/bathroom/trends",
            },
        ],
        navigation: [
            "아늑한 욕실을 위한 팁",
            "넓은 욕실에 어울리는 아늑한 디자인",
            "올바른 조명 선택",
            "스칸디나비아 욕실 트렌드를 알아보세요",
            //
        ],
        overview: [
            "넓은 욕실에서는 배치가 중요합니다.",
            "따뜻한 색상은 차분하고 편안한 분위기를 조성합니다.",
            "따뜻하고 차분한 조명이 완벽한 악센트를 더해줍니다.",
            //
        ],
        tipSlide: [
            {
                title: "똑똑한 객실 레이아웃",
                text: "독립형 욕조가 있을 만큼 운이 좋다면, 이것을 욕실의 중심으로 만들 수 있습니다. 그러나 아늑한 스타일은 더 단순한 욕실에도 잘 어울립니다. 스크린과 커튼을 사용하여 욕실의 여러 구역을 구분하고 나중에 쉽게 통합할 수도 있습니다.",
                image: "theano-freestanding-bathtub-beige.webp",
            },
            {
                title: "욕실 디자인에 따뜻한 색상을 사용하세요",
                text: "따뜻한 색상은 아늑한 욕실에 이상적입니다. 그러나 반드시 어두울 필요는 없습니다. 오렌지, 노랑, 밝은 빨간색 음영은 욕실에 좋은 선택이며 아름다운 악센트를 더합니다. 갈색이나 베이지와 같은 부드러운 자연스러운 색조를 기본 색상으로 사용할 수 있습니다. 세이지나 라벤더는 아늑한 욕실에 잘 어울리는 다른 자연스러운 색상입니다.",
                image: "theano-bathtub-rust-with-artis-washbasin-and-viclean.webp",
            },
            {
                title: "영감: 욕실 가구",
                text: "일부 욕실 가구는 실용적인 기능을 가지고 있습니다. 예를 들어, 세면대는 모든 종류의 품목을 정리하여 보관합니다. 그리고 아늑한 욕실에서는 정리가 중요합니다. 천연 소재의 욕실 가구는 항상 안전한 선택이 될 것입니다.",
                image: "antao-minimalist-console-bathroom.jpg.webp",
            },
            {
                title: "나무 악센트",
                text: "나무는 아늑한 욕실에서 가장 인기 있는 소재 중 하나입니다. 물론 색상도 역할을 합니다. 따뜻하고 어두운 나무를 선택하여 악센트를 더하세요. 다양한 옵션이 있습니다. 예를 들어, 나무처럼 보이는 가구나 바닥의 일부에 나무 표면을 두어 서로 다른 공간을 시각적으로 구분합니다.",
                image: "loop-and-friends-surface-mounted-washbasin-white-wood-plants.webp",
            },
        ],
        path: "/images/ideas/bathroom/trends/styles/hygge",
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>