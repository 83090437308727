<template>
    <main-section>
        <v-container>
            <div class="tit-wrap text-center">
                <h2 class="tit">한눈에 살펴보는 특별한 욕실 컬렉션</h2>
            </div>
            <v-row class="row--lg">
                <template v-for="(item, index) in items">
                    <v-col :key="index" cols="6" lg="3">
                        <v-hover v-slot="{ hover }">
                            <v-card tile flat :to="item.link" class="collection-card">
                                <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                <div class="tit tit--xs mb-6px mb-md-8px font-tertiary">
                                    {{ item.title }}
                                </div>
                                <div class="txt tit--xs mb-12px mb-md-16px">
                                    {{ item.text }}
                                </div>
                                <div class="collection-card__button d-flex align-centerv">
                                    <span class="txt txt--xs txt--dark font-weight-medium"> {{ item.title }} 보러 가기 </span>
                                    <v-img max-width="24" :src="hover ? '/images/icon/icon-arrow-right-active.svg' : '/images/icon/icon-arrow-right.svg'" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-hover>
                    </v-col>
                </template>
            </v-row>
        </v-container>
    </main-section>
</template>

<script>
import MainSection from "@/components/client/templates/main-section.vue";

export default {
    components: {
        MainSection,
    },
    data: () => ({
        items: [
            {
                title: "ViClean",
                text: "쉽게 청소하고 위생적으로 사용 가능한 변기입니다.",
                image: "/images/main/collections/collection-1.jpg",
                link: "/b/viclean",
            },
            {
                title: "Loop & Friends",
                text: "꿈꾸던 욕실을 완성할 다양한 디자인, 크기, 색상을 만나보세요.",
                image: "/images/main/collections/collection-2.jpg",
                link: "/b/loop-friends",
            },
            {
                title: "Collaro",
                text: "세월이 지나도 변함없는 섬세한 디자인으로 미적 감각, 기능성, 편안함을 모두 담았습니다.",
                image: "/images/main/collections/collection-3.jpg",
                link: "/b/collaro",
            },
            {
                title: "Hommage",
                text: "클래식함과 모던함이 어우러진 고급스럽고 우아한 욕실입니다.",
                image: "/images/main/collections/collection-4.jpg",
                link: "/b/hommage",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.collection-card {
    .collection-card {
        &__button {
            > span {
                transition: all 0.4s;
            }
            > .v-image {
                transition: all 0.2s ease;
            }
        }
    }
}
@media (min-width: 768px) {
    .collection-card {
        &:hover {
            .collection-card {
                &__button {
                    > span {
                        color: var(--v-primary-base);
                    }
                    .v-image {
                        transform: translateX(4px);
                    }
                }
            }
        }
    }
}
</style>