<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/colours/visual.webp" :breadcrumbs="breadcrumbs" title="Bathroom trend: colours" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="txt txt--sm">
                            <p>욕실에서 상쾌한 파란색으로 하루를 시작해 보세요. 아니면 조용한 녹색 숲 속에서 저녁을 마무리해 보세요. 적절한 컬러 컨셉으로 욕실을 오감으로 느끼고 즐길 수 있습니다.</p>
                            <br />
                            <p>디자이너 Gesa Hansen의 약간의 색상 이론, 영감 및 팁을 통해 올바른 색상과 재료를 선택하는 것은 매우 쉽습니다.</p>
                            <br />
                            <p>시도해 보세요!</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="txt txt--xs">계획은 영감에서 시작됩니다</div>
                        <div class="tit mb-12px mb-md-16px">욕실을 위한 색상</div>
                        <p class="txt txt--sm">색상을 사용하여 욕실을 더욱 편안하고 개성있게 만드세요. 색상 사용 방법에 대한 아이디어, 제안, 팁을 제공하는 색상의 세계로 여러분을 안내합니다. 발견해보세요: 섬세한 로제, 강렬한 레드, 따뜻한 옐로우, 내추럴 그린, 차분한 블루, 흙빛 그레이지.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <trends-slide />
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit-wrap tit-wrap--lg text-center">
                            <div class="tit mb-12px mb-md-16px">ARTIS - 가장 아름다운 색상</div>
                            <p class="txt txt--sm">
                                Artis의 조리대 세면대는 다양한 색상과 다양한 모양으로 인상적입니다. <br />
                                10가지 색상과 4가지 모양 중에서 모델을 선택하세요 . 세면대의 섬세한 디자인으로 더욱 아름답게 강조되는 욕실에 강렬하거나 은은한 액센트를 추가하세요.
                            </p>
                        </div>
                    </v-col>
                </v-row>
                <artis-slide />
                <div class="tit-wrap"></div>
                <p class="txt txt--sm text-center">Artis의 다양한 색상으로 개별적인 욕실 디자인이 가능합니다.</p>
            </v-container>
        </page-section>

        <page-section bg="right">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img src="/images/ideas/bathroom/colours/gesa-hansen-sitting-on-pink-chair.webp"></v-img>
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="txt txt--sm">
                            <p>
                                "색상은 분위기, 크기, <br />
                                방의 정의 등 거의 모든 것을 <br />
                                바꿀 수 있습니다." <br />
                            </p>
                            <p>디자이너 - 게사 한센</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="txt txt--xs">색 구성표</div>
                        <div class="tit mb-12px mb-md-16px">색상을 결합하는 네 가지 쉬운 방법</div>
                        <p class="txt txt--sm">하나, 둘, 세 가지 색상을 조합하더라도 조화로운 선택이 중요합니다. 네 가지 색상 구성표를 사용하면 항상 조화로운 효과를 얻을 수 있습니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <section-slide :items="items" path="/images/ideas/bathroom/colours" />
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mt-md-90px">컬러 컨셉 다운로드</div>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-card tile flat class="pa-24px pa-md-32px">
                            <div class="txt txt--xs line-height-2">2024년</div>
                            <h3 class="tit font-tertiary mb-12px mb-md-16px">아티스 컬러 컨셉</h3>
                            <p class="txt txt--xs txt--dark">색상환과 색상 컨셉을 다운로드하여 인쇄하거나, 무드 보드를 만들거나, 약간의 영감을 얻으세요.</p>
                            <div class="txt txt--xs txt--dark mt-12px mt-md-16px">PDF 18MB</div>
                            <v-btn text class="pa-0 mt-20px rounded-0 v-size--xx-small" href="/res/download/Colour_concept_booklet_2023_EN.pdf" download>
                                <span class="txt txt--xs txt--dark font-weight-medium">다운로드 받기</span>
                                <v-img src="/images/icon/icon-download.svg" class="ml-8px" />
                            </v-btn>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="txt txt--xs mb-12px mb-md-16px">디자인 전문가가 전하는 10가지 팁</div>
                        <div class="tit mb-12px mb-md-16px">Gesa Hansen이 추천하는 훌륭한 컬러 컨셉</div>
                        <p class="txt txt--sm">
                            색상을 올바르게 사용하면 놀라운 효과를 얻을 수 있습니다. <br />
                            색상은 욕실을 더 크고, 친근하고, 편안하게 보이게 합니다. <br />
                            아니면 그 반대의 원인이 됩니다.
                        </p>
                        <expansion-panels :data="recommends" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7" order-md="2">
                        <v-img src="/images/ideas/bathroom/colours/gesa-hansen-stands-in-front-of-a-pink-washbasin.webp"></v-img>
                    </v-col>
                    <v-col cols="12" md="5" order-md="1">
                        <div class="txt txt--sm">
                            <p>
                                "색상은 분위기, 크기, <br />
                                방의 정의 등 거의 모든 것을 <br />
                                바꿀 수 있습니다." <br />
                            </p>
                            <p>디자이너 - 게사 한센</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8" class="text-center">
                        <div class="tit mb-12px mb-md-16px">우리의 컬렉션</div>
                        <p class="txt txt--sm">팁 3에서 Gesa Hansen은 욕실 색상을 세면대부터 시작할 것을 권장합니다. Artis 및 Finion 컬렉션은 다양한 색상을 제공합니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row class="row--lg">
                    <template v-for="item in collections">
                        <v-col cols="12" md="4" :key="item.title">
                            <v-card tile elevation="4">
                                <v-img :src="`/images/ideas/bathroom/colours/${item.image}`" />
                                <div class="pa-24px pa-md-32px">
                                    <div class="tit tit--sm mb-12px mb-md-16px">
                                        {{ item.title }}
                                    </div>
                                    <p class="txt txt--sm">
                                        {{ item.text }}
                                    </p>
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import CleaningQnas from "@/components/client/ideas/bathroom/overview/cleaning/cleaning-qnas.vue";
import TrendsSlide from "@/components/client/ideas/bathroom/trends/trends-slide.vue";
import ArtisSlide from "@/components/client/ideas/bathroom/overview/colours/artis-slide.vue";
import SectionSlide from "@/components/client/dumb/section-slide.vue";
import ExpansionPanels from "@/components/client/ideas/expansion-panels.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        CleaningQnas,
        TrendsSlide,
        ArtisSlide,
        SectionSlide,
        ExpansionPanels,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 개요",
                href: "/ideas/bathroom/overview",
            },
        ],
        items: [
            {
                title: "단색화",
                text: "놀라운 단색\r\n단색 배색은 단일 기본 색상의 음영을 사용합니다. 색상의 뉘앙스는 전체 표면에 한 가지 색상만 사용하는 경우보다 욕실을 더 차분하게 보이게 하며 액세서리를 적용하기가 훨씬 쉽습니다.",
                image: "slide-1.webp",
            },
            {
                title: "보완적인",
                text: "반대자들의 미묘한 결합\r\n보색 대비를 사용하면 색상환에서 두 개의 반대 색상이 선택됩니다. 두 가지 색상 중 하나를 악센트로 사용해야 합니다. 그렇지 않으면 효과가 너무 날카로워질 수 있습니다.",
                image: "slide-2.webp",
            },
            {
                title: "비슷한 물건",
                text: "자연스러움에 힘이 있습니다\r\n색상환에서 바로 옆에 있는 색상이 여기에 사용됩니다. 유사한 색상 구성은 자연에서 흔히 볼 수 있습니다. 특히 강렬한 색상을 악센트로 사용하고 더 넓은 영역에 차분한 색상을 사용하는 경우 조화롭고 위험이 없는 디자인 방식입니다.",
                image: "slide-3.webp",
            },
            {
                title: "트라이어드",
                text: "큰 효과를 주는 세 가지 색상\r\n삼원색 구성표는 색상환의 서로 다른 부문에서 선택된 3가지 색상으로 가장 대담합니다. 차분한 색상을 사용하면 결코 화려하고 눈에 띄지 않지만 여전히 조화롭게 보입니다.",
                image: "slide-4.webp",
            },
        ],
        recommends: [
            {
                header: "1. 편안함을 주는 컬러",
                content: "전형적인 흰색은 종종 욕실을 차갑고 무미건조하게 보이게 만듭니다. 색상은 이곳을 더욱 집처럼 편안하게 만들고 오래 머물고 싶게 만드는 데 도움이 됩니다.",
            },
            {
                header: "2. 자신의 감정에 귀를 기울이세요",
                content: "색상을 선택할 때 규칙은 없습니다. 좋은 느낌을 주는 색상이면 어떤 색상이든 허용됩니다. 확실하지 않은 경우 파란색 음영으로 시작하는 것이 가장 좋습니다. 데님과 비슷하게 결합하기가 가장 쉽고 항상 잘 맞습니다.",
            },
            {
                header: "3. 세부사항부터 시작하세요",
                content: "천천히 색상에 접근하세요. 벽 전체를 컬러 타일로 마감하는 대신 먼저 컬러 싱크대를 사용해 보세요. 선택이 만족스럽지 않으면 되돌리기가 더 쉽습니다.",
            },
            {
                header: "4. 조명 조건에 주의하세요",
                content: "빛에 따라 색상이 전혀 다르게 보일 수 있습니다. 선택하기 전에 다양한 장소에서 색상을 테스트하고 조명 조건에 따라 색상이 어떻게 변하는지 확인해야 합니다.",
            },
            {
                header: "5. 시끄러운 색상, 조용한 색상",
                content: "색상은 온도에 따라 구성되며 빨간색은 따뜻한 톤이고 파란색은 차가운 톤입니다. 그러나 볼륨 문제도 중요합니다. 넓은 공간에는 조용한 파스텔 톤과 중간 톤을 사용하는 것이 좋습니다. 강한 색상은 세부 사항에 더 적합합니다.",
            },
            {
                header: "6. 작은 방, 큰 효과",
                content: "작은 방이라도 강한 색상을 견딜 수 있습니다. 올바르게 사용하면 더 크게 나타날 수도 있습니다. 항상 방의 짧은 벽을 색상으로 칠하고 천장 조명은 흰색이나 그대로 두십시오. 이러한 대조는 시각적으로 위대함을 만들어냅니다.",
            },
            {
                header: "7. 색상은 보안을 만듭니다",
                content: "넓은 욕실은 편안하지만 금방 불편하고 추워 보일 수도 있습니다. 색상을 통해 경계를 그릴 수 있습니다. 누에고치 같은 느낌을 주어 웰빙을 보장합니다.",
            },
            {
                header: "8. 롤모델로서의 자연",
                content: "색상 조합은 생각보다 쉽습니다. 자연은 당신에게 방법을 보여줍니다. 꽃 초원에서 서로 다른, 아마도 반대되는 색상이 얼마나 잘 어울리는 지 볼 수 있습니다. 이것이 당신에게 영감을 주도록 하세요.",
            },
            {
                header: "9. 새로운 조합을 시도해 보세요",
                content: "무언가를 바꾸고 싶다면 항상 모든 색상을 변경할 필요는 없습니다. 각각의 새로운 색상 조합은 완전히 다른 분위기를 만들어냅니다. 예를 들어 빨간색은 노란색 옆에 있을 때와 검은색 옆에 있을 때 완전히 다르게 보입니다.",
            },
            {
                header: "10. 나만의 컬러 컨셉을 만드는 방법",
                content: "무드 보드는 자신만의 색상 컨셉을 만드는 데 도움이 될 수 있습니다. 다양한 재료 샘플을 찾아 나란히 배치하여 서로 조화를 이루는지 테스트합니다. PDF로 인쇄할 수 있는 Villeroy & Boch의 컬러 휠도 도움이 됩니다.",
            },
        ],
        collections: [
            {
                title: "Artis",
                text: "9가지 색상과 4가지 모양으로 구성된 Artis 세면기는 매혹적인 색상 컨셉을 구현할 수 있는 수천 가지 가능성을 제공합니다.",
                image: "artis-washbasin-yellow-in-black-bathroom.webp",
            },
            {
                title: "Finion",
                text: "Finion 컬렉션의 가구는 개별 디자인의 폭이 넓습니다. 예를 들어, 10가지 매혹적인 페인트 색상이 있습니다.",
                image: "artis-washbasin-light-blue-and-white-modern-blue-bathroom.webp",
            },
            {
                title: "Colour on Demand",
                text: "Color on Demand 기술을 사용하면 원하는 색상으로 디자인된 7가지 컬렉션의 욕조를 가질 수 있습니다.",
                image: "theano-bathtub-rust-with-artis-washbasin-and-viclean.webp",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>