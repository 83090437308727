<template>
    <page-section>
        <v-container>
            <swiper v-bind="{ options }">
                <template v-for="item in items">
                    <swiper-slide :key="item.title">
                        <v-row align="center" class="row--lg">
                            <v-col cols="12" md="5" order="2" order-md="1">
                                <v-card class="h-md-400px d-md-flex align-center">
                                    <div>
                                        <div class="tit mb-12px mb-md-16px">
                                            {{ item.title }}
                                        </div>
                                        <p class="txt txt--sm text-pre-wrap mb-12px mb-md-16px">
                                            {{ item.text, }}
                                        </p>
                                        <v-btn large color="#fff" elevation="2" class="rounded-xs ml-1" :to="item.link">
                                            {{ item.title }} 보러가기
                                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                        </v-btn>
                                    </div>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="7" order="1" order-md="2">
                                <v-card tile :img="item.image" class="position-relative mb-50px mb-md-0">
                                    <v-responsive :aspect-ratio="674 / 504" />
                                    <div class="swiper-controler">
                                        <v-row align="center" class="row--xs">
                                            <v-col cols="auto">
                                                <v-btn x-small icon outlined class="swiper-button swiper-button--prev">
                                                    <v-img max-width="32" src="/images/icon/icon-swiper-left.svg" />
                                                </v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <div class="swiper-pagination" />
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn x-small icon outlined class="swiper-button swiper-button--next">
                                                    <v-img max-width="32" src="/images/icon/icon-swiper-right.svg" />
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </swiper-slide>
                </template>
            </swiper>
        </v-container>
    </page-section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import PageSection from "@/components/client/templates/page-section.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        PageSection,
    },
    data: () => ({
        items: [
            {
                title: "Subway 3.0",
                text: "피팅의 모든 각도, 곡선 및 가장자리는 Subway 3.0의 세라믹과 완벽하게 일치합니다. Subway 3.0 피팅은 컬렉션의 디자인을 채택하고 시대를 초월한 스타일에 완벽하게 들어맞습니다. 이것이 바로 우리가 Top Together라고 말하는 이유입니다.",
                image: "/images/shop/overview/liberty-1.webp",
                link: "/b/subway-30",
            },
            {
                title: "Liberty",
                text: "원형과 타원형 요소의 약간 유쾌한 기하학이 매끄러운 직선으로 보완됩니다. Liberty를 사용하면 모든 모양이 조화로운 디자인으로 함께 흐르며 특히 Collaro 세라믹의 가장자리와 곡선과 잘 어울립니다. 이것이 바로 우리가 Top Together라고 말하는 이유입니다.",
                image: "/images/shop/overview/liberty-2.webp",
                link: "/b/liberty",
            },
            {
                title: "Mettlach",
                text: "Mettlach는 고품질 디테일과 탁월한 제작 기술을 상징합니다. 명확한 라인과 우아한 윤곽이 Memento 컬렉션의 순수하고 가벼움을 완벽하게 보완합니다. 이것이 바로 우리가 Top Together라고 말하는 이유입니다.",
                image: "/images/shop/overview/liberty-3.webp",
                link: "/b/mettlach",
            },
            {
                title: "Conum",
                text: "탁월한 디자인, 설득력 있는 제작 기술 - Conum은 독특한 특성을 지닌 프리미엄 욕실을 위한 최고의 선택입니다. 피팅은 눈에 띄고 조화로운 방식으로 Finion의 명확한 디자인에 딱 들어맞습니다. 이것이 바로 우리가 Top Together라고 말하는 이유입니다.",
                image: "/images/shop/overview/liberty-4.webp",
                link: "/b/conum",
            },
            {
                title: "Architectura Square",
                text: "명확한 모양이 여기에 함께 있습니다. Architectura Square 설비는 Architectura 컬렉션의 욕실 디자인에 특히 조화롭게 어울립니다. 이것이 바로 우리가 Top Together라고 말하는 이유입니다.",
                image: "/images/shop/overview/liberty-5.webp",
                link: "/b/architectura-square",
            },
        ],
        options: {
            effect: "fade",
            allowTouchMove: true,
            autoplay: {
                delay: 5000,
                waitForTransition: true,
                disableOnInteraction: false,
            },
            speed: 500,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + "</span>";
                },
            },
            navigation: {
                nextEl: ".swiper-button--next",
                prevEl: ".swiper-button--prev",
            },
        },
    }),
};
</script>

<style lang="scss" scoped>
.inspiration-text {
    position: relative;
    z-index: 3;
}
.swiper {
    position: relative;
    &::before {
        content: "";
        width: 100vw;
        height: 100%;
        background-color: #fff;
        position: absolute;
        top: 0;
        left: -100vw;
        z-index: 2;
    }
}
.swiper-container {
    overflow: visible;
    .swiper-wrapper {
        overflow: inherit;
    }
}
.swiper-controler {
    position: absolute;
    bottom: -56px;
    left: 0;
    .swiper-pagination {
        position: static;
        padding: 0 16px;
        ::v-deep(.swiper-pagination-bullet) {
            margin: 0 16px !important;
            background-color: initial !important;
            border: initial !important;
            width: auto !important;
            height: auto !important;
            font-weight: 500;
            color: var(--v-grey-darken3);
            border-radius: initial;
            &:first-child {
                margin-left: 0 !important;
            }
            &:last-child {
                margin-right: 0 !important;
            }
            &-active {
                border-bottom: 1px solid var(--v-grey-darken3) !important;
            }
        }
    }
}
</style>