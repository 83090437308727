<template>
    <client-page>
        <collections-visual :image="`${path}/visual.webp`" title="ANTAO" :breadcrumbs="breadcrumbs" />

        <page-section>
            <v-container>
                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="txt txt--sm text-center">
                            숲속 산책, 해변 산책 등 이런 곳에서 우리는 자연과 가까워지는 것이 얼마나 좋은지 느끼게 됩니다. 우리는 다시 머리를 비우고 새로운 아이디어를 생각해 냅니다. 우리에게 자연은 에너지의 원천이자 휴식처입니다. 우리의 새로운 Antao 컬렉션은 우리가 자연과 접촉하면서 경험하는 거의 마법 같은 에너지를 발산합니다. 전체적인 디자인 언어는 나뭇잎에 맺힌 이슬 방울에서 영감을 받았습니다. 부드럽고 비대칭적인 곡선이 디자인의 특징입니다. <br />
                            Antao도 느껴보세요. 이 욕실에서는 자연과 소통할 수 있습니다.
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <div class="tit-wrap tit-wrap--lg">
                    <div class="tit text-center">자연과의 만남</div>
                </div>
            </v-container>
            <v-img :src="`${path}/antao-bath-tub-white-in-modern-bathroom.webp`" />
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <div class="tit-wrap tit-wrap--lg">
                            <div class="tit mb-12px mb-md-16px">Antao 제품군을 살펴보세요</div>
                            <p class="txt txt--sm">Antao 컬렉션은 욕실 디자인을 위한 광범위한 제품을 제공합니다. 화장실부터 욕실 가구, 그에 어울리는 설비까지 욕실에 필요한 모든 것을 여기에서 찾으실 수 있습니다. 팁: 이 제품군은 다른 Villeroy & Boch 컬렉션과 이상적으로 결합할 수도 있습니다. 이를 통해 개별 인테리어 디자인에 대한 더 많은 범위가 제공됩니다.</p>
                        </div>
                    </v-col>
                </v-row>
                <idea-product-list :path="path" :items="items" />
            </v-container>
        </page-section>

        <!-- <page-section>
            <v-container>
                <div class="tit-wrap text-center">
                    <div class="tit">우리의 Antao 제품</div>
                </div>
                <v-tabs centered class="mb-20px mb-md-40px" v-model="tab">
                    <template v-for="item in products">
                        <v-tab :key="item.tab">
                            <v-btn large class="rounded-xs">
                                {{ item.tab }}
                            </v-btn>
                        </v-tab>
                    </template>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <template v-for="item in products">
                        <v-tab-item :key="item.tab">
                            <v-row>
                                <v-col cols="6" sm="4" lg="3">
                                    <shop-product-card />
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </template>
                </v-tabs-items>
                <div class="tit-wrap" />
                <div class="d-flex justify-center">
                    <v-btn large color="#fff" elevation="2" class="rounded-xs" to="/">
                        제품 보러가기
                        <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                    </v-btn>
                </div>
            </v-container>
        </page-section> -->

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--sm text-center">
                    <div class="tit">Antao의 다양성을 발견해보세요</div>
                </div>

                <center-slide :path="path" :items="images" />

                <v-row justify="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="text-center">
                            <div class="txt txt--sm txt--dark mb-12px mb-md-16px">유동적으로</div>
                            <p class="txt txt--sm">Antao의 다양성은 다양한 스타일을 창조하고 다양한 환경에 적응하는 능력에서 분명하게 드러납니다. 미니멀하고 현대적이든 소박하고 아늑하든 Antao를 사용하면 개인 휴양지를 디자인하고 자연과의 내면의 연결을 표현할 수 있습니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import CollectionsVisual from "@/components/client/collections/collections-visual.vue";
import IdeaProductList from "@/components/client/ideas/idea-product-list.vue";
import CenterSlide from "@/components/client/dumb/center-slide.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        ClientPage,
        PageSection,
        ShopProductVisual,
        CollectionsVisual,
        IdeaProductList,
        CenterSlide,
    },
    data: () => ({
        tab: null,
        breadcrumbs: [
            {
                text: "컬렉션",
                href: "/collections/antao",
            },
            {
                text: "안타오",
                href: "/collections/antao",
            },
        ],
        path: "/images/collections/antao",
        items: [
            {
                title: "세면기",
                text: "이슬방울 모양을 바탕으로 한 캐비닛형 세면대 또는 조리대형 세면대",
                image: "antao-washbasin-green-with-running-water.webp",
                // button: "Antao 세면기로",
                cols: "6",
                // link: "/",
            },
            {
                title: "피팅",
                text: "욕조, 샤워기 또는 싱크대에 어울리는 피팅",
                image: "antao-washbasin-green-with-silver-tab.webp",
                // button: "Antao 피팅으로",
                cols: "6",
                // link: "/",
            },
            {
                title: "욕실 가구",
                text: "둥근 모서리가 있는 하부 수납장과 높은 수납장, 찬장",
                image: "antao-bathroom-furniture-green-and-beige-in-modern-bathroom.webp",
                // button: "Antao 욕실가구 바로가기",
                cols: "12",
                // link: "/",
            },
            {
                title: "양변기",
                text: "둥근 모서리가 있는 하부 수납장과 높은 수납장, 찬장",
                image: "antao-toilet-in-beige-bathroom.webp",
                // button: "Antao 양변기로",
                cols: "6",
                // link: "/",
            },
            {
                title: "조명이 통합된 거울",
                text: "터치 센서와 전방위 LED 조명을 갖춘 세 가지 크기의 스마트홈 호환 거울",
                image: "antao-bathromm-mirror-illuminated-in-modern-bathroom.webp",
                // button: "Antao 조명 거울로",
                cols: "6",
                // link: "/",
            },
            {
                title: "욕조",
                text: "순수한 휴식을 위한 인체공학적 모양의 욕조",
                image: "antao-bath-tub-white-in-bathroom-with-wall-of-glass.webp",
                // button: "Antao 욕조로",
                cols: "12",
                // link: "/",
            },
        ],
        products: [
            {
                tab: "세먼기",
            },
            {
                tab: "피팅",
            },
            {
                tab: "욕실 가구",
            },
            {
                tab: "화장실",
            },
            {
                tab: "거울",
            },
            {
                tab: "욕조",
            },
        ],
        images: [
            "antao-bath-tub-beige-in-modern-bathroom.webp",
            "antao-toilet-black-in-modern-black-and-white-bathroom.webp",
            "antao-bath-tub-in-stylish-bathromm.webp",
            //
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.v-tab {
    &::before {
        display: none;
    }
    .v-btn {
        border: 1px solid var(--v-grey-lighten3);
        background-color: transparent !important;
        color: var(--v-grey-darken3) !important;
        text-transform: initial;
    }
    &--active {
        .v-btn {
            border-color: var(--v-primary-base);
            background-color: var(--v-primary-base) !important;
            color: #fff !important;
        }
    }
}

@media (min-width: 768px) {
    .v-tab {
        padding: 0 12px;
    }
}
</style>