<template>
    <client-page>
        <shop-product-visual wide image="/images/ideas/bathroom/trends/visual.webp" :breadcrumbs="breadcrumbs" title="Ideas & Trends" />

        <page-section class="py-40px py-md-80px">
            <v-container>
                <div class="mt-50 mt-md-100px">
                    <v-row class="row--lg">
                        <v-col cols="12" md="8">
                            <div class="tit mb-12px mb-md-16px">욕실이 트렌디한 오아시스가 됩니다!</div>
                            <p class="txt txt--sm">최신 트렌드, 멋진 아이디어, 가장 인기 있는 욕실 스타일 - 욕실을 나만의 개성 있는 공간으로 바꾸고 일상 생활을 스타일리시하게 만드는 방법을 보여 드리겠습니다. 새로운 꿈의 욕실로 가는 길에 Villeroy & Boch의 창의적인 공간과 색상 컨셉에서 영감을 얻으세요. 지금 트렌드를 발견하고 가장 핫한 스타일을 찾아보세요!</p>
                        </v-col>
                    </v-row>
                </div>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 py-40px py-md-80px mb-40px mb-md-80px">
            <v-container>
                <v-row>
                    <v-col cols="12" md="3" class="border-md-r">
                        <div class="txt txt--sm txt--dark font-weight-medium mb-18p mb-md-24px">모든 취향에 맞는 욕실 스타일</div>
                        <router-link to="#1" class="txt txt--sm d-flex align-center">
                            스타일 살펴보기
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="3" class="border-md-r">
                        <div class="txt txt--sm txt--dark font-weight-medium mb-18p mb-md-24px">욕실의 색상 트렌드</div>
                        <router-link to="#2" class="txt txt--sm d-flex align-center">
                            색상 살펴보기
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="3" class="border-md-r">
                        <div class="txt txt--sm txt--dark font-weight-medium mb-18p mb-md-24px">모든 스타일에 맞는 제품</div>
                        <router-link to="#3" class="txt txt--sm d-flex align-center">
                            욕실용품을 만나보세요
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                    <v-col cols="12" md="3">
                        <div class="txt txt--sm txt--dark font-weight-medium mb-18p mb-md-24px">더 많은 아이디어</div>
                        <router-link to="#4" class="txt txt--sm d-flex align-center">
                            영감을 찾아보세요
                            <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="ml-8px" />
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--xxl">
                    <v-col cols="12" md="7">
                        <v-img src="/images/ideas/bathroom/trends/trend-1.webp" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="txt txt--xs mb-20px grey--text font-weight-light">2024년 트렌드</div>
                        <div class="tit mb-12px mb-md-16px">현재 욕실 트렌드: 올해의 필수 아이템</div>
                        <p class="txt txt--sm mb-18px mb-md-24px">2024년에는 욕실이 현대적인 디자인과 편안함을 상징하게 될 것입니다. 이러한 발전을 반영하는 10가지 트렌드를 소개합니다.</p>
                        <v-btn large color="#fff" elevation="2" class="rounded-xs" to="/ideas/bathroom/trends/2024">
                            지금 트렌드를 알아보세요
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="1">
            <v-container>
                <div class="product-section-title">
                    <span class="tit font-weight-regular font-tertiary white">최고의 욕실 스타일</span>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--lg text-center">
                    <div class="tit">모든 취향에 맞는 영감</div>
                </div>
                <v-row class="row--lg">
                    <template v-for="(item, index) in inspirations">
                        <v-col :key="index" cols="6" md="3">
                            <v-card tile flat :to="item.link">
                                <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                <div class="txt grey--text text--darken-3 mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm grey--text text--darken-3">
                                    {{ item.text }}
                                </p>
                                <div class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">{{ item.title }} 욕실 알아보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5 py-40px py-md-80px my-20px my-md-40px" id="2">
            <v-container>
                <div class="product-section-title tit-wrap tit-wrap--lg">
                    <span class="tit font-weight-regular font-tertiary grey lighten-5">색상 트렌드</span>
                </div>
                <h3 class="tit text-center mb-12px mb-md-16px">마법 같은 색상의 매력</h3>
                <p class="txt txt--sm text-center">이런 연출로 욕실이 단번에 시선을 사로잡을 것입니다.</p>
                <div class="d-flex justify-center mt-12px mt-md-16px">
                    <v-btn large color="#fff" elevation="2" class="rounded-xs" to="/ideas/bathroom/overview/colours">
                        색상 살펴보기
                        <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                    </v-btn>
                </div>
                <div class="color-image-wrap">
                    <v-img src="/images/ideas/bathroom/trends/trend-color-1.webp" max-width="672" />
                    <v-img src="/images/ideas/bathroom/trends/trend-color-2.webp" class="positioned-image positioned-image--1" />
                    <v-img src="/images/ideas/bathroom/trends/trend-color-3.webp" class="positioned-image positioned-image--2" />
                    <div class="color-text">
                        "색상은 분위기, 크기, <br />
                        방의 정의 등 거의 모든 것을 <br />
                        바꿀 수 있습니다." <br />
                        디자이너 - 게사 한센
                    </div>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <div class="tit-wrap tit-wrap--lg text-center">
                    <div class="tit">당신의 욕실에는 어떤 색상이 어울리나요?</div>
                </div>
                <v-row class="row--lg">
                    <template v-for="(item, index) in colors">
                        <v-col :key="index" cols="6" md="3">
                            <v-card tile flat :href="item.link" target="_blank">
                                <v-img :src="item.image" class="mb-12px mb-md-16px" />
                                <div class="txt grey--text text--darken-3 mb-6px mb-md-8px">
                                    {{ item.title }}
                                </div>
                                <p class="txt txt--sm grey--text text--darken-3">
                                    {{ item.text }}
                                </p>
                                <div v-if="item.link" class="mt-12px mt-md-16px d-flex align-center">
                                    <span class="txt txt--xs txt--dark font-weight-medium">{{ item.button }} 욕실 알아보기</span>
                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                </div>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <trends-slide />
        </page-section>

        <trends-products-tabs />

        <page-section>
            <v-row align="center" class="row--lg">
                <v-col lg="7">
                    <v-img max-height="508" src="/images/ideas/bathroom/trends/trend-idea.webp"></v-img>
                </v-col>
                <v-col lg="5">
                    <div class="tit mb-12px mb-md-16px">
                        더 많은 욕실 아이디어를 <br />
                        원하시나요?
                    </div>
                    <p class="txt txt--sm mb-18px mb-md-24px">
                        그렇다면 지금 욕실에 대한 객실 아이디어를 살펴보거나 <br />
                        가까운 전시회를 방문하는 것이 가장 좋습니다.
                    </p>
                    <v-btn large color="#fff" elevation="2" class="rounded-xs" to="/ideas/bathroom/overview">
                        욕실에 대한 아이디어
                        <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                    </v-btn>
                </v-col>
            </v-row>
        </page-section>

        <trends-more-ideas />

        <trends-interested />

        <trends-exploration />
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import PlannerSlides from "@/components/client/ideas/bathroom/planner/planner-slides.vue";
import TrendsSlide from "@/components/client/ideas/bathroom/trends/trends-slide.vue";
import TrendsProductsTabs from "@/components/client/ideas/bathroom/trends/trends-products-tabs.vue";
import TrendsMoreIdeas from "@/components/client/ideas/bathroom/trends/trends-more-ideas.vue";
import TrendsInterested from "@/components/client/ideas/bathroom/trends/trends-interested.vue";
import TrendsExploration from "@/components/client/ideas/bathroom/trends/trends-exploration.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        PlannerSlides,
        TrendsSlide,
        TrendsProductsTabs,
        TrendsMoreIdeas,
        TrendsInterested,
        TrendsExploration,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 아이디어 및 트렌드",
                href: "/ideas/bathroom/planner",
            },
        ],
        inspirations: [
            {
                title: "스칸디",
                text: "기분 좋은 욕실을 위한 북유럽 디자인",
                image: "/images/ideas/bathroom/trends/inspiration-1.webp",
                link: "/ideas/bathroom/trends/styles/scandi",
            },
            {
                title: "산업적",
                text: "꿈꾸던 욕실을 위한 거친 매력의 디자인",
                image: "/images/ideas/bathroom/trends/inspiration-2.webp",
                link: "/ideas/bathroom/trends/styles/industrial",
            },
            {
                title: "지중해",
                text: "당신만의 공간에서 누리는 행복한 휴식",
                image: "/images/ideas/bathroom/trends/inspiration-3.webp",
                link: "/ideas/bathroom/trends/styles/mediterranean",
            },
            {
                title: "재팬디",
                text: "젠 스타일과 미니멀리즘의 만남, 욕실에서 완성되다",
                image: "/images/ideas/bathroom/trends/inspiration-4.webp",
                link: "/ideas/bathroom/trends/styles/japandi",
            },
            {
                title: "미니멀리스트",
                text: "욕실을 위한 정제된 우아함",
                image: "/images/ideas/bathroom/trends/inspiration-5.webp",
                link: "/ideas/bathroom/trends/styles/minimalist",
            },
            {
                title: "보헤미안",
                text: "나만의 오아시스를 위한 자유로운 스타일",
                image: "/images/ideas/bathroom/trends/inspiration-6.webp",
                link: "/ideas/bathroom/trends/styles/boho",
            },
            {
                title: "휘게",
                text: "당신의 집을 위한 따뜻한 아늑함",
                image: "/images/ideas/bathroom/trends/inspiration-7.webp",
                link: "/ideas/bathroom/trends/styles/hygge",
            },
            {
                title: "별장",
                text: "웰빙의 오아시스를 위한 자연스러운 우아함",
                image: "/images/ideas/bathroom/trends/inspiration-8.webp",
                link: "/ideas/bathroom/trends/styles/country",
            },
            {
                title: "우디",
                text: "자연의 따뜻함과 세련된 스타일의 조화",
                image: "/images/ideas/bathroom/trends/inspiration-9.webp",
                link: "/ideas/bathroom/trends/styles/wood",
            },
            {
                title: "빈티지",
                text: "욕실을 위한 클래식한 매력과 시간에 구애받지 않는 우아함",
                image: "/images/ideas/bathroom/trends/inspiration-10.webp",
                link: "/ideas/bathroom/trends/styles/vintage",
            },
            {
                title: "아라비안",
                text: "아라비안나이트를 떠올리게 하는 이국적인 패턴과 매혹적인 색감",
                image: "/images/ideas/bathroom/trends/inspiration-11.webp",
                link: "/ideas/bathroom/trends/styles/oriental",
            },
            {
                title: "럭셔리",
                text: "고품질 소재와 세련된 우아함의 조화",
                image: "/images/ideas/bathroom/trends/inspiration-12.webp",
                link: "/ideas/bathroom/trends/styles/luxurious",
            },
        ],
        colors: [
            {
                title: "블랙의 아름다움",
                text: "시선을 사로잡는 욕실.",
                image: "/images/ideas/bathroom/trends/color-list-1.webp",
                button: "블랙",
                // link: "/",
            },
            {
                title: "인더스트리얼 시크",
                text: "콘크리트 감성의 세련된 욕실.",
                image: "/images/ideas/bathroom/trends/color-list-2.webp",
                button: "콘크리트 느낌",
                // link: "/",
            },
            {
                title: "그레이",
                text: "유행 없는 우아한 욕실.",
                image: "/images/ideas/bathroom/trends/color-list-3.webp",
                button: "그레이",
                // link: "/",
            },
            {
                title: "화이트",
                text: "꿈같은 화이트 욕실",
                image: "/images/ideas/bathroom/trends/color-list-4.webp",
                button: "화이트",
                // link: "/",
            },
            {
                title: "그린",
                text: "산뜻함이 느껴지는 자연 욕실",
                image: "/images/ideas/bathroom/trends/color-list-5.webp",
                button: "그린",
                // link: "/",
            },
            {
                title: "베이지",
                text: "심플하고 부드러운 욕실",
                image: "/images/ideas/bathroom/trends/color-list-6.webp",
                button: "베이지색",
                // link: "/",
            },
            {
                title: "파스텔",
                text: "은은하고 감성적인 욕실",
                image: "/images/ideas/bathroom/trends/color-list-7.webp",
                button: "파스텔톤",
                // link: "/",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
.color-image-wrap {
    position: relative;
    margin-top: 30px;
    .positioned-image {
        &--1 {
            max-width: 288px;
            margin-top: -60px;
            margin-right: calc(-1 * var(--container-gutter));
            margin-left: auto;
            position: relative;
            z-index: 1;
        }
        &--2 {
            max-width: 240px;
            margin-left: calc(-1 * var(--container-gutter));
            margin-top: -180px;
        }
    }
    .color-text {
        font-size: 12px;
        line-height: 1.8;
        margin-top: 40px;
    }
}
@media (min-width: 768px) {
    .color-image-wrap {
        margin-top: 40px;
        .positioned-image {
            max-width: 368px;
            &--1 {
                margin-top: -130px;
                margin-right: 266px;
            }
            &--2 {
                margin: initial;
                position: absolute;
                top: 100px;
                right: 0;
            }
        }
    }
}
@media (min-width: 1024px) {
    .color-image-wrap {
        margin-top: 40px;
        .positioned-image {
            max-width: 368px;
            &--1 {
                margin-top: -130px;
                margin-right: 266px;
            }
            &--2 {
                margin: initial;
                position: absolute;
                top: 100px;
                right: 0;
            }
        }
        .color-text {
            position: absolute;
            bottom: 100px;
            margin: initial;
        }
    }
}
</style>