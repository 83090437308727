var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": `${_vm.path}/visual.webp`,
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Industrial-style bathroom"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("생동감 넘치는 소재와 강렬한 대비는 산업 스타일의 특징입니다. 산업 스타일 욕실을 디자인하는 방법을 여기에서 알아보세요.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_vm._l(_vm.navigation, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('router-link', {
      staticClass: "txt txt--sm d-flex align-center",
      attrs: {
        "to": `#${index + 1}`
      }
    }, [_c('v-img', {
      staticClass: "mr-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-down.svg"
      }
    }), _vm._v(" " + _vm._s(item) + " ")], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('dot-list', [_vm._l(_vm.overview, function (item) {
    return [_c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "right",
      "id": "1"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/infinity-showers-shower-system-matt-black-with-squaro-infinity-shower-tray.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("산업 스타일의 특징은 무엇일까?")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실에서 점점 더 많이 볼 수 있는 산업 스타일의 주요 영감은 오래된 공장 건물입니다. 이 디자인은 어떤 거칠음과 도시적 미학을 혼합합니다.")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("산업 스타일은 일반적으로 미니멀리즘을 지향하지만 물론 일부 장식적 요소는 허용합니다. 개방형 평면 디자인은 산업 스타일의 또 다른 특징입니다. 중성 톤과 대담한 대비가 생생한 색상보다 선호됩니다.")])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "2"
    }
  }, [_c('v-container', [_c('section-slide', {
    attrs: {
      "items": _vm.tipSlide,
      "path": _vm.path
    }
  })], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "right"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/collaro-white-washbasin-black-furniture-subway3-mirror-black-bathroom.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("팁 4: 멋진 블랙 & 그레이 색상 구성표")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("진한 검은색과 밝은 흰색을 조합하여 대비를 만드세요. 회색 음영은 산업용 욕실에 깊이를 더해줍니다. 색상 구성표에는 자연스러운 갈색 음영이나 밝은 분홍색도 포함될 수 있지만, 밝은 색상은 피하는 것이 가장 좋습니다. 검은색 산업용 세면대와 크롬 수도꼭지를 조합하여 디자인에 마무리 터치를 더하세요.")])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "left"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("팁 5: 장식적 특징과 식물로 아늑한 느낌을 더하세요")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("장식적인 특징은 산업 스타일 욕실에서도 필수적입니다. 나머지 디자인과 조화를 이루는 경우에 한합니다. 신중하게 선택한 꽃병은 식물을 욕실로 가져오고 산업 스타일의 차가움을 상쇄하는 완벽한 방법입니다. 부드러운 욕실 직물은 아늑한 느낌을 만들어내고 색상 구성과 조화를 이루어야 합니다.")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/manufacture-vases-beige-as-diffusor-in-bathroom.webp`
    }
  })], 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "md": "1"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card', {
    staticClass: "pa-24px pa-md-40px txt",
    attrs: {
      "tile": "",
      "elevation": "3"
    }
  }, [_c('p', [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', [_vm._v("mdi-information-outline")])], 1), _c('v-col', {
    staticClass: "font-weight-medium"
  }, [_vm._v("팁")])], 1)], 1), _c('p', {
    staticClass: "txt--sm"
  }, [_vm._v("산업 스타일의 로프트 특성은 큰 창문과 높은 천장이 있는 넓은 욕실에서 특히 잘 어울립니다. 하지만 작은 욕실도 산업적인 느낌을 줄 수 있습니다. 이 경우 이 스타일의 전형적인 요소를 사용하는 것이 더욱 중요합니다.")])])], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "grey lighten-5",
    attrs: {
      "id": "3"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("욕실을 위한 2024년 트렌드 더 보기")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("산업적인 룩 외에도 욕실을 위해 선택할 수 있는 다른 스타일이 많이 있습니다. 개인 취향과 욕실 디자인에 가장 잘 맞는 스타일을 결정하세요. 대부분의 경우 모든 것을 바꿀 필요는 없습니다. 몇 가지 간단한 조정만으로도 욕실을 2024년에 맞게 최신식으로 만들기에 충분합니다.")]), _c('v-btn', {
    staticClass: "rounded-xs mt-18px mt-md-24px",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/ideas/bathroom/trends/2024"
    }
  }, [_vm._v(" 2024년 욕실 트렌드 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/collaro-white-washbasin-black-furniture-subway3-mirror-black-bathroom.webp`
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }