var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": `${_vm.path}/visual.webp`,
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Boho bathroom"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("빌레로이앤보흐와 함께 완벽한 보헤미안 스타일 욕실을 디자인하는 방법을 알아보세요.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_vm._l(_vm.navigation, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('router-link', {
      staticClass: "txt txt--sm d-flex align-center",
      attrs: {
        "to": `#${index + 1}`
      }
    }, [_c('v-img', {
      staticClass: "mr-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-down.svg"
      }
    }), _vm._v(" " + _vm._s(item) + " ")], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('dot-list', [_vm._l(_vm.overview, function (item) {
    return [_c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "1"
    }
  }, [_c('v-container', {
    staticClass: "text-center"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("보헤미안 스타일의 전형적인 특징")]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("보헤미안 스타일은 창의성, 기발함, 개성을 중시하지만, 특정 아이템과 색상은 여전히 ​​신중한 계획이 필요합니다. 어느 정도 조화를 이루면 욕실에 가볍고 통풍이 잘되는 분위기가 조성됩니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, _vm._l(_vm.checkList, function (item, index) {
    return _c('v-row', {
      key: index,
      staticClass: "row--xs"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-check")])], 1), _c('v-col', [_c('p', {
      staticClass: "txt txt--sm txt--dark"
    }, [_vm._v(" " + _vm._s(item) + " ")])])], 1);
  }), 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/loop-friends-washbasin-black-in-balinese-style-bathroom.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("보호 소재")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("보헤미안 스타일은 보헤미안 문화에서 유래되었으며 히피 미학의 요소를 반영합니다. 따라서 자연과의 연결은 또 다른 결정적 특징입니다. 나무, 라탄, 리넨과 같은 천연 소재는 이 스타일에 완벽합니다. 원단도 중요한 역할을 합니다. 부드럽고 가볍고 통풍이 잘 되어야 합니다. 프린지와 레이스는 훌륭한 추가 요소입니다. 한편, 고리버들 바구니는 보헤미안 욕실에 마무리 터치를 더할 것입니다.")])])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "left"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("보헤미안 스타일에는 어떤 색상이 가장 잘 어울리나요?")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("기본 보헤미안 스타일 색상은 흙빛입니다: 갈색, 베이지, 모래, 카키, 올리브. 아늑한 느낌을 주는 따뜻한 색조를 선택하세요. 청록색, 밝은 녹색, 노란색, 보라색 또는 따뜻한 주황색과 같은 밝고 명랑한 색조로 생동감 있는 터치를 더하세요. 보헤미안 스타일에서는 단일 기본 색상과 하나의 악센트 색상에 고집하는 대신 욕실에 다양한 색상 악센트를 자유롭게 사용할 수 있습니다.")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/loop-and-friends-washbasin-in-white-bathroom-santorinian-style.webp`
    }
  })], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "2"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/subway-30-bathroom-with-boho-accessories.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("보헤미안 스타일 장식")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("보헤미안 욕실 장식은 호화롭고 다채로울 수 있습니다. 예를 들어, 팜파스 그라스로 채워진 베이지색 꽃병을 몇 장의 사진과 휴일 기념품 옆에 두는 것입니다.")])])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "left",
      "id": "3"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("작은 욕실을 위한 보헤미안 스타일")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("작은 욕실조차도 아늑한 보헤미안 스타일로 빠르게 변신할 수 있습니다. 좁은 공간에서 인상적인 효과를 위해 직물과 작은 액세서리를 사용하여 환영하는 보헤미안 분위기를 연출하세요. 공간에 너무 많은 것을 집어넣으려고 하지 마세요. 방이 더 작아 보일 것입니다. 액세서리를 신중하게 선택하고 개성을 더하세요.")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/architectura-bathtub-with-hand-shower-and-toilet.webp`
    }
  })], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "4"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "product-section-title"
  }, [_c('span', {
    staticClass: "tit font-weight-regular font-tertiary white"
  }, [_vm._v("보헤미안 욕실을 위한 Villeroy & Boch 솔루션")])])])], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('v-row', {
    staticClass: "row--xxl",
    attrs: {
      "justify": "center"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": item.cols
      }
    }, [_c('v-card', {
      staticClass: "mx-auto",
      attrs: {
        "max-width": "776",
        "color": "transparent",
        "tile": "",
        "flat": "",
        "href": item.link
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": `${_vm.path}/${item.image}`
      }
    }), _c('div', {
      staticClass: "px-12px px-md-16px"
    }, [_c('div', {
      staticClass: "tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), item.link ? [_c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v(" " + _vm._s(item.button) + " ")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)] : _vm._e()], 2)], 1)], 1)];
  })], 2)], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey",
    attrs: {
      "id": "4"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/antao-bath-tub-in-stylish-bathromm.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("2024년 주택 욕실 트렌드를 알아보세요")]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("보헤미안 룩 욕실 외에도 탐험할 수 있는 인테리어 디자인 트렌드가 많이 있습니다. 호화롭고 낭만적인 컨트리 스타일부터 도시적인 산업적 세련미와 미니멀리스트 재팬디 룩까지: 2024년 욕실 트렌드를 확인하고 자신에게 맞는 스타일을 찾으세요.")]), _c('v-btn', {
    staticClass: "rounded-xs mt-18px mt-md-24px",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/ideas/bathroom/trends/2024"
    }
  }, [_vm._v(" 더 많은 욕실 트렌드를 살펴보세요 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }