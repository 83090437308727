var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": `${_vm.path}/visual.webp`,
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Japandi bathroom"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("욕실을 트렌디한 Japandi 스타일로 꾸미고 싶으신가요? 저희가 방법을 보여드리겠습니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_vm._l(_vm.navigation, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('router-link', {
      staticClass: "txt txt--sm d-flex align-center",
      attrs: {
        "to": `#${index + 1}`
      }
    }, [_c('v-img', {
      staticClass: "mr-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-down.svg"
      }
    }), _vm._v(" " + _vm._s(item) + " ")], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('dot-list', [_vm._l(_vm.overview, function (item) {
    return [_c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "1"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg text-center",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("미니멀리스트 재팬디 스타일의 정수")]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("\"새로운 Japandi 스타일은 일본과 스칸디나비아 인테리어 디자인의 영향을 결합했습니다. 이름은 \"Japan\"과 \"Scandi\"의 융합입니다. 두 스타일이 처음에는 반대되는 것처럼 보일 수 있지만 자세히 살펴보면 많은 공통적인 특징이 드러납니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, _vm._l(_vm.checkList, function (item, index) {
    return _c('v-row', {
      key: index,
      staticClass: "row--xs"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-check")])], 1), _c('v-col', [_c('p', {
      staticClass: "txt txt--sm txt--dark"
    }, [_vm._v(" " + _vm._s(item) + " ")])])], 1);
  }), 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "txt txt--xs"
  }, [_vm._v("조화로운 분위기를 위해")]), _c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("재팬디룩: 천연소재")]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("Japandi 인테리어 디자인 스타일은 나무와 같은 천연 소재에 초점을 맞춥니다. 천연석, 리넨, 코튼도 인기 있는 선택입니다.")])])], 1), _c('page-section', {
    attrs: {
      "bg": "right",
      "id": "2"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/loop-friends-bathtub-black-in-japanese-style-bathroom.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("Japandi 욕실 장식 선택")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("Japandi 스타일 욕실을 디자인하려면 몇 가지 선택된 핵심 품목으로 시작하세요. 크고 우아한 꽃병은 말린 꽃과 함께 진정한 스타일의 특징이 될 것입니다. 진짜 식물은 자연광이 드는 더 큰 욕실을 위한 또 다른 옵션이며 Japandi 욕실에 신선한 느낌을 더할 것입니다. 벽에 있는 예술 작품은 방을 과부하시키지 않으면서도 의미를 전달할 것입니다.")])])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "left",
      "id": "2"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("정통 재팬디 스타일의 욕실 가구를 선택하세요")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("Japandi 스타일은 욕실 가구를 허용하지만, 품목을 선택할 때 몇 가지 기준을 고려하는 것이 중요합니다. 모든 욕실 가구는 선형 디자인과 단순한 색상을 가져야 합니다. 실용적인 기능도 중요합니다. Japandi 욕실의 세면대는 항상 기능적이고 스타일리시해야 합니다.")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/loop-friends-washbasin-white-with-legato-furniture-and-bonsai.webp`
    }
  })], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "3"
    }
  }, [_c('v-container', {
    staticClass: "text-center"
  }, [_c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("재팬디 욕실: 편안한 휴식과 이완")]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("Japandi 인테리어 디자인의 모든 측면은 차분하고 평화로운 분위기를 조성하기 위해 완벽하게 조정되었습니다. 이는 욕실 세라믹에도 적용됩니다.")])])], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "10"
    }
  }, [_c('v-row', {
    staticClass: "row--xxl",
    attrs: {
      "justify": "center"
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": item.cols
      }
    }, [_c('v-card', {
      staticClass: "mx-auto",
      attrs: {
        "max-width": "776",
        "color": "transparent",
        "tile": "",
        "flat": "",
        "href": item.link
      }
    }, [_c('v-img', {
      staticClass: "mb-12px mb-md-16px",
      attrs: {
        "src": `${_vm.path}/${item.image}`
      }
    }), _c('div', {
      staticClass: "px-12px px-md-16px"
    }, [_c('div', {
      staticClass: "tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px"
    }, [_vm._v(" " + _vm._s(item.title) + " ")]), _c('p', {
      staticClass: "txt txt--sm grey--text text--darken-3"
    }, [_vm._v(" " + _vm._s(item.text) + " ")]), item.link ? [_c('div', {
      staticClass: "mt-12px mt-md-16px d-flex align-center"
    }, [_c('span', {
      staticClass: "txt txt--xs txt--dark font-weight-medium"
    }, [_vm._v(" " + _vm._s(item.button) + " ")]), _c('v-img', {
      staticClass: "ml-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-right.svg"
      }
    })], 1)] : _vm._e()], 2)], 1)], 1)];
  })], 2)], 1)], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey",
    attrs: {
      "id": "4"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("2024년 주택 욕실 트렌드를 알아보세요")]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("다양한 스타일의 영향이 결합되어 미니멀리스트 재팬디 스타일 욕실이 매우 특별해졌습니다. 하지만 이 스타일이 모든 욕실에 어울리는 것은 아닙니다. 미니멀리스트적이지 않은 것을 찾고 있다면 욕실 트렌드를 살펴보세요. 산업 스타일에서 스칸디나비아 스타일, 컨트리 스타일까지 선택할 수 있는 룩이 많이 있습니다.")]), _c('v-btn', {
    staticClass: "rounded-xs mt-18px mt-md-24px",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/ideas/bathroom/trends/2024"
    }
  }, [_vm._v(" 2024년을 위한 더 많은 욕실 트렌드 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/antao-bath-tub-in-stylish-bathromm.webp`
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }