var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('shop-product-visual', {
    attrs: {
      "wide": "",
      "image": `${_vm.path}/visual.webp`,
      "breadcrumbs": _vm.breadcrumbs,
      "title": "Cosy bathrooms"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("아늑한 웰빙 욕실은 트렌드에 딱 맞습니다. 욕실을 환영하는 휴식처로 바꾸고 싶다면, 몇 가지 팁을 알려드리겠습니다.")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md16px"
  }, [_vm._v("컨텐츠")])]), _c('v-row', [_vm._l(_vm.navigation, function (item, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "12"
      }
    }, [_c('router-link', {
      staticClass: "txt txt--sm d-flex align-center",
      attrs: {
        "to": `#${index + 1}`
      }
    }, [_c('v-img', {
      staticClass: "mr-8px",
      attrs: {
        "max-width": "24",
        "src": "/images/icon/icon-arrow-down.svg"
      }
    }), _vm._v(" " + _vm._s(item) + " ")], 1)], 1)];
  })], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-20px pa-md-40px",
    attrs: {
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("개요")])]), _c('dot-list', [_vm._l(_vm.overview, function (item) {
    return [_c('li', {
      key: item
    }, [_vm._v(" " + _vm._s(item) + " ")])];
  })], 2)], 1)], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "1"
    }
  }, [_c('v-container', {
    staticClass: "text-center"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('h3', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("아늑한 욕실 디자인 – 팁과 아이디어")]), _c('p', {
    staticClass: "txt txt--sm txt--dark"
  }, [_vm._v("욕실을 아늑하게 만드는 방법은 다양합니다. 핵심 원칙을 설명하겠습니다.")])])], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, _vm._l(_vm.checkList, function (item, index) {
    return _c('v-row', {
      key: index,
      staticClass: "row--xs"
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-check")])], 1), _c('v-col', [_c('p', {
      staticClass: "txt txt--sm txt--dark"
    }, [_vm._v(" " + _vm._s(item) + " ")])])], 1);
  }), 1)], 1)], 1)], 1), _c('page-section', [_c('v-container', [_c('section-slide', {
    attrs: {
      "items": _vm.tipSlide,
      "path": _vm.path
    }
  })], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "right"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/artis-washbasin-white-with-concrete-walls.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("작은 욕실을 아늑하게 만드는 방법")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("작은 욕실은 쉽게 아늑한 휴식처로 바꿀 수 있습니다. 큰 타일은 작고 아늑한 욕실을 약간 더 크게 보이게 합니다. 또한 조인트가 적기 때문에 더 편안한 효과를 냅니다. 일관된 색상 구성표와 많은 천연 소재는 더 아늑한 느낌을 주는 데 이상적입니다.")])])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "left",
      "id": "2"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("과제: 넓은 욕실을 아늑하게 느끼게 하기")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("넓은 욕실에서 아늑한 분위기를 조성하는 것은 어려울 수 있습니다. 한 가지 해결책은 룸 디바이더에 대해 이미 설명한 것처럼 서로 다른 구역을 영리하게 분리하는 것입니다.")]), _c('p', [_vm._v("각 구역에 다른 색상을 사용하여 공간을 시각적으로 구분합니다. 간접 조명 배열도 잘 작동합니다. 이는 개별적으로 제어하여 욕실에서 사용하는 부분만 밝힐 수 있습니다.")])])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/finion-bathtub-white-freestanding-with-washbasins-white-and-mirrors-big-round.webp`
    }
  })], 1)], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "bg": "right",
      "id": "3"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/antao-bathroom-wc-furniture-washbasin-white-and+oak.webp`
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("따뜻한 조명으로 편안한 분위기를 연출하세요")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("욕실의 적절한 조명은 완벽한 기분 좋은 분위기를 조성하는 데 필수적입니다. 물론 일광이 가장 좋은 선택이지만 모든 욕실에 창문이 있는 것은 아닙니다. 여기서 따뜻한 조명이 등장합니다. 간접 조명원은 분위기 있는 악센트를 더합니다.")])])])], 1)], 1)], 1), _c('page-section', {
    attrs: {
      "id": "4"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("스칸디나비아 욕실 - 북쪽의 아늑한 스타일")]), _c('div', {
    staticClass: "txt txt--sm txt--dark"
  }, [_c('p', [_vm._v("우리는 모두 \"휘게\"라는 단어에 익숙하지만, 욕실에도 아늑한 스칸디나비아 룩이 있다는 걸 알고 계셨나요? 이 인테리어 디자인 스타일을 자세히 알아보세요!")])]), _c('v-btn', {
    staticClass: "rounded-xs mt-18px mt-md-24px",
    attrs: {
      "large": "",
      "color": "#fff",
      "elevation": "2",
      "to": "/ideas/bathroom/trends/styles/scandi"
    }
  }, [_vm._v(" 지금 스칸디나비아 욕실 스타일을 만나보세요 "), _c('v-img', {
    staticClass: "ml-8px",
    attrs: {
      "max-width": "24",
      "src": "/images/icon/icon-arrow-right.svg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-img', {
    attrs: {
      "src": `${_vm.path}/antao-bath-tub-in-bathroom-with-large-window.jpg.webp`
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }