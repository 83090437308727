<template>
    <client-page>
        <shop-product-visual wide :image="`${path}/visual.webp`" :breadcrumbs="breadcrumbs" title="Minimalism in the bathroom" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm txt--dark">미니멀리스트 욕실은 평화와 질서의 느낌을 조성하여 곧바로 긴장을 풀고 휴식을 취하고 싶은 마음을 불러일으킵니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <template v-for="(item, index) in navigation">
                                <v-col cols="12" :key="index">
                                    <router-link :to="`#${index + 1}`" class="txt txt--sm d-flex align-center">
                                        <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                        {{ item }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <dot-list>
                                <template v-for="item in overview">
                                    <li :key="item">
                                        {{ item }}
                                    </li>
                                </template>
                            </dot-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey" id="1">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/antao-bathroom-wc-furniture-washbasin-white-and+oak.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">미니멀리스트 디자인 - 편안한 느낌을 위한 완벽한 구성</div>
                        <div class="txt txt--sm txt--dark">
                            <p>
                                미니멀리스트 디자인 트렌드는 모든 것을 필수 요소로 줄입니다. 미니멀리스트 욕실에는 불필요한 것은 없습니다. <br />
                                미니멀리즘은 작은 욕실에 좋은 선택으로, 공간을 더 넓어 보이게 하고 고요한 분위기를 조성합니다.
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="2">
            <v-container class="text-center">
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <div class="txt txt--xs">고려할 사항</div>
                        <h3 class="tit mb-12px mb-md-16px">미니멀리스트 욕실 디자인 – 팁과 아이디어</h3>
                        <p class="txt txt--sm txt--dark">욕실에 신선한 미니멀리스트 룩을 주고 싶다면, 우리는 당신을 위한 많은 팁과 조언을 가지고 있습니다. 가이드를 따라 욕실을 순식간에 미니멀리스트적으로 탈바꿈하세요.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <section-slide :items="tipSlide" :path="path" />
            </v-container>
        </page-section>

        <page-section class="page-section--grey" id="3">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <div class="tit-wrap tit-wrap--lg">
                            <h3 class="tit mb-12px mb-md-16px text-center">미니멀리스트 욕실의 장점은 무엇인가요?</h3>
                        </div>
                        <v-row v-for="(item, index) in checkList" :key="index" class="row--xs">
                            <v-col cols="auto">
                                <v-icon small>mdi-check</v-icon>
                            </v-col>
                            <v-col>
                                <p class="txt txt--sm txt--dark">
                                    {{ item }}
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="4">
            <v-container>
                <div class="product-section-title">
                    <span class="tit font-weight-regular font-tertiary white">미니멀리스트 욕실을 위한 Villeroy &amp; Boch 솔루션</span>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="10">
                        <v-row justify="center" class="row--xxl">
                            <template v-for="(item, index) in items">
                                <v-col :cols="item.cols" :key="index">
                                    <v-card max-width="776" color="transparent" tile flat :href="item.link" class="mx-auto">
                                        <v-img :src="`${path}/${item.image}`" class="mb-12px mb-md-16px" />
                                        <div class="px-12px px-md-16px">
                                            <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                                {{ item.title }}
                                            </div>
                                            <p class="txt txt--sm grey--text text--darken-3">
                                                {{ item.text }}
                                            </p>
                                            <template v-if="item.link">
                                                <div class="mt-12px mt-md-16px d-flex align-center">
                                                    <span class="txt txt--xs txt--dark font-weight-medium">
                                                        {{ item.button }}
                                                    </span>
                                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                                </div>
                                            </template>
                                        </div>
                                    </v-card>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import SectionSlide from "@/components/client/dumb/section-slide.vue";
import DotList from "@/components/client/ideas/dot-list.vue";
import ViewSectionTitle from "@/components/console/dumb/view-section-title.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        SectionSlide,
        DotList,
        ViewSectionTitle,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 아이디어 및 트렌드",
                href: "/ideas/bathroom/trends",
            },
        ],
        navigation: [
            "욕실에서 미니멀리즘이란 무엇을 뜻할까?",
            "디자인 팁과 아이디어",
            "미니멀리스트 욕실의 장점",
            "욕실에 딱 맞는 제품",
            //
        ],
        overview: [
            "정리를 더 잘하고 아늑한 느낌을 더할 수 있는 공간을 확보하세요.",
            "단순한 색상은 미니멀리스트 디자인 스타일을 강조합니다.",
            "미니멀리스트 욕실은 청소가 더 빠르고 쉽습니다.",
            //
        ],
        tipSlide: [
            {
                title: "대칭을 통한 시각적 질서",
                text: "대칭은 인간의 눈에 기분 좋습니다. 따라서 대칭적인 레이아웃은 모든 미니멀리스트 욕실 디자인의 중요한 측면입니다. 예를 들어, 나란히 놓인 두 개의 세면대, 어울리는 거울과 세면대, 또는 더블 세면대.",
                image: "finion-washbasins-with-mirrors-illuminated-bathtub-white-and-shower-tray-glazed.webp",
            },
            {
                title: "다양한 스타일을 위한 욕실 계획",
                text: "미니멀리즘은 스타일 면에서 진정한 유연성을 제공합니다. 욕조와 기타 설비에 직사각형, 타원형 또는 원형을 선호하는지 결정하세요. 취향에 맞는 욕실 가구를 선택하세요. 가장 중요한 것은 일관된 컨셉을 고수하여 욕실 세라믹과 수도꼭지에 완벽하게 조화된 모습을 만드는 것입니다.",
                image: "loop-and-friends-black-washbasin-on-wood.webp",
            },
            {
                title: "깔끔한 욕실을 위해 충분한 수납공간을 계획하세요",
                text: "칫솔, 헤어드라이어, 향수 및 기타 품목을 정리할 때 보관 공간은 중요합니다. 표면에 아무것도 남겨서는 안 되므로 욕실 캐비닛은 미니멀리스트 욕실에 완벽한 솔루션입니다. 새 욕실을 계획하고 있다면 내장형 보관 칸이나 벽 캐비닛도 실용적인 아이디어입니다.",
                image: "collaro-momento-minimalismus-bathroom.webp",
            },
            {
                title: "흰색 욕실 세라믹으로 심플하고 아늑한 색상",
                text: "물론, 욕실에 색상을 더하는 것을 막을 것은 없습니다. 단순하게 유지한다면요. 미니멀리스트 욕실은 밝고 대담한 색상에 적합한 곳이 아닙니다. 흰색은 위생적이고 우아해 보이기 때문에 욕실 세라믹에 좋은 선택입니다. 라벤더, 세이지, 민트 그린, 청록색 또는 빨간색 톤의 타월과 기타 직물로 색상을 더할 수 있습니다.",
                image: "memento-2.0-countertop-washbasin-stone-white.webp",
            },
            {
                title: "고품질 욕실 설비를 선택하세요",
                text: "미니멀리스트 룩을 위해서는 양보다 질이 중요합니다. 고품질 소재와 뛰어난 장인 정신으로 디자인 아이디어를 실현하세요. 항균 마감 처리된 욕실 세라믹과 현대적인 샤워 변기에 투자하여 욕실 일상을 한 단계 업그레이드하세요. 내구성과 견고성이 뛰어난 가구를 선택하세요.",
                image: "viclean-shower-toilet-in-modern-bathroom.webp",
            },
        ],
        checkList: [
            "먼지가 쌓일 물건이 적기 때문에 미니멀한 장식은 청소에 드는 시간과 에너지를 줄여줍니다.",
            "미니멀리스트적인 욕실은 차분한 느낌을 주며, 휴식을 취하기에 좋습니다.",
            "고품질의 스마트한 설비는 장기적으로 욕실을 더욱 지속 가능하게 만들어줍니다.",
            //
        ],
        items: [
            {
                title: "완벽한 욕조를 선택하세요",
                text: "신중하게 선택된 가구와 결합된 고품질 위생 설비는 매끈하고 선형적인 모습을 만들어냅니다.",
                button: "빌레로이앤보흐 욕조",
                link: "/shop/products?category=bathtub",
                image: "collaro-bathtub-in-bathroom-with-sea-view.webp",
                cols: "6",
            },
            {
                title: "고품질 가구를 선택하세요",
                text: "미니멀리스트 욕실 리모델링으로 최적의 결과를 얻으려면, 앞으로도 오랫동안 즐길 수 있는 고품질, 기능적이고 스타일리시한 Villeroy & Boch 욕실 가구를 추천합니다.",
                button: "빌레로이앤보흐 가구 제품군",
                link: "/shop/products?category=Washbasin_base_cabinets",
                image: "finion-washbasin-white-with-tap-black-and-bathtub-white-in-backround.webp",
                cols: "6",
            },
        ],
        path: "/images/ideas/bathroom/trends/styles/minimalist",
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>