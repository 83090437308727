var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('collections-visual', {
    attrs: {
      "image": `${_vm.path}/visual.webp`,
      "title": "ANTAO",
      "breadcrumbs": _vm.breadcrumbs
    }
  }), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm text-center"
  }, [_vm._v(" 숲속 산책, 해변 산책 등 이런 곳에서 우리는 자연과 가까워지는 것이 얼마나 좋은지 느끼게 됩니다. 우리는 다시 머리를 비우고 새로운 아이디어를 생각해 냅니다. 우리에게 자연은 에너지의 원천이자 휴식처입니다. 우리의 새로운 Antao 컬렉션은 우리가 자연과 접촉하면서 경험하는 거의 마법 같은 에너지를 발산합니다. 전체적인 디자인 언어는 나뭇잎에 맺힌 이슬 방울에서 영감을 받았습니다. 부드럽고 비대칭적인 곡선이 디자인의 특징입니다. "), _c('br'), _vm._v(" Antao도 느껴보세요. 이 욕실에서는 자연과 소통할 수 있습니다. ")])])], 1)], 1)], 1), _c('page-section', {
    staticClass: "page-section--grey"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('div', {
    staticClass: "tit text-center"
  }, [_vm._v("자연과의 만남")])])]), _c('v-img', {
    attrs: {
      "src": `${_vm.path}/antao-bath-tub-white-in-modern-bathroom.webp`
    }
  })], 1), _c('page-section', [_c('v-container', [_c('v-row', {
    staticClass: "row--lg"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('div', {
    staticClass: "tit mb-12px mb-md-16px"
  }, [_vm._v("Antao 제품군을 살펴보세요")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Antao 컬렉션은 욕실 디자인을 위한 광범위한 제품을 제공합니다. 화장실부터 욕실 가구, 그에 어울리는 설비까지 욕실에 필요한 모든 것을 여기에서 찾으실 수 있습니다. 팁: 이 제품군은 다른 Villeroy & Boch 컬렉션과 이상적으로 결합할 수도 있습니다. 이를 통해 개별 인테리어 디자인에 대한 더 많은 범위가 제공됩니다.")])])])], 1), _c('idea-product-list', {
    attrs: {
      "path": _vm.path,
      "items": _vm.items
    }
  })], 1)], 1), _c('page-section', [_c('v-container', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm text-center"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("Antao의 다양성을 발견해보세요")])]), _c('center-slide', {
    attrs: {
      "path": _vm.path,
      "items": _vm.images
    }
  }), _c('v-row', {
    staticClass: "row--lg",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "txt txt--sm txt--dark mb-12px mb-md-16px"
  }, [_vm._v("유동적으로")]), _c('p', {
    staticClass: "txt txt--sm"
  }, [_vm._v("Antao의 다양성은 다양한 스타일을 창조하고 다양한 환경에 적응하는 능력에서 분명하게 드러납니다. 미니멀하고 현대적이든 소박하고 아늑하든 Antao를 사용하면 개인 휴양지를 디자인하고 자연과의 내면의 연결을 표현할 수 있습니다.")])])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }