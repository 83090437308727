<template>
    <client-page>
        <shop-product-visual wide :image="`${path}/visual.webp`" :breadcrumbs="breadcrumbs" title="Industrial-style bathroom" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm">생동감 넘치는 소재와 강렬한 대비는 산업 스타일의 특징입니다. 산업 스타일 욕실을 디자인하는 방법을 여기에서 알아보세요.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <template v-for="(item, index) in navigation">
                                <v-col cols="12" :key="index">
                                    <router-link :to="`#${index + 1}`" class="txt txt--sm d-flex align-center">
                                        <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                        {{ item }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <dot-list>
                                <template v-for="item in overview">
                                    <li :key="item">
                                        {{ item }}
                                    </li>
                                </template>
                            </dot-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="right" id="1">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/infinity-showers-shower-system-matt-black-with-squaro-infinity-shower-tray.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">산업 스타일의 특징은 무엇일까?</div>
                        <p class="txt txt--sm">욕실에서 점점 더 많이 볼 수 있는 산업 스타일의 주요 영감은 오래된 공장 건물입니다. 이 디자인은 어떤 거칠음과 도시적 미학을 혼합합니다.</p>
                        <p class="txt txt--sm">산업 스타일은 일반적으로 미니멀리즘을 지향하지만 물론 일부 장식적 요소는 허용합니다. 개방형 평면 디자인은 산업 스타일의 또 다른 특징입니다. 중성 톤과 대담한 대비가 생생한 색상보다 선호됩니다.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="2">
            <v-container>
                <section-slide :items="tipSlide" :path="path" />
            </v-container>
        </page-section>

        <page-section bg="right">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/collaro-white-washbasin-black-furniture-subway3-mirror-black-bathroom.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">팁 4: 멋진 블랙 & 그레이 색상 구성표</div>
                        <p class="txt txt--sm">진한 검은색과 밝은 흰색을 조합하여 대비를 만드세요. 회색 음영은 산업용 욕실에 깊이를 더해줍니다. 색상 구성표에는 자연스러운 갈색 음영이나 밝은 분홍색도 포함될 수 있지만, 밝은 색상은 피하는 것이 가장 좋습니다. 검은색 산업용 세면대와 크롬 수도꼭지를 조합하여 디자인에 마무리 터치를 더하세요.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="left">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">팁 5: 장식적 특징과 식물로 아늑한 느낌을 더하세요</div>
                        <p class="txt txt--sm">장식적인 특징은 산업 스타일 욕실에서도 필수적입니다. 나머지 디자인과 조화를 이루는 경우에 한합니다. 신중하게 선택한 꽃병은 식물을 욕실로 가져오고 산업 스타일의 차가움을 상쇄하는 완벽한 방법입니다. 부드러운 욕실 직물은 아늑한 느낌을 만들어내고 색상 구성과 조화를 이루어야 합니다.</p>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/manufacture-vases-beige-as-diffusor-in-bathroom.webp`" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row class="row--lg">
                    <v-col md="1" class="d-none d-md-block" />
                    <v-col cols="12" md="8">
                        <v-card tile elevation="3" class="pa-24px pa-md-40px txt">
                            <p>
                                <v-row align="center" class="row--sm">
                                    <v-col cols="auto">
                                        <v-icon>mdi-information-outline</v-icon>
                                    </v-col>
                                    <v-col class="font-weight-medium">팁</v-col>
                                </v-row>
                            </p>
                            <p class="txt--sm">산업 스타일의 로프트 특성은 큰 창문과 높은 천장이 있는 넓은 욕실에서 특히 잘 어울립니다. 하지만 작은 욕실도 산업적인 느낌을 줄 수 있습니다. 이 경우 이 스타일의 전형적인 요소를 사용하는 것이 더욱 중요합니다.</p>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="grey lighten-5" id="3">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">욕실을 위한 2024년 트렌드 더 보기</div>
                        <p class="txt txt--sm">산업적인 룩 외에도 욕실을 위해 선택할 수 있는 다른 스타일이 많이 있습니다. 개인 취향과 욕실 디자인에 가장 잘 맞는 스타일을 결정하세요. 대부분의 경우 모든 것을 바꿀 필요는 없습니다. 몇 가지 간단한 조정만으로도 욕실을 2024년에 맞게 최신식으로 만들기에 충분합니다.</p>
                        <v-btn large color="#fff" elevation="2" class="rounded-xs mt-18px mt-md-24px" to="/ideas/bathroom/trends/2024">
                            2024년 욕실 트렌드
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/collaro-white-washbasin-black-furniture-subway3-mirror-black-bathroom.webp`" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import SectionSlide from "@/components/client/dumb/section-slide.vue";
import DotList from "@/components/client/ideas/dot-list.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        SectionSlide,
        DotList,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 아이디어 및 트렌드",
                href: "/ideas/bathroom/trends",
            },
        ],
        path: "/images/ideas/bathroom/trends/styles/industrial",
        navigation: [
            "산업적 스타일의 특징",
            "산업적 스타일 욕실을 위한 팁",
            "2024년 욕실 트렌드",
            //
        ],
        overview: [
            "이 스타일은 오래된 공장 건물에서 영감을 받았습니다.",
            "노출된 벽은 산업 스타일의 특징적인 요소입니다.",
            "디자인 특징을 활용하여 기능적인 가구를 만들 수 있습니다.",
            "조명의 색상은 차가운 경우가 많습니다.",
            //
        ],
        tipSlide: [
            {
                title: "팁 1: 벽돌, 노출된 벽 및 콘크리트 모양",
                text: "노출된, 이상적으로는 매우 높은 벽은 이 트렌드의 로프트 스타일을 강조할 것입니다. 기존 타일 대신 벽돌이나 콘크리트 룩의 벽 덮개를 선택하여 거칠고 개방적인 효과를 연출하세요.",
                image: "collaro-bathroom-loft-bath-fresh-air.webp",
            },
            {
                title: "팁 2: 보이는 파이프는 산업적인 느낌을 더해줍니다.",
                text: "대부분의 욕실에서 파이프는 세면대 뒤에 숨겨져 있습니다. 열린 카운터탑은 파이프를 보이게 하며 산업 스타일에 인기 있는 선택입니다. 기존 파이프는 수건 걸이로도 사용할 수 있습니다. 뜨거운 물 파이프인지 차가운 물 파이프인지 확인하세요. 또 다른 옵션은 수건을 걸고 산업적인 느낌을 강조하기 위해 벽에 인공 파이프를 장착하는 것입니다.",
                image: "onovo-washbasin-white-small-with-mirror-and-plants.webp",
            },
            {
                title: "팁 3: 소박한 산업용 조명",
                text: "산업용 욕실은 효과를 완성하기 위해 많은 빛이 필요합니다. 일광이 이상적이지만, 전구가 달린 대형 펜던트 조명을 사용하여 창문이 없는 욕실을 밝힐 수 있습니다. 다른 스타일과 달리, 이 룩에는 차가운 빛이 자주 사용됩니다. 맨 전구를 사용하고 싶지 않다면, 조명이 있는 거울은 산업용 욕실에 완벽합니다.",
                image: "antao-washbasin-mirror-hotel-bathroom.webp",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>