<template>
    <client-page>
        <shop-product-visual wide :image="`${path}/visual.webp`" :breadcrumbs="breadcrumbs" title="Country-style bathroom" />

        <page-section class="page-section--first">
            <v-container>
                <v-row class="row--lg">
                    <v-col cols="12" md="8">
                        <p class="txt txt--sm txt--dark">인기 있는 컨트리 스타일 트렌드는 주방에만 국한되지 않습니다. Villeroy & Boch와 함께 컨트리 스타일 욕실을 만드는 방법을 알아보세요.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="6">
                        <div class="tit-wrap tit-wrap--sm">
                            <div class="tit mb-12px mb-md16px">컨텐츠</div>
                        </div>
                        <v-row>
                            <template v-for="(item, index) in navigation">
                                <v-col cols="12" :key="index">
                                    <router-link :to="`#${index + 1}`" class="txt txt--sm d-flex align-center">
                                        <v-img max-width="24" src="/images/icon/icon-arrow-down.svg" class="mr-8px" />
                                        {{ item }}
                                    </router-link>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-sheet elevation="4" class="pa-20px pa-md-40px">
                            <div class="tit-wrap tit-wrap--sm">
                                <div class="tit">개요</div>
                            </div>
                            <dot-list>
                                <template v-for="item in overview">
                                    <li :key="item">
                                        {{ item }}
                                    </li>
                                </template>
                            </dot-list>
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="1">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/subway-3.0-washbasin-wc-guest-bath.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">컨트리 스타일의 특징 - 현대적인 욕실</div>
                        <div class="txt txt--sm txt--dark">
                            <p>천연 소재, 따뜻한 색상, 화려한 모양은 소박한 컨트리 스타일 욕실의 특징입니다. 컨트리 스타일에는 다양한 변형이 있습니다. 현대적, 전통적이거나 영국이나 프랑스 컨트리 하우스에서 영감을 받은 스타일이 있습니다. 욕실을 컨트리 하우스 휴양지로 바꾸고 싶다면 가장 먼저 고려해야 할 질문은 선호하는 스타일입니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container class="text-center">
                <v-row justify="center">
                    <v-col cols="12" md="8">
                        <h3 class="tit mb-12px mb-md-16px">우아한 컨트리 스타일의 욕실 디자인</h3>
                        <p class="txt txt--sm txt--dark">현대적이거나 소박한 컨트리 스타일 욕실의 바닥과 벽에는 밝은 색상의 타일을 선택하세요. 가구, 세라믹 위생 설비 및 액세서리로 룩을 완성하세요.</p>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section>
            <v-container id="2">
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">올바른 액세서리와 장식 요소 선택</div>
                        <div class="txt txt--sm txt--dark">
                            <p>액세서리는 컨트리 스타일 욕실에서 필수적인 역할을 합니다. 그들은 방에 특별한 터치를 더하고 전반적인 분위기에 상당한 기여를 합니다. 욕실의 컨트리 테마에 맞는 장식을 선택하세요: 신선한 꽃, 꽃무늬 직물, 화려한 거울과 같은 빈티지 아이템이 좋은 선택입니다. 쿠션도 컨트리 스타일 욕실에 좋은 추가품입니다.</p>
                        </div>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/subway-30-country-style-with-light-blue-wall.webp`" />
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section bg="right" id="3">
            <v-container>
                <v-row align="center" class="row--lg">
                    <v-col cols="12" md="7">
                        <v-img :src="`${path}/hommage-bidet-white-toilet-white-washbasin-light-brown-and-bathtub-white.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">정품 욕실가구를 선택하세요</div>
                        <div class="txt txt--sm txt--dark">
                            <p>컨트리 스타일 욕실 가구는 매력적이며, 사랑스럽고 아늑한 매력이 있습니다. 고품질 컨트리 스타일 세면대는 필수이며 유용한 보관 공간을 제공합니다. 마찬가지로 빈티지 컨트리 스타일 욕실 거울은 훌륭한 추가 사항입니다. 컨트리 스타일 욕실 가구는 실용적이어야 하며 방에 매우 독특한 스타일을 제공해야 합니다.</p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section id="4">
            <v-container>
                <div class="product-section-title">
                    <span class="tit font-weight-regular font-tertiary white">컨트리 스타일 욕실을 위한 설비</span>
                </div>
            </v-container>
        </page-section>

        <page-section>
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" md="10">
                        <v-row justify="center" class="row--xxl">
                            <template v-for="(item, index) in items">
                                <v-col :cols="item.cols" :key="index">
                                    <v-card max-width="776" color="transparent" tile flat :href="item.link" class="mx-auto">
                                        <v-img :src="`${path}/${item.image}`" class="mb-12px mb-md-16px" />
                                        <div class="px-12px px-md-16px">
                                            <div class="tit tit--xxs grey--text text--darken-3 mb-6px mb-md-8px">
                                                {{ item.title }}
                                            </div>
                                            <p class="txt txt--sm grey--text text--darken-3">
                                                {{ item.text }}
                                            </p>
                                            <template v-if="item.link">
                                                <div class="mt-12px mt-md-16px d-flex align-center">
                                                    <span class="txt txt--xs txt--dark font-weight-medium">
                                                        {{ item.button }}
                                                    </span>
                                                    <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                                                </div>
                                            </template>
                                        </div>
                                    </v-card>
                                </v-col>
                            </template>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>

        <page-section class="page-section--grey" id="5">
            <v-container>
                <v-row justify="center" align="center" class="row--lg">
                    <v-col cols="12" md="5">
                        <v-img :src="`${path}/antao-bath-tub-in-stylish-bathromm.webp`" />
                    </v-col>
                    <v-col cols="12" md="5">
                        <div class="tit mb-12px mb-md-16px">컨트리 스타일이 당신의 욕실에 적합한 선택일까요?</div>
                        <div class="txt txt--sm txt--dark">
                            <p>현대적이든 전통적인 컨트리 스타일이든 취향에 따라 선택하면 됩니다. 컨트리 스타일이 당신에게 맞지 않나요? 걱정하지 마세요. 선택할 수 있는 욕실 트렌드가 많이 있습니다!</p>
                        </div>
                        <v-btn large color="#fff" elevation="2" class="rounded-xs mt-18px mt-md-24px" to="/b/bathroom-collections">
                            더 많은 욕실 트렌드를 확인하세요
                            <v-img max-width="24" src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import SectionSlide from "@/components/client/dumb/section-slide.vue";
import DotList from "@/components/client/ideas/dot-list.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShopProductVisual,
        SectionSlide,
        DotList,
    },
    data: () => ({
        breadcrumbs: [
            {
                text: "아이디어 및 플래너",
                href: "/ideas/bathroom/overview",
            },
            {
                text: "욕실 아이디어 및 트렌드",
                href: "/ideas/bathroom/trends",
            },
        ],
        navigation: [
            "컨트리 스타일의 특징",
            "올바른 액세서리",
            "정품 욕실 가구를 선택하세요",
            "컨트리 스타일의 욕실 디자인",
            "더 많은 트렌드 보기",
            //
        ],
        overview: [
            "컨트리 스타일의 욕실은 전통적인 느낌도 있고, 더 현대적인 느낌도 가질 수 있습니다.",
            "밝은 색상의 타일은 컨트리 스타일의 욕실에 가장 적합한 선택입니다.",
            "기발하고 화려한 욕실 가구는 아늑한 분위기를 조성합니다.",
            "둥근 모양의 욕실 도자기를 선택하세요.",
            //
        ],
        items: [
            {
                title: "완벽한 욕조를 선택하세요",
                text: "독립형 욕조는 소박한 시골 스타일 욕실에 특히 좋은 선택입니다. 곡선형, 둥근 모양의 욕조는 현대적인 스타일과도 아름답게 조화를 이룹니다.",
                button: "빌레로이앤보흐 욕조",
                link: "/shop/products?category=bathtub",
                image: "collaro-bathroom-country-style.webp",
                cols: "6",
            },
            {
                title: "스타일리쉬한 샤워",
                text: "욕조뿐만 아니라 샤워도 넓은 시골 스타일 욕실에 더 큰 편의성을 더해줍니다. 검은색 수도꼭지와 레인 샤워는 특별한 터치를 더해줍니다.",
                button: "완벽한 수도꼭지를 찾아보세요",
                link: "/shop/products?category=Shower_fittings",
                image: "universal-showers-rain-shower-matt-black-in-white-shower.webp",
                cols: "6",
            },
        ],
        path: "/images/ideas/bathroom/trends/styles/country",
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>